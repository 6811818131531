<template>
  <div class="v-block-polygon">
    <div class="v-tit" v-show="visible" @click="handlerOpen">
      <img :src="require('../../assets/images/gs-tit2.png')" alt="" />
      <h2>围栏设置</h2>
    </div>
    <div class="v-content" v-show="!visible">
      <div class="slideArrow" @click="handlerClose">
        <span :class="visible ? '' : 'rote'"><img :src="require('../../assets/images/gs-arrow4.png')" alt=""></span>
      </div>
      <!-- 围栏-操作-->
      <div class="v-poi-tool" v-if="poiEditStatus">
        <p v-if="poiEditStatus == 2">请点击地图绘制，双击或点击鼠标右键结束</p>
        <template v-else-if="poiEditStatus == 1">
          <span @click="hanlderSave">
            <template v-if="polygonType === 5">保存线路</template>
            <template v-else>保存围栏</template>
          </span>
          <span @click="hanlderDrawPolyonEdit">重新编辑</span>
          <span @click="handlerDrawReset">重新绘制</span>
          <span @click="handlerDrawExit(1)">退出编辑</span>
        </template>
        <template v-else-if="poiEditStatus == 4">
          <span @click="hanlderDrawPolyonEdit">编辑</span>
          <span @click="handlerDrawReset">重新绘制</span>
          <span @click="handlerDrawExit(4)">退出编辑</span>
        </template>
        <template v-else-if="poiEditStatus == 3">
          <span @click="hanlderendEdit">完成编辑</span>
        </template>
      </div>
      <!-- <span class="ico"><i class="el-icon-close"></i></span> -->
      <ul class="titNav">
        <li :class="currentIndex === item.value ? 'cell active' : 'cell'" v-for="(item, index) in navOptions" :key="index"
          @click="handlerNav(item)"
          v-show="item.value === 1 || item.value === 5 && loginInfo.allFuns.includes(30210) || (item.value === 2 || item.value === 3) && loginInfo.allFuns.includes(20110)">
          {{ item.label }}</li>
      </ul>
      <div class="list">
        <template v-if="currentIndex === 1">
          <div class="caption">
            <el-input v-model="key1" suffix-icon="el-icon-search" size="small" @input="d_key1Change" clearable></el-input>
            <tool-tip content="添加兴趣点" placement="top" type="light-tip" :offset="-10">
              <span class="eIcon" @click="handlerPolygonAdd(1, '')" v-if="loginInfo.UserID === 32083"> <img
                  :src="require('../../assets/images/gs-tt1.png')" alt=""></span>
            </tool-tip>
          </div>
          <div class="scroll">
            <ul class="con">
              <el-scrollbar ref="elScroll" style="height:100%;" v-loading="tableLoading">
                <li>
                  <el-checkbox v-model="checked1" :label="$t('onlineTrack.loadAll')"></el-checkbox>
                </li>
                <el-checkbox-group v-model="checkPOIList">
                  <li v-for="(item, index) in (key1 ? POIList_B : POIList)" :key="index">
                    <el-checkbox :label="item.PointID" :key="item.PointID"
                      @click.native="regionCheckChangeSingle(1, item)">
                      {{ item.PointName }}</el-checkbox>

                    <div class="tags" v-if="loginInfo.UserID === 32083">
                      <span class='tagMode tag' @click="handlerPolyonRemove(1, item)">删除</span>
                    </div>
                  </li>
                </el-checkbox-group>
              </el-scrollbar>
            </ul>
          </div>
        </template>
        <template v-if="currentIndex === 2">
          <div class="caption">
            <el-input v-model="key2" suffix-icon="el-icon-search" size="small" @input="d_key2Change" clearable></el-input>
            <tool-tip content="添加多边形围栏" placement="top" type="light-tip" :offset="-10">
              <span class="eIcon" @click="handlerPolygonAdd(2, '')" v-if="loginInfo.UserID === 32083"> <img
                  :src="require('../../assets/images/gs-tt1.png')" alt=""></span>
            </tool-tip>
          </div>
          <div class="scroll">
            <ul class="con">
              <el-scrollbar ref="elScroll" style="height:100%;" v-loading="tableLoading">
                <li>
                  <el-checkbox v-model="checked2" :label="$t('onlineTrack.loadAll')"></el-checkbox>
                </li>
                <li v-if="objectId">
                  <el-checkbox v-model="byId2" label="关联多边形围栏"></el-checkbox>
                </li>
                <el-checkbox-group v-model="checkRegionList">
                  <li v-for="(item, index) in (key2 ? RegionList_B : RegionList)" :key="index">
                    <el-checkbox :label="item.RegionID" :key="item.RegionID"
                      @click.native="regionCheckChangeSingle(2, item)">
                      {{ item.RegionName }}</el-checkbox>
                    <div class="tags">
                      <span class='tagMode tag' @click="handlerPolygonAdd(2, item)"
                        v-if="loginInfo.UserID === 32083">编辑</span>
                      <span class='tagMode tag' @click="handlerRelation(1, item)">关联设备</span>
                      <el-popover placement="bottom" width="60" popper-class="minPopper" trigger="click">
                        <dl class="menus">
                          <dd><span class='tagMode tag' @click="handlerPolyonRemove(2, item)"
                              v-if="loginInfo.UserID === 32083">删除</span></dd>
                          <dd><span class='tagMode tag' @click="openDialogList(1, item.RegionID)">查看详情</span></dd>
                        </dl>
                        <span class='tagMode tag' slot="reference">更多</span>
                      </el-popover>
                    </div>
                  </li>
                </el-checkbox-group>
              </el-scrollbar>
            </ul>
          </div>
        </template>
        <template v-if="currentIndex === 3">
          <div class="caption">
            <el-input v-model="key3" suffix-icon="el-icon-search" size="small" @input="d_key3Change" clearable></el-input>
            <tool-tip content="添加圆形围栏" placement="top" type="light-tip" :offset="-10">
              <span class="eIcon" @click="handlerPolygonAdd(3, '')" v-if="loginInfo.UserID === 32083"> <img
                  :src="require('../../assets/images/gs-tt1.png')" alt=""></span>
            </tool-tip>
          </div>
          <div class="scroll pg">
            <ul class="con">
              <el-scrollbar ref="elScroll" style="height:100%;" v-loading="tableLoading">
                <!-- <li>
                <el-checkbox v-model="checked3" :label="$t('onlineTrack.loadAll')"></el-checkbox>
              </li> -->
                <li v-if="objectId">
                  <el-checkbox v-model="byId3" label="关联的圆形围栏"></el-checkbox>
                </li>
                <el-checkbox-group v-model="checkCircleList">
                  <li v-for="(item, index) in CircleList" :key="index">
                    <el-checkbox :label="item.CircleID" :key="item.CircleID"
                      @click.native="regionCheckChangeSingle(3, item)">
                      {{ item.CircleName }}</el-checkbox>
                    <div class="tags">
                      <span class='tagMode tag' @click="handlerRelation(2, item)">关联设备</span>
                      <span class='tagMode tag' @click="handlerPolygonAdd(3, item)"
                        v-if="loginInfo.UserID === 32083">编辑</span>
                      <el-popover placement="bottom" width="60" popper-class="minPopper" trigger="click">
                        <dl class="menus">
                          <dd><span class='tagMode tag' @click="handlerPolyonRemove(3, item)"
                              v-if="loginInfo.UserID === 32083">删除</span></dd>
                          <dd><span class='tagMode tag' @click="openDialogList(2, item.CircleID)">查看详情</span></dd>
                        </dl>
                        <span class='tagMode tag' slot="reference">更多</span>
                      </el-popover>
                    </div>
                  </li>
                </el-checkbox-group>
              </el-scrollbar>
              <div class="blockPages">
                <el-pagination small layout="total,prev, pager, next" :total="circleTable.total"
                  @current-change="handleCurrentChangeCircle">
                </el-pagination>
              </div>
            </ul>
          </div>
        </template>
        <template v-if="currentIndex === 5">
          <div class="caption">
            <el-input v-model="key5" suffix-icon="el-icon-search" size="small" @input="d_key5Change" clearable></el-input>
            <tool-tip content="添加线路" placement="top" type="light-tip" :offset="-10">
              <span class="eIcon" @click="handlerPolygonAdd(5, '')" v-if="loginInfo.UserID === 32083"> <img
                  :src="require('../../assets/images/gs-tt1.png')" alt=""></span>
            </tool-tip>
          </div>
          <div class="scroll pg">
            <ul class="con">
              <el-scrollbar ref="elScroll" style="height:100%;" v-loading="tableLoading">
                <!-- <li>
                <el-checkbox v-model="checked5" :label="$t('onlineTrack.loadAll')"></el-checkbox>
              </li> -->
                <!-- <li v-if="objectId">
                <el-checkbox v-model="byId5" label="关联线路"></el-checkbox>
              </li> -->
                <li>
                  <div class="tagExprot" @click="hanlderRoadExprot" v-if="loginInfo.UserID === 32083">导入KML</div>
                </li>
                <el-checkbox-group v-model="checkRoadList">
                  <li v-for="(item, index) in RoadList" :key="index">
                    <el-checkbox :label="item.PolygonID" :key="item.PolygonID"
                      @click.native="regionCheckChangeSingle(5, item)">
                      {{ item.PolygonName }}</el-checkbox>
                    <div class="tags">
                      <span class='tagMode tag' @click="handlerPolygonAdd(5, item)"
                        v-if="loginInfo.UserID === 32083">编辑</span>
                      <span class='tagMode tag' @click="handlerRoadActivate(item)" v-if="loginInfo.UserID === 32083">{{
                        item.IsActivate ? '已激活' : '未激活' }}</span>
                      <span class='tagMode tag' v-else>{{ item.IsActivate ? '已激活' : '未激活' }}</span>
                      <el-popover placement="bottom" width="60" popper-class="minPopper" trigger="click">
                        <dl class="menus">
                          <dd><span class='tagMode tag' @click="handlerPolyonRemove(5, item)"
                              v-if="loginInfo.UserID === 32083">删除</span></dd>
                        </dl>
                        <span class='tagMode tag' slot="reference">更多</span>
                      </el-popover>
                    </div>
                  </li>
                </el-checkbox-group>
              </el-scrollbar>
              <div class="blockPages">
                <el-pagination small layout="total,prev, pager, next" :total="roadTable.total"
                  @current-change="handleCurrentChangeRoad">
                </el-pagination>
              </div>
            </ul>
          </div>
        </template>
      </div>
      <div class="gap"><img :src="require('../../assets/images/gs-py2.png')" alt=""></div>
    </div>
  </div>
</template>

<script>
import {
  POST_URL,
  DOWNLOAD_URL
} from '@/api/config'
import {
  mixinAmap
} from '@/views/mixins/polygonMixin.js'
import {
  mapGetters
} from 'vuex'
import {
  GetPOI,
  GetRegionByLngLat,
  GetRegionByObjectID,
  GetRegionCircleList,
  GetRegionCircleListByObjectID,
  GetRoadList,
  GetRoadInfo,
  ModifyRoadActivate,
  ConvertFrom,
  AddPoi,
  GetRegionCircleListByRoadId,
  DeleteCenterCircle
} from '@/api/common'
import miment from 'miment'
import * as API from '@/api/common'
import * as ROAD from '@/api/road'
import * as REGION from '@/api/region'
import {
  SaveRegion,
  SaveCenterCircle
} from '@/api/region'
import {
  debounce,
  deleteFromArray,
  findFromArray,
} from '@/common/utils'
export default {
  props: {
    map: {
      required: true
    },
    google: {
      type: Boolean,
      default: false
    },
    infoWindow: {
      required: true
    },
    objectId: {
      type: Number,
      default: null
    },
    display: {
      type: Boolean,
      default: false
    },
    showRoad: {
      type: Boolean,
      default: false
    },
  },
  mixins: [
    mixinAmap
  ],
  data() {
    return {
      miment,
      POIList: [], // 所有兴趣点 1
      key1: '', // 兴趣点查询关键字 1
      checked1: false, // 单选兴趣点 全部 1
      checkPOIList: [], // 所有选中的 兴趣点 1
      POIList_B: [],
      RegionList: [], // 所有多边形围栏 2
      key2: '', // 多边形围栏查询关键字 2
      byId2: false,
      checked2: false, // 单选兴趣点 全部 2
      checkRegionList: [], // 所有选中的 多边形围栏 2
      CircleList: [], //所有圆形围栏3
      RegionList_B: [],
      key3: '', // 圆形围栏查询关键字 3
      byId3: false,
      checked3: false, // 单选圆形围栏 全部 3
      checkCircleList: [], // 所有选中的 圆形围栏3
      circleTable: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      RoadList: [], //所有线路
      key5: '', // 线路查询关键字 5
      byId5: false,
      checked5: false, // 单选线路 全部 5
      checkRoadList: [], // 所有选中的 线路5
      roadTable: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      RegionListLink: [], // 所有关联的围栏
      visible: false,
      tableLoading: false,
      currentIndex: 1,
      navOptions: [{
        label: this.$t('onlineTrack.poiTab'),
        value: 1
      },
      {
        label: this.$t('onlineTrack.regionTab'),
        value: 2,
      },
      {
        label: this.$t('onlineTrack.circleTab'),
        value: 3,
      },
      // {
      //   label: this.$t('onlineTrack.regionTabLink'),
      //   value: 3
      // },
      {
        label: this.$t('onlineTrack.roadTab'),
        value: 5
      },
      ],
      loading: false,
      poiEditStatus: 0, // 1.编辑工具/绘制完成（待保存/重新编辑）  2.开始绘制  3.编辑工具打开  4.映射围栏到地图上面
      polygonData: {
        points: [], // 编辑完成的点数据
        polygon: '', // 围栏对象
        item: '', //对象数据
      },
      mousetool: null, // 绘制笔对象
      msTool: null, // 编辑围栏对象
    }
  },
  watch: {
    checked1(val) {
      if (val) {
        let list = this.POIList
        list.forEach(k => {
          if (!this.checkPOIList.includes(k.PointID)) {
            this.checkPOIList.push(k.PointID)
          }
        })
        this.mixinAddPoi(this.map, list.slice()).then(() => {
          this.mixinSetFitView(this.map, this.mixinPoiOverlays)
        })
        console.log('选中全部兴趣点')
      } else {
        this.mixinRemovePoi()
        this.checkPOIList.splice(0, this.checkPOIList.length)
        console.log('移除全部兴趣点')
      }
    },
    checked2(val) {
      if (val) {
        let list = this.RegionList
        list.forEach(k => {
          if (!this.checkRegionList.includes(k.RegionID)) {
            this.checkRegionList.push(k.RegionID)
          }
        })
        this.mixinAddRegionDataToMap(this.map, list.slice())
        console.log('选中全部多边形围栏')
      } else {
        this.mixinRemoveRegion()
        this.checkRegionList.splice(0, this.checkRegionList.length)
        console.log('移除全部多边形围栏')
      }
    },
    checked3(val) {
      if (val) {
        let list = this.CircleList
        list.forEach(k => {
          if (!this.checkCircleList.includes(k.CircleID)) {
            this.checkCircleList.push(k.CircleID)
          }
        })
        console.log('选中全部圆形围栏')
      } else {
        this.checkCircleList.splice(0, this.checkCircleList.length)
        console.log('移除全部圆形围栏')
      }
    },
    checked5(val) {
      if (val) {
        let list = this.RoadList
        list.forEach(k => {
          if (!this.checkRoadList.includes(k.RegionID)) {
            this.checkRoadList.push(k.RegionID)
          }
        })
        console.log('选中全部线路')
      } else {
        this.checkRoadList.splice(0, this.checkRoadList.length)
        console.log('移除全部线路')
      }
    },
    byId2(form, to) {
      if (form && form !== to) {
        this.fetchRegionByID()
      } else {
        this.fetchRegionData()
      }
    },
    byId3(form, to) {
      if (form && form !== to) {
        this.circleTable.pageIndex = 1
        this.fetchRegionCircleData(this.objectId)
      } else {
        this.circleTable.pageIndex = 1
        this.fetchRegionCircleData('')
      }
    },
    // byId5() {
    //   if (form && form !== to) {
    //     this.fetchlistByRoadId(this.objectId)
    //   } else {
    //     this.roadTable.pageIndex = 1
    //     this.fetchRoadList()
    //   }
    // },
  },
  computed: {
    ...mapGetters([
      'loginInfo',
      'currentHoldId',
      'currentMap'
    ])
  },
  methods: {
    handlerOpen() {
      this.visible = false;
    },
    handlerClose() {
      this.visible = true;
    },
    regionCheckChangeSingle(type, item) {
      if (type === 5) {
        if (this.checkRoadList.includes(item.PolygonID)) {
          let index = null
          index = this.mixinRoadOverlays.findIndex(k => k.getExtData() === item.PolygonID)
          if (index != -1) {
            this.mixinRoadOverlays[index].setMap(null)
            this.mixinRoadOverlays[index] = null
            this.mixinRoadOverlays.splice(index, 1)
          }
        } else {
          this.fetchRoadInfo(item.PolygonID).then((data) => {
            item.Points = data
            this.mixinSelectRoad(this.map, item)
          })
        }
      } else if (type === 3) {
        if (this.checkCircleList.includes(item.CircleID)) {
          let index = null
          index = this.mixinCircleOverlays.findIndex(k => k.getExtData() === item.CircleID)
          if (index != -1) {
            this.mixinCircleOverlays[index].setMap(null)
            this.mixinCircleOverlays[index] = null
            this.mixinCircleOverlays.splice(index, 1)
          }
        } else {
          this.mixinSelectCircle(this.map, item)
        }
      } else if (type === 2) {
        if (this.checkRegionList.includes(item.RegionID)) {
          let index = null
          index = this.mixinRegionOverlays.findIndex(k => k.getExtData() === item.RegionID)
          if (index != -1) {
            this.mixinRegionOverlays[index].setMap(null)
            this.mixinRegionOverlays[index] = null
            this.mixinRegionOverlays.splice(index, 1)
          }
        } else {
          this.mixinSelectRegion(this.map, item)
        }
      } else if (type === 1) {
        if (this.checkPOIList.includes(item.PointID)) {
          let index = null
          index = this.mixinPoiOverlays.findIndex(k => k.getExtData() === item.PointID)
          if (index != -1) {
            this.mixinPoiOverlays[index].setMap(null)
            this.mixinPoiOverlays[index] = null
            this.mixinPoiOverlays.splice(index, 1)
          }
        } else {
          this.mixinSelectPoi(this.map, item)
        }
      }
    },
    d_key3Change(val) {
      this.circleTable.pageIndex = 1
      debounce(this.fetchRegionCircleData(), 300)
    },
    d_key5Change() {
      this.roadTable.pageIndex = 1
      debounce(this.fetchRoadList(), 300)
    },
    handlerRelation(index, row) {
      const temp = {
        type: index,
        obj: row
      }
      this.$emit('relation', temp)
    },
    openDialogList(typeStatus, id) {
      const obj = {
        type: typeStatus,
        PolygonID: id
      }
      this.$emit('openPolygonlist', obj)
    },
    hanlderRoadExprot() {
      this.$emit('roadExprot')
    },
    handlerPoiAdd() {
      const handler = async e => {
        this.map.off('click', handler)
        let err = this.$t('onlineMonitor.savePoiTitle')
        let err2 = this.$t('onlineMonitor.savePoiTip2')
        const {
          value
        } = await this.$prompt(this.$t('onlineMonitor.savePoiTitle'), this.$t('onlineMonitor.savePoiTip'), {
          confirmButtonText: this.$t('common.save'),
          cancelButtonText: this.$t('common.cancel'),
          inputValidator(val) {
            if (!val) {
              return err
            } else if (val.length > 50) {
              return err2
            } else {
              return true
            }
          }
        })
        const poi = {
          HoldID: -1,
          Lon_F: this.currentMap === 'Bmap' ? e.point.lng : e.lnglat.getLng(),
          Lat_F: this.currentMap === 'Bmap' ? e.point.lat : e.lnglat.getLat(),
          PointName: value,
          PointType: 5,
          Remark: ''
        }
        AddPoi(poi).then(ret => {
          this.mixinSelectPoi(this.map, ret.data)
          this.$message({
            message: this.$t('onlineMonitor.saveSuccessed'),
            type: 'success'
          })
          this.fetchDataList()
        })
      }
      this.map.on('click', handler, this)
      this.$message(this.$t('onlineMonitor.savePoiTip3'))
    },
    handlerPolygonAdd(type, item) {
      this.polygonType = type
      this.polygonData.item = item
      this.removeRegionFromMap()
      if (type === 5) { // 编辑线路
        if (item) {
          let index = null
          index = this.mixinRoadOverlays.findIndex(k => k.getExtData() === item.PolygonID)
          if (index != -1) {
            this.mixinRoadOverlays[index].setMap(null)
            this.mixinRoadOverlays[index] = null
            this.mixinRoadOverlays.splice(index, 1)
            this.checkRoadList.splice(index, 1)
          }
          this.fetchRoadInfo(item.PolygonID).then((data) => {
            item.Points = data
            this.polygonData.item = item
            this.hanlderPolylineToMap(item, this.map)
            setTimeout(() => {
              this.hanlderDrawPolyonEdit()
            }, 10)
          })
        } else {
          this.hanlderDrawPolyline()
        }
      } else if (type === 2) { // 编辑多边形围栏
        if (item) {
          let index = null
          index = this.mixinRegionOverlays.findIndex(k => k.getExtData() === item.RegionID)
          if (index != -1) {
            this.mixinRegionOverlays[index].setMap(null)
            this.mixinRegionOverlays[index] = null
            this.mixinRegionOverlays.splice(index, 1)
            this.checkRegionList.splice(index, 1)
          }
          this.hanlderPolyonToMap(item, this.map)
          setTimeout(() => {
            this.hanlderDrawPolyonEdit()
          }, 10)
        } else {
          this.hanlderDrawPolyon()
        }
      } else if (type === 3) { // 编辑圆形围栏
        if (item) {
          let index = null
          index = this.mixinCircleOverlays.findIndex(k => k.getExtData() === item.CircleID)
          if (index != -1) {
            this.mixinCircleOverlays[index].setMap(null)
            this.mixinCircleOverlays[index] = null
            this.mixinCircleOverlays.splice(index, 1)
            this.checkCircleList.splice(index, 1)
          }
          this.hanlderCircleToMap(item, this.map)
          setTimeout(() => {
            this.hanlderDrawPolyonEdit()
          }, 10)
        } else {
          this.hanlderDrawCircle()
        }
      } else if (type === 1) {
        this.handlerPoiAdd()
      }
    },
    hanlderDrawPolyline() {
      console.log(1)
      this.map.plugin(['AMap.MouseTool'], () => {
        this.poiEditStatus = 2 // 开始在地图画笔绘制
        let mousetool = new window.AMap.MouseTool(this.map)
        mousetool.polyline({
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1'
        })
        mousetool.on('draw', ({
          obj
        }) => {
          const points = obj.getPath()
          this.polygonData.points = points
          this.polygonData.polygon = obj
          mousetool.close()
          console.log(1)
          this.poiEditStatus = 1 // 画笔绘制结束
        })
        this.mousetool = mousetool
      })
    },
    hanlderPolylineToMap(row, map) {
      console.log(row)
      this.poiEditStatus = 4 // 选择路线到地图
      map = map || this.map
      // this.removeRegionFromMap()
      let polygon = new window.AMap.Polyline({
        map,
        path: row.Points.map(j => [j.Lon, j.Lat]),
        strokeColor: 'red',
        strokeOpacity: '0.9',
        strokeWeight: '10',
        fillColor: '#1890ff',
        fillOpacity: '0.1',
        extData: row.PolygonID
      })
      this.polygonData.polygon = polygon
      map.setFitView([polygon])
    },
    hanlderPolyonToMap(row, map) {
      console.log(row)
      this.poiEditStatus = 4 // 选择围栏到地图
      map = map || this.map
      // this.removeRegionFromMap()
      let polygon = new window.AMap.Polygon({
        map,
        path: row.points.map(j => [j.Lon, j.Lat]),
        strokeColor: '#1890ff',
        strokeOpacity: '0.9',
        fillColor: '#1890ff',
        fillOpacity: '0.1'
      })
      this.polygonData.polygon = polygon
      map.setFitView([polygon])
    },
    hanlderCircleToMap(row, map) {
      this.poiEditStatus = 4 // 选择围栏到地图
      map = map || this.map
      // this.removeRegionFromMap()
      let polygon = new window.AMap.Circle({
        map,
        center: [row.Lon, row.Lat],
        radius: row.R, //半径
        strokeColor: '#1890ff',
        strokeOpacity: '0.9',
        fillColor: '#1890ff',
        fillOpacity: '0.1'
      })
      this.polygonData.polygon = polygon
      map.setFitView([polygon])
    },
    hanlderDrawCircle() {
      this.map.plugin(['AMap.MouseTool'], () => {
        this.poiEditStatus = 2 // 开始在地图画笔绘制
        let mousetool = new window.AMap.MouseTool(this.map)
        mousetool.circle({
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1'
        })
        mousetool.on('draw', ({
          obj
        }) => {
          const points = {
            center: obj.getCenter(),
            radius: obj.getRadius()
          }
          this.polygonData.points = points
          this.polygonData.polygon = obj
          mousetool.close()
          this.poiEditStatus = 1 // 画笔绘制结束
        })
        this.mousetool = mousetool
      })
    },
    hanlderDrawPolyon() {
      this.map.plugin(['AMap.MouseTool'], () => {
        this.poiEditStatus = 2 // 开始在地图画笔绘制
        let mousetool = new window.AMap.MouseTool(this.map)
        mousetool.polygon({
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1'
        })
        mousetool.on('draw', ({
          obj
        }) => {
          const points = obj.getPath()
          this.polygonData.points = points
          this.polygonData.polygon = obj
          console.log(obj)
          console.log(points)
          mousetool.close()
          this.poiEditStatus = 1 // 画笔绘制结束
        })
        this.mousetool = mousetool
      })
    },
    removeRegionFromMap() {
      this.polygonData.polygon && this.polygonData.polygon.setMap(null)
    },
    handlerDrawExit() { // 退出编辑
      this.removeRegionFromMap()
      this.poiEditStatus = 0
      if (this.msTool) {
        this.msTool.on('end', () => {
          this.polygonData.points = []
          this.polygonData.polygon = ''
        })
      } else {
        this.msTool = null
      }
      if (this.mousetool) {
        this.mousetool = null
      }
      this.polygonData.points = []
      this.polygonData.polygon = ''
    },
    hanlderDrawPolyonEdit() {
      // this.removeRegionFromMap()
      const obj = this.polygonData.polygon
      if (this.polygonType === 2) {
        this.map.plugin(['AMap.PolyEditor'], () => {
          let msTool = new window.AMap.PolyEditor(this.map, obj)
          this.poiEditStatus = 3 // 多边形围栏编辑工具开启
          msTool.open()
          msTool.on('end', ({
            target
          }) => {
            this.polygonData.points = target.getPath()
            this.polygonData.polygon = target
          })
          this.msTool = msTool
        })
      } else if (this.polygonType === 3) {
        this.map.plugin(['AMap.CircleEditor'], () => {
          let msTool = new window.AMap.CircleEditor(this.map, obj)
          this.poiEditStatus = 3 // 圆围栏编辑工具开启
          msTool.open()
          msTool.on('end', ({
            target
          }) => {
            const points = {
              radius: target.getRadius(),
              center: target.getCenter()
            }
            this.polygonData.points = points
            this.polygonData.polygon = target
          })
          this.msTool = msTool
        })
      } else if (this.polygonType === 5) {
        this.map.plugin(['AMap.PolyEditor'], () => {
          let msTool = new window.AMap.PolyEditor(this.map, obj)
          this.poiEditStatus = 3 // 路径编辑工具开启
          msTool.open()
          msTool.on('end', ({
            target
          }) => {
            this.polygonData.points = target.getPath()
            this.polygonData.polygon = target
          })
          this.msTool = msTool
        })
      }
    },
    hanlderendEdit() {
      this.poiEditStatus = 1 // 工具编辑围栏结束
      this.msTool.close()
      this.msTool = null
    },
    handlerDrawReset() {
      // 重新绘制
      this.removeRegionFromMap()
      this.mousetool = null
      this.msTool = null
      if (this.polygonType === 2) {
        this.hanlderDrawPolyon()
      } else if (this.polygonType === 3) {
        this.hanlderDrawCircle()
      } else if (this.polygonType === 5) {
        this.hanlderDrawPolyline()
      } else {
        console.log('重新绘制错误')
      }
    },
    hanlderSave() {
      if (this.polygonType === 2) {
        this.hanlderSavePolygon()
      } else if (this.polygonType === 3) {
        this.hanlderSaveCircle()
      } else if (this.polygonType === 5) {
        this.hanlderSaveRoad(this.polygonData.item, this.polygonData.points)
      } else {
        console.log('保存类型错误')
      }
    },
    async hanlderSavePolygon() {
      // 保存多边形围栏
      const list = (await ConvertFrom(this.polygonData.points, 'marsr', 'gps')).data
      const minLon = Math.min(...list.map(k => k.lng))
      const maxLon = Math.max(...list.map(k => k.lng))
      const minLat = Math.min(...list.map(k => k.lat))
      const maxLat = Math.max(...list.map(k => k.lat))
      this.$prompt(this.$t('regionManage.enterRegionTip'), '', {
        inputValue: (this.polygonData.item && this.polygonData.item.RegionName) || '',
        confirmButtonText: this.$t('common.save'),
        cancelButtonText: this.$t('common.cancel'),
      }).then(({
        value
      }) => {
        const regionId = this.polygonData.item && this.polygonData.item.RegionID
        SaveRegion(regionId, value, maxLon, maxLat, minLon, minLat, list.map(k => `${k.lng},${k.lat}`)).then(
          ret => {
            if (ret.data.RegionID) {
              this.$message({
                type: 'success',
                message: '保存围栏: ' + value + "成功"
              });
              this.poiEditStatus = 0
              this.mousetool = null
              this.msTool = null
              let temp = {
                type: 1,
                obj: {
                  RegionID: ret.data.RegionID,
                  PolygonName: value
                }
              }
              const id = ret.data.RegionID
              this.fetchRegionData().then(() => {
                this.RegionList.forEach((item) => {
                  if (item.RegionID === id) {
                    this.mixinSelectRegion(this.map, item)
                    this.checkRegionList.push(id)
                  }
                })
              })
              // this.$refs.mapPoi.fetchData()
              // this.$refs.dialogPolyon.open(temp)
              // this.$refs.mapPoi.removerAllRegion()
            }
          }).finally(() => {
            this.removeRegionFromMap()
            this.poiEditStatus = 0
            this.mousetool = null
            this.msTool = null
          })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    async hanlderSaveCircle() {
      // 保存圆形围栏
      let p = this.polygonData.points.center
      const list = (await ConvertFrom([{
        lng: p.lng,
        lat: p.lat
      }], 'marsr', 'gps')).data
      this.$prompt(this.$t('regionManage.enterRegionTip'), '', {
        inputValue: (this.polygonData.item && this.polygonData.item.CircleName) || '',
        confirmButtonText: this.$t('common.save'),
        cancelButtonText: this.$t('common.cancel'),
      }).then(({
        value
      }) => {
        console.log(value)
        const regionId = (this.polygonData.item && this.polygonData.item.CircleID) || 0
        let obj = {
          CircleID: regionId,
          CircleName: value,
          CreateTime: miment(),
          HoldID: this.loginInfo.HoldID,
          Lat: list[0].lat,
          Lon: list[0].lng,
          R: parseInt(this.polygonData.points.radius),
          UpdateTime: miment(),
          UserID: this.loginInfo.UserID
        }
        SaveCenterCircle([obj], this.polygonData.item.RoadID || 0).then(ret => {
          if (!ret.data.errCode) {
            console.log(ret.data)
            const id = ret.data.RegionList[0].CircleID
            this.$message({
              type: 'success',
              message: '保存圆形围栏: ' + value + "成功"
            });
            this.poiEditStatus = 0
            this.mousetool = null
            this.msTool = null
            this.circleTable.pageIndex = 1
            this.fetchRegionCircleData().then(() => {
              this.CircleList.forEach((item) => {
                if (item.CircleID === id) {
                  this.mixinSelectCircle(this.map, item)
                  this.checkCircleList.push(id)
                }
              })
            })
          }
        }).finally(() => {
          this.removeRegionFromMap()
          this.poiEditStatus = 0
          this.mousetool = null
          this.msTool = null
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    async hanlderSaveRoad() {
      // 保存线路
      const list = (await ConvertFrom(this.polygonData.points, 'marsr', 'gps')).data
      this.$prompt('请输入线路名称', '', {
        inputValue: (this.polygonData.item && this.polygonData.item.PolygonName) || '',
        confirmButtonText: this.$t('common.save'),
        cancelButtonText: this.$t('common.cancel'),
      }).then(({
        value
      }) => {
        const regionId = (this.polygonData.item && this.polygonData.item.PolygonID) || 0
        const obj = {
          RoadName: value,
          RoadWidth: 10,
          LimitSpeed: 0,
          StartTime: '00:00:00',
          EndTime: '23:59:59',
        }
        ROAD.SaveRegion(regionId, obj, list.map(k => {
          return {
            Lon: k.lng,
            Lat: k.lat
          }
        })).then(ret => {
          if (!ret.data.errCode) {
            const id = ret.data.data.RoadID
            this.$message({
              type: 'success',
              message: '保存线路: ' + obj.RoadName + "成功"
            });
            this.poiEditStatus = 0
            this.mousetool = null
            this.msTool = null
            this.roadTable.pageIndex = 1
            this.fetchRoadList().then(() => {
              this.RoadList.forEach((item) => {
                if (item.PolygonID === id) {
                  this.fetchRoadInfo(id).then((data) => {
                    item.Points = data
                    this.mixinSelectRoad(this.map, item)
                  })
                  this.checkRoadList.push(id)
                }
              })
            })

            // let temp = {
            //   type: 2,
            //   obj: {
            //     RegionID: ret.data.Region.CircleID,
            //     PolygonName: value
            //   }
            // }
          }
        }).finally(() => {
          this.removeRegionFromMap()
          this.poiEditStatus = 0
          this.mousetool = null
          this.msTool = null
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    handlerPolyonRemove(type, row) {
      if (type === 5) { // 删除线路
        this.deleteRoad(row)
      } else if (type === 2) { // 删除多边形围栏
        this.deleteRegion(row)
      } else if (type === 1) {
        this.deletePoi(row)
      } else if (type === 3) {
        this.deleteCircle(row)
      }
    },
    handleCurrentChangeCircle(val) {
      this.circleTable.pageIndex = val
      this.fetchRegionCircleData()
    },
    handleCurrentChangeRoad(val) {
      this.roadTable.pageIndex = val
      this.fetchRoadList()
    },
    handlerRoadActivate(item) {
      const promise = new Promise((resolve, reject) => {
        ModifyRoadActivate(item.PolygonID, item.IsActivate ? false : true).then(ret => {
          if (!ret.data.errCode) {
            this.$message({
              type: 'success',
              message: item.IsActivate ? '取消' : '激活' + "成功"
            });
            this.fetchDataList()
          }
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
      return promise
    },
    handlerNav(item) {
      this.currentIndex = item.value
      this.fetchDataList(item.value)
      this.polygonData.polygon.setMap(null)
      if (this.poiEditStatus) {
        if (this.poiEditStatus === 3) {
          this.hanlderendEdit()
          setTimeout(() => {
            this.handlerDrawExit()
          }, 500)
        } else {
          this.handlerDrawExit()
        }
        // this.hanlderendEdit()
        // this.handlerDrawExit()
      }
      this.removeRegionFromMap()
    },
    fetchDataList(eq) {
      const index = eq || this.currentIndex
      this.tableLoading = true

      if (index === 1) {
        this.fetchPoiData()
      } else if (index === 2) {
        if (this.objectId && this.byId2) {
          this.fetchRegionByID()
        } else {
          this.fetchRegionData()
        }
      } else if (index === 3) {
        this.circleTable.pageIndex = 1
        this.fetchRegionCircleData()
      } else if (index === 4) {

      } else if (index === 5) {
        this.roadTable.pageIndex = 1
        this.fetchRoadList()
      }
    },
    fetchPoiData() {
      const promise = new Promise((resolve, reject) => {
        GetPOI('', true).then(ret => {
          this.POIList = ret.data.splice(0, 500)
          resolve()
        }).catch(err => {
          reject(err)
        }).finally(() => {
          this.tableLoading = false
        })
      })
      return promise
    },
    deletePoi(item) {
      API.DeletePoi([item.PointID]).then(() => {
        this.$message({
          message: this.$t('onlineMonitor.deletePoiTip', {
            name: item.PointName
          }),
          type: 'success'
        })
        let index = null
        index = this.mixinPoiOverlays.findIndex(k => k.getExtData() === item.PointID)
        if (index != -1) {
          this.mixinPoiOverlays[index].setMap(null)
          this.mixinPoiOverlays[index] = null
          this.mixinPoiOverlays.splice(index, 1)
        }
        this.map.clearInfoWindow()
        let target = findFromArray(this.mixinPoiOverlays, k => k.getExtData() === item.PointID)
        if (target) {
          target.setMap(null)
          target = null
        }
        deleteFromArray(this.POIList, k => {
          return k.PointID === item.PointID
        })
        deleteFromArray(this.POIList_B, k => {
          return k.PointID === row.PointID
        })
      })
    },
    fetchRegionData() {
      const promise = new Promise((resolve, reject) => {
        let obj = {
          point_dongbei: {
            lat: 63.511341,
            lng: -125.743726
          },
          point_xinan: {
            lat: -5.349134,
            lng: -57.540601
          }
        }
        const point_dongbei = obj.point_dongbei
        const point_xinan = obj.point_xinan
        GetRegionByLngLat(point_xinan.lng, point_xinan.lat, point_dongbei.lng, point_dongbei.lat).then(ret => {
          //console.log(ret.data)
          this.RegionList = ret.data
          resolve()
        }).catch(err => {
          reject(err)
        }).finally(() => {
          this.tableLoading = false
        })
      })
      return promise
    },
    fetchRegionByID() {
      if (!this.objectId) {
        this.RegionList = []
        this.tableLoading = false
        return
      }
      const promise = new Promise((resolve, reject) => {
        GetRegionByObjectID(this.objectId).then(ret => {
          this.RegionList = ret.data
          resolve()
        }).catch(err => {
          reject(err)
        }).finally(() => {
          this.tableLoading = false
        })
      })
      return promise
    },
    async deleteRegion(row) {
      try {
        await this.$confirm(this.$t('regionManage.deleteTip', {
          name: row.RegionName
        }), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        })
      } catch (err) {
        return
      }
      REGION.DeleteRegion(row.RegionID).then(ret => {
        if (!ret.errCode) {
          this.$message({
            message: this.$t('regionManage.deleteSuccessed'),
            type: 'success'
          })
          let index = null
          index = this.mixinRegionOverlays.findIndex(k => k.getExtData() === row.RegionID)
          if (index != -1) {
            this.mixinRegionOverlays[index].setMap(null)
            this.mixinRegionOverlays[index] = null
            this.mixinRegionOverlays.splice(index, 1)
          }
          deleteFromArray(this.RegionList, k => {
            return k.RegionID === row.RegionID
          })
          deleteFromArray(this.checkRegionList, k => {
            return k.RegionID === row.RegionID
          })
          this.fetchDataList()
        } else {
          this.$message({
            message: ret.errMsg,
            type: 'error'
          })
        }
      })
    },
    fetchRegionCircleData(id) {
      let objId = id || ''
      const promise = new Promise((resolve, reject) => {
        GetRegionCircleListByObjectID(true, this.key3 || '', objId || '', this.circleTable.pageIndex, this.circleTable
          .pageSize, this.loginInfo.HoldID)
          .then(ret => {
            this.CircleList = ret.data.data
            this.circleTable.total = ret.data.total
            resolve()
          }).catch(err => {
            reject(err)
          }).finally(() => {
            this.tableLoading = false
          })
      })
      return promise
    },
    async deleteCircle(row) {
      try {
        await this.$confirm(this.$t('regionManage.deleteTip', {
          name: row.CircleName
        }), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        })
      } catch (err) {
        return
      }
      DeleteCenterCircle([row.CircleID]).then(ret => {
        if (!ret.errCode) {
          this.$message({
            message: this.$t('regionManage.deleteSuccessed'),
            type: 'success'
          })
          let index = null
          index = this.mixinCircleOverlays.findIndex(k => k.getExtData() === row.CircleID)
          if (index != -1) {
            this.mixinCircleOverlays[index].setMap(null)
            this.mixinCircleOverlays[index] = null
            this.mixinCircleOverlays.splice(index, 1)
          }
          deleteFromArray(this.CircleList, k => {
            return k.CircleID === row.CircleID
          })
          this.fetchDataList()
        } else {
          this.$message({
            message: ret.errMsg,
            type: 'error'
          })
        }
      })
    },
    fetchRoadList() {
      const promise = new Promise((resolve, reject) => {
        GetRoadList(true, this.key5 || '', this.roadTable.pageIndex, this.roadTable.pageSize).then(ret => {
          this.RoadList = ret.data.pageData
          this.roadTable.total = ret.data.total
          resolve()
        }).catch(err => {
          reject(err)
        }).finally(() => {
          this.tableLoading = false
        })
      })
      return promise
    },
    fetchCircleListByRoadId(id) { // 查看线路关联的圆形围栏
      const promise = new Promise((resolve, reject) => {
        GetRegionCircleListByRoadId(id).then(ret => {
          resolve(ret.data.data || [])
        }).catch(err => {
          reject(err)
        }).finally(() => {
          this.tableLoading = false
        })
      })
      return promise
    },
    fetchRoadInfo(id) {
      const promise = new Promise((resolve, reject) => {
        GetRoadInfo(id).then(ret => {
          resolve(ret.data.points)
        }).catch(err => {
          reject(err)
        }).finally(() => {
          this.tableLoading = false
        })
      })
      return promise
    },
    fetchActiveRoad() {
      const promise = new Promise((resolve, reject) => {
        GetRoadList(true, '', 1, 5).then(ret => {
          let data = ret.data.pageData
          let check = null
          data.forEach((k) => {
            if (k.IsActivate === 1) {
              check = k
            }
          })
          check && this.fetchRoadInfo(check.PolygonID).then((data) => {
            check.Points = data
            if (!this.checkRoadList.includes(check.PolygonID)) {
              this.checkRoadList.push(check.PolygonID)
            }
            setTimeout(() => {
              resolve()
              this.mixinSelectRoad(this.map, check)
            }, 800);
          })
          check && this.fetchCircleListByRoadId(check.PolygonID).then((item) => {
            setTimeout(() => {
              resolve()
              item.length && this.mixinSelectCircle(this.map, item)
            }, 800);
          })
        }).catch(err => {
          reject(err)
        }).finally(() => {
          this.tableLoading = false
        })
      })
      return promise
    },
    async deleteRoad(row) {
      try {
        await this.$confirm(this.$t('regionManage.deleteTip', {
          name: row.PolygonName
        }), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        })
      } catch (err) {
        return
      }
      ROAD.DeleteRegion(row.PolygonID).then(ret => {
        if (!ret.errCode) {
          this.$message({
            message: this.$t('regionManage.deleteSuccessed'),
            type: 'success'
          })
          let index = null
          index = this.mixinRoadOverlays.findIndex(k => k.getExtData() === row.PolygonID)
          if (index != -1) {
            this.mixinRoadOverlays[index].setMap(null)
            this.mixinRoadOverlays[index] = null
            this.mixinRoadOverlays.splice(index, 1)
          }
          deleteFromArray(this.RoadList, k => {
            return k.PolygonID === row.PolygonID
          })
          this.fetchDataList()
        } else {
          this.$message({
            message: ret.errMsg,
            type: 'error'
          })
        }
      })
    },
  },
  created() {
    this.key1Change = () => {
      let key = this.key1
      let list = this.POIList
      this.POIList_B = list.filter(k => k.PointName.indexOf(key) != -1)
    }
    this.key2Change = () => {
      let key = this.key2
      let list = this.RegionList
      this.RegionList_B = list.filter(k => k.RegionName.indexOf(key) != -1)
    }
    this.d_key1Change = debounce(this.key1Change, 300)
    this.d_key2Change = debounce(this.key2Change, 300)
  },
  mounted() {
    this.fetchDataList()
    if (this.showRoad) {
      this.fetchActiveRoad()
    }
  }
}

</script>

<style lang="scss" scoped>
.v-block-polygon {
  position: absolute;
  right: 108px;
  top: 7px;
  z-index: 1;

  .v-tit {
    width: 100%;
    height: 40px;
    position: relative;
    cursor: pointer;

    h2 {
      font-size: 16px;
      line-height: 40px;
      color: #fff;
      text-indent: 30px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .v-content {
    width: 360px;
    position: relative;
    z-index: 1;

    .slideArrow {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 1px;
      top: 42px;
      z-index: 2;

      span {
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: -1px;
        margin-top: -10px;
        z-index: 1;

        img {
          display: block;
          width: 24px;
          height: 24px;
        }
      }
    }

    // .rote{
    //   transform: rotate(180deg);
    //   -ms-transform: rotate(180deg);
    //   -moz-transform: rotate(180deg);
    //   -webkit-transform: rotate(180deg);
    //   -o-transform: rotate(180deg);
    // }
  }

  .titNav {
    display: flex;
    background-color: #213546;

    li {
      width: 25%;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #EAEAEA;
      text-align: center;
      cursor: pointer;
      background: url(../../assets/images/form-p3.png);
      background-size: 100% 100%;
    }

    li:hover {
      color: #00FFFF;
      // background: url(../../assets/images/form-p2.png);
      // background-size: 100% 100%;
    }

    .active {
      color: #00FFFF;
      background: url(../../assets/images/form-p2.png);
      background-size: 100% 100%;
    }
  }

  .list {
    height: 350px;
    padding-top: 10px;
    background: url(../../assets/images/gs-py1.png) repeat-y center;

    .scroll {
      height: 300px;

      .con {
        height: 100%;

        & ::v-deep .el-scrollbar__wrap {
          height: calc(100% + 17px);
        }
      }


      .blockPages {
        text-align: center;

        & ::v-deep .el-pager {
          li {
            background: none;
            color: #fff;
          }

          .active {
            color: #00FFFF;
          }
        }

        & ::v-deep .el-pagination__total {
          color: #fff;
        }

        & ::v-deep.btn-next {
          background: none;
          color: #fff;
        }

        & ::v-deep.btn-prev {
          background: none;
          color: #fff;
        }
      }
    }

    .pg {
      .con {
        height: calc(100% - 30px);
      }
    }

    li {
      display: flex;
      padding: 0 20px;
      height: 36px;
      line-height: 36px;

      & ::v-deep .el-popove {
        min-width: 80px;
      }

      & ::v-deep .el-checkbox {
        width: 50%;

        // word-break: break-all;
        .el-checkbox__label {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          position: relative;
          top: 5px;
        }
      }

      .tagExprot {
        cursor: pointer;
        display: inline-block;
        background-color: #103249;
        line-height: 26px;
        height: 26px;
        border-radius: 5px;
        padding: 0 10px;
        color: #fff;
        font-size: 12px;
        border: 1px solid #3A6886;
        position: absolute;
        right: 13px;
        top: 5px;
        z-index: 1;
      }

      .tags {
        width: 50%;
        text-align: right;
      }

      & ::v-deep .el-checkbox__label {
        color: #fff;
      }

      .tag {
        font-size: 14px;
        color: #fff;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .caption {
      display: flex;
      height: 30px;
      padding: 0 10px;
      margin-bottom: 5px;
      margin-left: 35px;

      .el-input {
        & ::v-deep .el-input__inner {
          background-color: #182B3D;
          border-color: #182B3D;
          border-radius: 15px;
          color: #fff;
        }
      }

      .eIcon {
        margin-left: 8px;
        padding-top: 5px;

        img {
          display: block;
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .gap {
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }

}

.v-poi-tool {
  width: 520px;
  text-align: center;
  position: fixed;
  top: 120px;
  left: 50%;
  margin-left: -400px;
  z-index: 2;

  p {
    background-color: #1890ff;
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
  }

  span {
    display: inline-block;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    background: url(../../assets/images/form-btn1.png) no-repeat center;
    background-size: auto 100%;
    color: #2F302C;
    font-size: 14px;
    padding: 0px 20px;
    margin-left: 20px;
  }

  // span:hover {
  //   background-color: #1890ff;
  //   color: #fff;
  // }
}
</style>

<template>
  <!-- <main-wrapper tool-background-image="linear-gradient(135deg, #C3BEF0 0%, #DEFCF9 100%)" border-left-color="#C3BEF0">
    <template slot="tool">
      <span class="sub-title ipx-1">电子围栏</span>
    </template> -->
    <div class="v-wrapper tool--none">
      <div class="v-nav">
        <div class="v-area v-nav-polygon">
          <!-- <p class="v-area__title">围栏列表</p> -->
          <p class="imt-3 imb-0 imx-3">
            <!-- `<span class="high--text">${regionList.length}</span>` -->
            {{$t('regionManage.totalTip', {count: regionList.length})}}
            <el-checkbox v-model="containChild" style="float:right;">{{$t('regionManage.includeChild')}}</el-checkbox>
          </p>

          <div class="v-split-h imt-3 imb-0 imx-0"></div>
          <div class="imt-0 imx-0">
            <el-input class="risk-polygon-query" :placeholder="$t('onlineMonitor.favoriteRegionTip')" v-model="queryKey" clearable></el-input>
          </div>
          <div class="region imy-0" :class="{'active': activeRegionId == row.PolygonID}" v-for="row in tableList" :key="row.PolygonID" @click="() => rowClick(row)">
            <div class="content">
              <p class="region__name" :title="$t('regionManage.bindCountTip', {count: row.DeviceCount})">{{row.PolygonName}}[{{row.PolygonID}}]</p>
              <p class="region__desc" v-if="false">已绑定{{row.DeviceCount}}台设备</p>
            </div>
            <div class="option" @click.stop>
              <i-drop-down trigger="click" :show-text="$t('common.operate')">
                <!-- <i-icon name="icon-menu" style="font-size:22px;width:16px;" slot="active"></i-icon> -->
                <i class="el-icon-more-outline" style="font-size:14px;width:16px;margin-top:5px;" slot="active"></i>
                <i-drop-down-option :label="$t('regionManage.modify')" value="0" @click="modifyRegion(row)"></i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.deflete')" value="1" @click="deleteRegion(row)"></i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.viewDetail')" value="3" @click="openDialogList(row)"></i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.contactRegion')" value="3" @click="openRegionObject(row)"></i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.cancelContact')" value="4" @click="openDialogList(row)"></i-drop-down-option>
              </i-drop-down>
            </div>
          </div>

          <el-pagination
            class="v-pager"
            v-if="regionList.length > 8 && regionList.length != 0"
            background
            layout="prev, jumper, next"
            :current-page.sync="table.pageIndex"
            :page-size="table.pageSize"
            :total="regionList.length">
          </el-pagination>
          <p v-if="regionList.length <=8 && regionList.length != 0" class="imy-1 caption" style="text-align:center;color: #B0BEC5;">{{$t('common.noMoreResults')}}</p>
          <div class="imx-0 imt-0 imb-0" style="position: relative;">
            <el-button type="primary" class="border" plain style="width: 100%;border-radius:20px;" @click="addRegion">{{$t('regionManage.add')}}</el-button>
          </div>
        </div>
        <!-- <div class="v-area">
          <p class="v-area__title"></p>

        </div> -->
      </div>
      <div class="v-body" ref="container"></div>
      <select-car style="position:absolute;left: 274px;top:24px;z-index:10;" @select="carSelect"></select-car>
      <!--区域车辆-->
      <transition name="fade-polygon" >
      <div class="fixedTop" v-if="showBindObject" :key="activePolygon.PolygonID">
        <div class="fixedTopHeader">
          {{activePolygon.PolygonName}}
          <span class="fixedTopHeaderClose">
            <i class="el-icon-refresh" @click="refreshBindObject"></i>
            <i class="el-icon-close" @click="showBindObject = false"></i>
          </span>
        </div>
        <div class="vehicle-split"></div>
        <ul v-if="activePolygon.list.length">
          <li v-for="(item,index) in activePolygon.list.slice(0, 10)" :key="index">
            <span>
              {{item.VehicleName}}<span class="IOType">{{$t('regionManage.ioTypeList')[item.IOType] || '--'}}</span>
            </span>
            <p class="SIM2">
              {{item.SIM2}}
              <span class="Speed" v-if="item.CheckSpeed">({{$t('regionManage.limitTip2', {speed: item.MaxSpeed})}})</span>
            </p>
          </li>
        </ul>
        <p class="nodata" v-else>
          {{$t('regionManage.nodata')}}
        </p>
        <p class="viewAll" v-if="activePolygon.list.length" @click="openDialogList(activePolygon)">
          {{$t('regionManage.viewAll')}}
        </p>
      </div>
      </transition>

      <el-dialog @closed="resetDialog" append-to-body :title="dialogTitle" :visible.sync="dialogVisible" custom-class="v-dialog v-dialog--lg v-dialog--mdpadding pt10 v-dialog--footer">
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane :label="$t('regionManage.regionSetting')" name="first">
            <div class="tab-wrapper" ref="tabContainer">
              <div class="v-tool" :class="{'minimize':isMinimize}">
                <span class="tip"> {{ (!!modifyRow && !isMinimize) ? $t('regionManage.clearTip') : $t('regionManage.addTip')}}
                  <!-- <transition name="scale-shrink">
                    <el-button v-if="isDrawEnd" @click="startDraw" class="flat-btn border" type="primary" plain>{{ $t('regionManage.drawAgain')}}</el-button>
                  </transition> -->
                  <transition name="scale-shrink">
                    <div v-if="isDrawEnd" class="flat-btn" style="padding: 0 4px">
                      <el-button @click="modifyDraw" class="border" type="primary" plain>{{ $t('regionManage.editPoly')}}</el-button>
                      <el-button @click="startDraw" class="border" type="primary" plain>{{ $t('regionManage.drawAgain')}}</el-button>
                    </div>
                    <div class="flat-btn ipx-0" style="padding: 0 4px" v-else>
                      <el-button v-if="isEditing" @click="endEdit" class="border" type="primary" plain>{{ $t('regionManage.endEdit')}}</el-button>
                    </div>
                  </transition>
                </span>
                <p class="start" v-if="!modifyRow" @click="startDraw">
                  <span>{{$t('regionManage.start')}}</span>
                </p>
                <template v-else>
                  <p class="start">
                    <span @click="startDraw">{{$t('regionManage.drawAgain')}}</span>
                    <span @click="modifyDraw" class="iml-5">{{$t('regionManage.editPoly')}}</span>
                  </p>
                </template>
              </div>
              <div class="tab-place-search">
                <map-place @select="(lng,lat) => tabMap.setZoomAndCenter(17, [lng,lat])"/>
              </div>
              <select-car style="position:absolute;right:24px;top:50px;z-index:10;" @select="carSelectTab"></select-car>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('regionManage.contactRegion')" name="second">
            <div class="tab-wrapper tab--flex">
              <div class="tabPanels">
  <div class="cols step1">
     <tree-user
        v-if="dialogVisible"
        :title="$t('regionManage.selectVehicle')"
        show-icon
        show-vehicle
        :widthFixed ='false'
        :show-object-count.sync="showObjectCount"
        :use-local-setting="false"
        @check="treeCheck"
      ></tree-user>
  </div>
  <div class="cols step2">
      <div class="searchInput">
        <SelectDevice @select="handleQuery" :clear="searchCheck.VehicleName"></SelectDevice>
        <div class="group"><el-tag size="mini" v-if="searchCheck" clsss="tag" closable @close="clearSearch" >{{searchCheck.VehicleName}}</el-tag></div>
      </div>
      <el-form ref="form" label-width="100px" label-position='top' size="small">
          <div class="solid">
              <el-form-item :label="$t('regionManage.regionName')">
                  <!-- <span class="label-form">{{$t('regionManage.regionName')}}</span> -->
                  <el-select v-model="form.regionId" :disabled="!!modifyRow">
                    <el-option v-for="item in regionList" :key="item.PolygonID" :label="item.PolygonName" :value="item.PolygonID"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item :label="$t('regionManage.recordType')">
                      <el-select v-model="form.IOType">
                    <el-option :label="$t('regionManage.enterRegion')" :value="1"></el-option>
                    <el-option :label="$t('regionManage.leaveRegion')" :value="2"></el-option>
                    <el-option :label="$t('regionManage.enterLeaveRegion')" :value="0"></el-option>
                    <el-option :label="$t('regionManage.enterLeaveRegionNoAction')" :value="3"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item :label="$t('regionManage.validTime')">
                <div class="elRow">
                  <div class="elCol">
                     <el-time-picker
                        class="v-range"
                        v-model="form.timeRange[0]"
                        :placeholder="$t('common.selectDate')"
                        :clearable="false">
                      </el-time-picker>
                  </div>
                   <div class="elCol">
                      <el-time-picker
                          class="v-range"
                          v-model="form.timeRange[1]"
                          :placeholder="$t('common.selectDate')"
                          :clearable="false">
                        </el-time-picker>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label-position='left'>
                  <div class="line">
                      <span @click="()=>{form.needAlarm =!form.needAlarm}">
                          <i-icon name="iconxuanzhong" v-if="form.needAlarm"></i-icon><i
                              v-else></i>
                      </span>
                      <p>{{$t('regionManage.triggerAlarm')}}</p>
                  </div>
              </el-form-item>
              <el-form-item label-position='left'>
                  <div class="line"><span @click="()=>{form.checkSpeed =!form.checkSpeed}">
                          <i-icon name="iconxuanzhong" v-if="form.checkSpeed"></i-icon><i
                              v-else></i>
                      </span>
                      <p>{{$t('regionManage.regionLimit')}}</p>
                      <div class="input">
                          <el-input v-model="form.maxSpeed" :disabled="!form.checkSpeed"
                              size="mini" :min="1" :max="999" :controls="false">
                              <template slot="append">{{$t('regionManage.limitTip')}}</template>
                          </el-input>
                      </div>
                  </div>
              </el-form-item>
              <el-form-item label-position='left'>
                  <div class="line">
                    <span @click="()=>{form.CheckRetention =!form.CheckRetention}">
                          <i-icon name="iconxuanzhong" v-if="form.CheckRetention"></i-icon><i
                              v-else></i>
                      </span>
                      <p>{{$t('regionManage.triggerAlarm2')}}</p>
                      <div class="input">
                          <el-input v-model="form.MaxRetentionMinutes" :disabled="!form.CheckRetention"
                              size="mini" :min="1" :max="999" :controls="false">
                              <template slot="append">{{$t('regionManage.limitTip2')}}</template>
                          </el-input>
                      </div>
                  </div>
              </el-form-item>
          </div>
          <div class="bot">
              <el-form-item :label="$t('regionManage.enterTip')">
                  <el-input v-model="form.inTips" maxlength="200"></el-input>
              </el-form-item>
              <el-form-item :label="$t('regionManage.leaveTip')">
                  <el-input v-model="form.outTips" maxlength="200"></el-input>
              </el-form-item>
              <p class="tips">*
                  {{$t('regionManage.regionTip')}}
              </p>
              <div class="clk" @click="() => openDialogList({PolygonID:form.regionId})">{{$t('regionManage.viewBindDevice')}}</div>
          </div>
      </el-form>
  </div>
</div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div slot="footer" class="dialog-footer">
          <el-button class="border" plain type="primary" @click="dialogVisible = false">{{$t('common.close')}}</el-button>
          <el-button class="border" plain v-if="activeName=='first'" type="primary" @click="saveAndNext" :loading="btnLoading" :disabled="!isDrawEnd">{{$t('common.next')}}</el-button>
          <el-button class="border" plain v-else type="primary" @click="addRegionObject" :loading="btnLoading" >{{$t('common.save')}}</el-button>
        </div>
      </el-dialog>
      <el-dialog append-to-body custom-class="v-dialog v-dialog--md v-dialog--mdpadding v-dialog--footer" :visible.sync="dialogListVisible" :title="$t('regionManage.vehiceBindList')">
        <el-table style="width: 100%" :data="dialogList">
          <el-table-column :label="$t('regionManage.vehicleName')" prop="VehicleName" width="120">
            <p slot-scope="{row}">
              {{row.VehicleName || row.SIM}}
            </p>
          </el-table-column>
          <el-table-column :label="$t('regionManage.deviceID')" prop="SIM2" width="110"></el-table-column>
          <el-table-column :label="$t('regionManage.lineType')" width="115">
            <template slot-scope="{row}">
              <span style="color:#3F51B5;">{{row.TrackerType==0?$t('dict')['有线设备']:$t('dict')['无线设备']}}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('regionManage.triggerAlarm')" width="120">
            <template slot-scope="{row}" >
              <p v-if="row.NeedAlarm" style="color:#4CAF50">{{$t('regionManage.yes')}}</p>
              <p v-else >{{$t('regionManage.no')}}</p>
            </template>
          </el-table-column>
          <el-table-column :label="$t('regionManage.recordType')" width="260">
            <template slot-scope="{row}">
              <p>{{$t('regionManage.ioTypeList')[row.IOType] || '--'}} / <span style="color:#3F51B5">{{row.StartTime}}-{{row.EndTime}}</span></p>
            </template>
          </el-table-column>
          <el-table-column :label="$t('regionManage.regionLimit')" width="90">
            <template slot-scope="{row}">
              <p v-if="row.CheckSpeed">{{$t('regionManage.yes')}} / <span style="color:#f44336">{{$t('regionManage.limitTip2', {speed: row.MaxSpeed})}}</span></p>
              <p v-else>{{$t('regionManage.no')}}</p>
            </template>
          </el-table-column>
          <el-table-column min-width="65">
            <p slot-scope="{row}" class="i-link" @click="deleteRegionObject(dialogListRegionId, row.ObjectID)">{{$t('regionManage.jiechu')}}</p>
          </el-table-column>
        </el-table>
        <div slot="footer">
          <el-button @click="dialogListVisible=false">{{$t('common.close')}}</el-button>
        </div>
      </el-dialog>
    </div>

  <!-- </main-wrapper> -->
</template>

<script>

import * as API from '@/api/region'
import IDropDown from '@/base/i-dropdown/i-dropdown'
import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
import { remoteLoad, deleteFromArray } from '@/common/utils'
import { ConvertFrom, GetObjectIdByMixID,SearchVehicleInfo} from '@/api/common'
import TreeUser from '@/views/common/tree-user'
import miment from 'miment'
import mixinMapCachePosition from '@/views/mixins/mixin-map-cache-position'
import {debounce} from '@/common/utils'
import MapPlace from '@/views/common/map-place'
import SelectCar from '@/views/common/select-car'
import SelectDevice from '@/views/common/select-device'

export default {
  name: 'riskPolygonBing',
  data() {
    return {
      queryKey: '',
      activeRegionId: null,
      modifyRow: null,
      containChild: false,
      regionList: [],
      dialogVisible: false,
      dialogListRegionId: null,
      dialogListVisible: false,
      dialogList: [],
      activeName: 'first',
      isMinimize: false,
      isDrawEnd: false,
      isEditing: false,
      btnLoading: false,
      treeCheckedKeys: [],
      table: {
        pageSize: 8,
        pageIndex: 1
      },
      form: {
        regionId: null,
        IOType: 0,
        needAlarm: true,
        checkSpeed: false,
        CheckRetention: false,
        MaxRetentionMinutes: 60,
        maxSpeed: 100,
        timeRange: [miment('2018/11/11 00:00:00'), miment('2018/11/11 23:59:59')],
        inTips: '',
        outTips: ''
      },
      showObjectCount: false,
      showBindObject: false,
      activePolygon: {
        PolygonID: 0,
        PolygonName: '',
        list: []
      },
      marker:[],
      searchState:'',
      searchCheck:''
    }
  },
  computed: {
    tableList() {
      return this.regionList.slice(this.table.pageSize*this.table.pageIndex - this.table.pageSize, this.table.pageSize*this.table.pageIndex)
    },
    dialogTitle() {
      return this.modifyRow ? (this.$t('regionManage.modify') + `【${this.modifyRow.PolygonName}】`) : this.$t('regionManage.add')
    }
  },
  components: {
    IDropDown,
    IDropDownOption,
    TreeUser,
    MapPlace,
    SelectCar,
    SelectDevice
  },
  watch: {
    containChild() {
      this.fetchRegionList()
    },
    dialogVisible(val) {
      if (val) {
        setTimeout(() => {
          this.initTabMap()
          this.isMinimize && this.startDraw()
          if (this.modifyRow) {
            this.isMinimize = false
            this.isDrawEnd = false
            this.isEditing = false
            this.addRegionToMap(this.modifyRow, this.tabMap)
          }
          if (this.mousetool) {
            this.mousetool.close()
          }
          if (this.msTool) {
            this.msTool.close()
          }
        }, 300)
      } else {
        if (this.mousetool) {
          this.mousetool.close()
        }
        if (this.msTool) {
          this.msTool.close()
        }
      }
    },
    queryKey() {
      this.d_queryHandle()
    }
  },
  created() {
    this.fetchRegionList()
    this.IOTypeList = this.$t('regionManage.ioTypeList')
    this.d_queryHandle = debounce(this.fetchRegionList, 300)
  },
  mounted() {
    this.initMap()
  },
  methods: {
    querySearchDevice(queryString,cb){
      if(queryString!==''){
        SearchVehicleInfo(queryString).then(res=>{
          let result = res.data.list;
          result.map((k)=>{
              k.value = k.VehicleName;
              k.name = k.SIM2;
          })
          cb(result);
        })
      }
    },
    clearSearch(){
      this.searchCheck ='';
      this.searchState = '';
    },
    handleQuery(val){
      this.searchCheck = val;
      this.treeCheckedKeys = [];
    },
    async initMap(center = [116.397428, 39.90923], zoom = 11) {
      const position = this.loadPosition() // mixin
      center = position.center
      zoom = position.zoom

      if (!window.AMap) {
        await remoteLoad(this.$AMAP_URL)
      }

      if (!window.AMapUI) {
        await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
      }

      if (!this.SimpleMaker) {
        await this.initSimpleMarker()
      }

        // 初始化地图
        // console.log('api load success, init map', new Date())
        var layer = new window.AMap.TileLayer({
          getTileUrl:(x, y, z) => {
            let code = new Array(z)
            let nex = Math.pow(2, z-1)
            for (let i = 0; i < code.length; i++) {
              let ox = Math.floor(x / nex) % 2
              let oy = (Math.floor(y / nex) % 2) * 2
              code[i] = ox + oy
              nex = nex / 2
            }
            let imgNumber = code.join('')
            return `https://t1.dynamic.tiles.ditu.live.com/comp/ch/${imgNumber}?mkt=${this.$i18n.locale=="zh" ? "zh-CN": "en-US"}&ur=CN&it=G,TW,L&n=z&og=5&cstl=rd`
          },

          zIndex:2
        });

        this.mapLayer = layer

        // 初始化地图
        // console.log('api load success, init map', new Date())
        let map = new window.AMap.Map(this.$refs.container, {
          resizeEnable: true,
          zoom,
          center,
          isHotspot: false,
          features: [],
          preloadMode: false,
          zooms: [3,18],
          layers: [layer],
          expandZoomRange: false
        })

      this.map = map
      this.AMap = window.AMap
      this.AMapUI = window.AMapUI
      this.ifNotExistsWindow()
      // await this.initLayer()
      // await this.initRealScene()
    },
    initSimpleMarker() {
      const promise = new Promise((resolve) => {
        window.AMapUI.loadUI(['overlay/SimpleMarker'], SimpleMaker => {
          this.SimpleMaker = SimpleMaker
          resolve()
        })
      })
      return promise
    },
    ifNotExistsWindow() {
      if (!this.infoWindow) {
        let infoWindow = new this.AMap.InfoWindow({
          isCustom: true,
          content: '',
          offset: new this.AMap.Pixel(0, -42)
        })
        infoWindow.on('close', () => {
          this.isTrackInfoOpen = false
        })
        this.infoWindow = infoWindow
      }
    },
    initTabMap(center = [116.397428, 39.90923], zoom = 11) {
      const position = this.loadPosition() // mixin
      center = this.marker.length?this.marker:position.center
      zoom = this.marker.length?16:position.zoom
      if (this.tabMap) {
        this.tabMap.setCenter(center)
        this.tabMap.setZoom(zoom)
        return
      }
       // 初始化地图
        // console.log('api load success, init map', new Date())
        var layer = new window.AMap.TileLayer({
          getTileUrl:(x, y, z) => {
            let code = new Array(z)
            let nex = Math.pow(2, z-1)
            for (let i = 0; i < code.length; i++) {
              let ox = Math.floor(x / nex) % 2
              let oy = (Math.floor(y / nex) % 2) * 2
              code[i] = ox + oy
              nex = nex / 2
            }
            let imgNumber = code.join('')
            return `https://t1.dynamic.tiles.ditu.live.com/comp/ch/${imgNumber}?mkt=${this.$i18n.locale=="zh" ? "zh-CN": "en-US"}&ur=CN&it=G,TW,L&n=z&og=5&cstl=rd`
          },

          zIndex:2
        });
      let map = new window.AMap.Map(this.$refs.tabContainer, {
        resizeEnable: true,
        zoom,
        center,
        layers: [layer],
      })
      this.tabMap = map
    },
    fetchRegionList() {
      const promise = new Promise((resolve, reject) => {
        API.GetRegionList(this.containChild, this.queryKey).then(ret => {
          this.table.pageIndex = 1
          this.regionList = ret.data
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
      return promise
    },
    fetchObjectList() {
      const promise = new Promise((resolve) => {
        GetObjectIdByMixID(this.treeCheckedKeys, null).then(({data}) => {
          resolve(data)
        })
      })
      return promise
    },
    fetchRegionObjectList() {
      if (!this.dialogListRegionId) {
        this.dialogList = []
        return
      }

      const promise = new Promise((resolve) => {
        API.GetRegionObject(this.dialogListRegionId).then(({data}) => {
          this.dialogList = data.slice()
          resolve()
        })
      })
      return promise
    },
    rowClick(row) {
      this.activeRegionId = row.PolygonID
      this.addRegionToMap(row)
      this.addBindObject(row)
    },
    addRegionToMap(row, map) {
      map = map || this.map
      this.removeRegionFromMap()
      let polygon = new this.AMap.Polygon({
        map,
        path: row.Points.map(j => [j.Lon, j.Lat]),
        strokeColor: '#1890ff',
        strokeOpacity: '0.9',
        fillColor: '#1890ff',
        fillOpacity: '0.1'
      })
      this.polygon = polygon
      map.setFitView()
    },
    removeRegionFromMap() {
      this.polygon && this.polygon.setMap(null)
    },
    modifyRegion(row) {
      this.modifyRow = row
      this.dialogVisible = true
      this.form.regionId = row.PolygonID
      this.searchCheck ='';
      this.searchState = '';
    },
    addRegion() {
      this.modifyRow = null
      this.dialogVisible = true
      this.isEditing = false
      this.isDrawEnd = false
      this.isMinimize = false
    },
    async deleteRegion(row) {
      try {
        await this.$confirm(this.$t('regionManage.deleteTip', {name: row.PolygonName}), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        })
      } catch (err) {
        return
      }

      API.DeleteRegion(row.PolygonID).then(ret => {
        if (!ret.errCode) {
          this.$message({
            message: this.$t('regionManage.deleteSuccessed'),
            type: 'success'
          })
          deleteFromArray(this.regionList, k => {
            return k.PolygonID === row.PolygonID
          })
        } else {
          this.$message({
            message: ret.errMsg,
            type: 'error'
          })
        }
      })
    },
    openRegionObject(row) {
      this.activeName = 'second'
      this.modifyRow = row
      this.dialogVisible = true
      this.form.regionId = row.PolygonID
      this.searchCheck ='';
      this.searchState = '';
    },
    async addRegionObject() {
      this.btnLoading = true
      let list = [];
      if(this.searchCheck){
        list.push(this.searchCheck.ObjectID)
      }else{
        if (!this.form.regionId) {
          this.$alert(this.$t('regionManage.selectTip'))
          return
        }
        list = await this.fetchObjectList()
      }
      API.ZD_AddRegionObject(this.form.regionId, list, this.form.IOType, this.form.needAlarm ? 1 : 0, this.form.checkSpeed ? 1 : 0, this.form.maxSpeed, this.form.timeRange[0], this.form.timeRange[1], this.form.inTips, this.form.outTips,this.form.CheckRetention ? 1 : 0,this.form.MaxRetentionMinutes).then(({data}) => {
        if (!data.errCode) {
          this.$message({
            message: this.$t('regionManage.saveSuccessed'),
            type: 'success'
          })
          this.dialogVisible = false
          this.fetchRegionList()
        } else {
          console.log(data.errMsg)
        }
      }).catch(err => {
        console.error(err)
        this.btnLoading = false
      })
    },
    async openDialogList(row) {
      this.dialogListRegionId = row.PolygonID
      await this.fetchRegionObjectList()
      this.dialogListVisible = true
    },
    deleteRegionObject(regionId, objectid) {
      const list = [objectid]
      API.DeleteRegionObject(regionId, list).then(ret => {
        if (!ret.data.errCode) {
          const index = this.dialogList.findIndex(k => k.ObjectID === objectid)
          if (index != -1) {
            this.dialogList.splice(index, 1)
          }
        }
      })
    },
    tabClick() {

    },
    modifyDraw() {
      this.isMinimize = true
      this.isDrawEnd = false
      this.editObj(this.polygon, this.tabMap)
    },
    editObj(obj, map) {
      window.AMap.plugin(['AMap.PolyEditor'], () => {
        let msTool = new window.AMap.PolyEditor(map, obj)
        this.isEditing = true
        msTool.open()
        msTool.on('end', ({target}) => {
          this.points = target.getPath()
          this.polygon = target
        })
        this.msTool = msTool
      })
    },
    endEdit() {
      this.isEditing = false
      this.isDrawEnd = true
      this.msTool.close()
      this.msTool = null
    },
    resetDialog() {
      this.activeName = 'first'
      this.modifyRow = null
      this.btnLoading = false
      this.tabMap.clearMap()
    },
    startDraw() {
      this.isMinimize = true
      this.isDrawEnd = false
      this.tabMap.clearMap()

      if (this.mousetool) {
        this.mousetool.polygon({
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1'
        })
        return
      }

      this.tabMap.plugin(['AMap.MouseTool'], () => {
        let mousetool = new this.AMap.MouseTool(this.tabMap)
        mousetool.polygon({
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1'
        })
        mousetool.on('draw', ({obj}) => {
          this.isDrawEnd = true

          const points = obj.getPath()
          this.points = points
          this.polygon = obj
          mousetool.close()
        })
        this.mousetool = mousetool
      })
    },
    async saveAndNext() {
      try {
        const result = await this.$prompt(this.$t('regionManage.enterRegionTip'), '', {
          inputValue: (this.modifyRow && this.modifyRow.PolygonName) || '',
          confirmButtonText: this.$t('common.save'),
          cancelButtonText: this.$t('common.cancel'),
        })

        this.btnLoading = true
        const list = (await ConvertFrom(this.points,'marsr','gps')).data
        const minLon = Math.min(...list.map(k => k.lng))
        const maxLon = Math.max(...list.map(k => k.lng))
        const minLat = Math.min(...list.map(k => k.lat))
        const maxLat = Math.max(...list.map(k => k.lat))
        console.log(minLon, minLat, maxLon, maxLat)
        const regionId = this.modifyRow && this.modifyRow.PolygonID
        API.SaveRegion(regionId, result.value, maxLon, maxLat, minLon, minLat, list.map(k => `${k.lng},${k.lat}`)).then(ret => {
          if (ret.data.RegionID) {
            this.activeName = 'second'

            if (regionId) {
              const index = this.regionList.findIndex(k => k.PolygonID === regionId)
              console.log('索引',index)
              if (index != -1) {
                const row = this.regionList[index]
                row.PolygonName = result.value
                row.Points = this.points.map(k => {
                  return {
                    RegionID: regionId,
                    PointID: -1,
                    Lat: k.lat,
                    Lon: k.lng
                  }
                })
                this.regionList.splice(index, 1, row)
              }
            } else {
              this.fetchRegionList()
            }

            this.btnLoading = false
          }

          this.form.regionId = ret.data.RegionID
        })
      } catch (err) {
        console.error(err)
        // this.$message({
        //   message: '服务器又开小差了',
        //   type: 'error'
        // })
      }
    },
    treeCheck(keys) {
      this.treeCheckedKeys = keys.slice()
    },

    addBindObject(row) {
      this.activePolygon.PolygonName = row.PolygonName
      this.activePolygon.PolygonID = row.PolygonID
      API.GetRegionObject(row.PolygonID).then(({data}) => {
        this.activePolygon.list = data.slice()
        this.showBindObject = true
      })
    },
    refreshBindObject() {
      if (!this.activePolygon.PolygonID) {
        return
      }
      API.GetRegionObject(this.activePolygon.PolygonID).then(({data}) => {
        this.activePolygon.list = data.slice()
        this.showBindObject = true
      })
    },
    carSelect(lon, lat, name) {
      if (!lon) {
        this.$message(this.$t('onlineMonitor.noPositionTip'))
        return
      }
      if (this.carMarker) {
        this.carMarker.setMap(null)
        this.carMarker = null
      }
      this.marker =[]
      this.map.setCenter([lon, lat])
      this.map.setZoom(16)
      this.carMarker = new window.AMap.Marker({
        position: [lon, lat],
        map: this.map,
        title: name
      })
      this.marker =[lon, lat]
    },
    carSelectTab(lon, lat, name) {
      if (!lon) {
        this.$message(this.$t('onlineMonitor.noPositionTip'))
        return
      }
      if (this.carMarker) {
        this.carMarker.setMap(null)
        this.carMarker = null
      }
      this.marker =[]
      this.tabMap.setCenter([lon, lat])
      this.tabMap.setZoom(16)
      this.carMarker = new window.AMap.Marker({
        position: [lon, lat],
        map: this.tabMap,
        title: name
      })
      this.marker =[lon, lat]
    }
  },
  mixins: [
    mixinMapCachePosition
  ]
}
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
@import "../../styles/variables-simple.scss";
@include v-wrapper;
@include v-area;
@include v-item;
.v-nav {
  @include scroll-bar-grey;
}
.v-nav-polygon{
  width: 250px;
}
.region {
  font-size: 12px;
  padding: 8px 12px 8px 20px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  // padding: 0 12px 0 0;
  border: 1px solid #DCDFE6;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: $--color-primary-light-9;
  }
  &.active {
    border: 1px solid $--color-primary;
    background-color: $--color-primary-light-9;
  }

  .content {
    flex-grow: 1;
    .region__name {
      //margin-bottom: 0.5rem;
      font-size: 14px;
      color: #606266;
      line-height: 22px;
    }
    .region__desc {
      color: #909399;
    }
  }
  .option {
    width: 30px;
    .i-dropdown {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.tab-wrapper {
  height: 540px;
  .tab-place-search {
    position: absolute;
    z-index: 111;
    top: 50px;
    left: 20px;
    width: 350px;
    border-radius: 20px;
    box-shadow: 0 2px 6px 0 rgba(181,181,181,.4);
  }
  .v-tool {
    position: absolute;
    z-index: 111;
    top: 25%;
    left: 25%;
    height: 50%;
    width: 50%;
    transition: all 0.3s ease;
    background-color: rgba(64,158,255,.8);
    border-radius: 4px;
    .tip {
      color: #FAFAFA;
      height: 38px;
      line-height: 38px;
      padding-left: 12px;
    }
    .start {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 12px 24px;
      font-size: 18px;
      color: #FFF;
      transition: all 0.3s ease;
      cursor: default;
      & span:hover {
        // color: #ef5350;
        cursor: pointer;
        transition: all 0.3s ease;
        display: inline-block;
        transform: translateY(-5px);
      }
    }

    &.minimize {
      top: 0;
      left: 0;
      height: 38px;
      width: 100%;
      .start {
        display: none;
      }
    }
  }
  &.tab--flex {
    display: flex;

    .tab-nav {
      width: 250px;
      border-left: 1px solid #E4E7ED;
      &.en {
        width: 350px;

        .v-item {
          .v_title {
            width: 11em;
            text-align: right;
          }
          .v_text {
            margin-left: 11em;
          }
        }
      }
    }
    .tab-body {
      flex-grow: 1;
    }
  }
  .v-item {
    .v_title {
      line-height: 32px;
      height: 32px;
    }
  }

  & ::v-deep .amap-logo,& ::v-deep .amap-copyright {
    display: none !important;
    z-index: -1;
  }
}
.flat-btn {
  float:right;
  line-height:38px;
  height:38px;
  padding-top:0;
  padding-bottom:0;
  // background-color: #FF5722;
  // border: 0;
  // color: #FFF;
}
.v-number {
  width: 40px !important;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  & ::v-deep .el-input .el-input__inner {
    padding: 0 6px;
  }
}
.v-range {
  padding: 3px 10px;
  & ::v-deep .el-icon-time {
    display: none;
  }
  & ::v-deep .el-range__close-icon {
    display: none;
  }
  & ::v-deep .el-range-input {
    width: 63%;
  }
}
.v-pager {
  & ::v-deep .btn-next {
    float: right;
  }
}
.fixedTop {
  position: absolute;
  width: 350px;
  border-radius: 4px;
  padding: 12px 24px 24px;
  top: 24px;
  right: 24px;
  background-color: rgba(255, 255, 255, .9);
  border: 1px solid rgba(0, 0, 0, .24);
  .fixedTopHeader {
    height: 32px;
    line-height: 32px;
    color: rgba(0, 0, 0, .86);
    font-size: 16px;
    .fixedTopHeaderClose {
      float: right;
      i {
        font-size: 16px;
        &:hover {
          color: rgba(0, 0, 0, 1);
          cursor: pointer;
        }
      }
      i + i {
        margin-left: 12px;
      }
    }
  }
  .vehicle-split {
    margin: 4px auto 12px;
    height: 1px;
    background-color: rgba(0, 0, 0, .24);
    width: 302px;
  }
  ul {
    list-style-position: inside;
    margin-bottom: -8px;
    li {
      font-size: 14px;
      margin-bottom: 8px;
      .IOType {
        color: $--color-primary;
        float: right;
        font-size: 12px;
      }
      .SIM2 {
        margin: 4px 0 0 20px;
        color: rgba(0, 0, 0, .56);
        .Speed {
          font-size: 12px;
        }
      }
    }
  }
  .nodata {
    height: 40px;
    line-height: 40px;
    color: rgba(0, 0, 0, .54);
    font-size: 14px;
    text-align: center;
    margin-top: 24px;
  }
  .viewAll {
    font-size: 14px;
    margin: 16px 0 0 20px;
    color: $--color-primary;
    cursor: pointer;
  }
}
/*fade*/
.fade-polygon-enter-active,
.fade-polygon-leave-active {
  transition: all 0.28s ease-out;
}

.fade-polygon-enter,
.fade-polygon-leave-active {
  opacity: 0;
  transform: translateY(30px);
}
.risk-polygon-query ::v-deep input{
  border-radius: 20px;
  transition: all .3s ease;
  &:focus {
    background-color: #F4F4F8;
    box-shadow: 0 1px 3px 0 rgba(44, 34, 243, 0.25);
    border-color: transparent;
  }
}
.searchInput{
  text-align: center;
  padding:0 15px;
  .inner{
    width:84%;
    margin-bottom: 5px;
  }
  .group{
    width:96%;
    margin:10px auto;
    text-align: left;
    .tag{

    }
  }
}
.tabPanels{
    width: 100%;
    // height: 560px;
    display: flex;
    .cols{
      flex-grow: 1;
       overflow: hidden;
    }
    .step1{
      width:50%;
      border: 1px solid #e0e1e1;
      border-radius: 10px;
       & ::v-deep .treeBlock{
        border-right:0;
      }
    }
    .step2{
      padding-left:20px;
    }

    .con {
      width: 100%;
      height: 100%;
    }

    .selectUser {
        border: 1px solid #e0e1e1;
        padding: 5px;
        border-radius: 10px;

        .searchBar {
          margin-bottom: 15px;
        }
    }

    .solid {
       padding: 0px 10px 10px 10px;
    }
    .label-form{
      color: #999;
      margin-right: 10px;
    }

    .bot {
        border: 1px dashed #1890ff;
        border-radius: 5px;
        padding: 5px 10px;

        .tips {
            margin-top: 10px;
            height: 20px;
            color:#1890ff;
        }
        .clk{
            cursor: pointer;
            line-height: 28px;
            text-indent: 10px;
            color:#1890ff;
        }
    }
    .elRow{
      display: flex;
      .elCol{
        margin-right: 15px;
      }
    }

    .line {
        display: flex;
        span {
            width: 40px;
            position: relative;
            z-index: 1;

            i {
              position: absolute;
              left: 10px;
              top: 6px;
              z-index: 1;
              display: block;
              width: 18px;
              height: 18px;
              border: 1px solid #ccc;
              border-radius: 50%;
            }

            .icon {
              width: 26px;
              height: 26px;
              color: #1890ff;
              position: absolute;
              left: 6px;
              top: 3px;
              z-index: 1;
            }
        }

        p {
            // width: 100px;
            padding-right: 30px;
        }
        .input{
           & ::v-deep .el-input__inner{
             width: 160px;
           }
           & ::v-deep.el-input-group__append{
             min-width:96px;
           }
        }
    }
    & ::v-deep .el-form-item--small.el-form-item{
      margin-bottom: 0px;
    }
    & ::v-deep .el-form-item__label{
      padding-bottom: 0;
    }
}
</style>


export function GetWrapper(google) {
  function Wrapper (map, latlng, args, id) {
    this.latlng = latlng
    this.setMap(map)
    this.args = Object.assign({
      offsetX: 0,
      offsetY: 0,
      el: ''
    }, args)
    this.id = id
  }

  Wrapper.prototype = new google.maps.OverlayView()

  Wrapper.prototype.setId = function(id) {
    this.id = id
  }

  Wrapper.prototype.getId = function() {
    return this.id
  }

  Wrapper.prototype.onAdd = function () {

    let div = document.createElement('div')
    div.classList.add('i-google-wrapper')
    if (typeof this.args.el == 'object') {
      div.appendChild(this.args.el)
    } else {
      div.innerHTML = this.args.el
    }

    let panes = this.getPanes()
    panes.floatPane.appendChild(div)
    const point = this.getProjection().fromLatLngToDivPixel(this.latlng)
    div.style.left = `${point.x + this.args.offsetX}px` //减掉marker宽度的一半，居中
    div.style.top = `${point.y + this.args.offsetY}px` //减掉marker高度的一半，居中
    div.style.position = 'absolute'
    div.style.transform = 'translateX(-50%) translateY(-100%)'
    div.style.whiteSpace = 'nowrap'
    this._div = div
  }

  Wrapper.prototype.draw = function () {
    const point = this.getProjection().fromLatLngToDivPixel(this.latlng)
    this._div.style.left = `${point.x + this.args.offsetX}px`
    this._div.style.top = `${point.y + this.args.offsetY}px`
  }

  Wrapper.prototype.onRemove = function () {
    this._div.parentNode.removeChild(this._div)
    //delete(this)
  }

  Wrapper.prototype.getPosition = function () {
    return this.latlng
  }

  Wrapper.prototype.setPosition = function (latlng) {
    this.latlng = latlng
    this.draw()
  }

  Wrapper.prototype.show = function () {
    this._div.style.removeProperty('display')
  }

  Wrapper.prototype.hide = function () {
    this._div.style.display = 'none'
  }

  // 模拟高德open方法
  Wrapper.prototype.open = function (map, latlng) {
    if (map) {
      this.setMap(map)
    }
    this.setPosition(latlng)
    this.show()
  }

  Wrapper.prototype.close = Wrapper.prototype.hide

  Wrapper.prototype.setContent = function (el) {
    if (this.args.el === el) {
      return
    }

    this.args.el = el
    if (this._div) {
      if (typeof el === 'object') {
        this._div.innerHTML = ''
        this._div.appendChild(this.args.el)
      } else {
        this._div.innerHTML = el
      }

      this.draw()
    } else {
      this.onAdd()
    }

    //this.show()
  }

  return Wrapper
}

// 创建一个文本标签
export function GetTextView(google) {
  function TextView ({map, latlng, args, id,backgroundStyle}) {

    this.latlng = latlng
    this.setMap(map)
    this.args = Object.assign({
      offsetX: 0,
      offsetY: 0,
      el: ''
    }, args)
    this.id = id
    this.backgroundStyle = backgroundStyle
  }

  TextView.prototype = new google.maps.OverlayView()

  TextView.prototype.setId = function(id) {
    this.id = id
  }

  TextView.prototype.getId = function() {
    return this.id
  }

  TextView.prototype.onAdd = function () {

    let div = document.createElement('span')
    div.classList.add('i-google-textview')
    if (typeof this.args.el == 'object') {
      div.appendChild(this.args.el)
    } else {
      div.innerHTML = this.args.el
    }

    let panes = this.getPanes()
    panes.mapPane.appendChild(div)
    const point = (this.getProjection()).fromLatLngToDivPixel(this.latlng)
    div.style.left = `${point.x + this.args.offsetX}px` //减掉marker宽度的一半，居中
    div.style.top = `${point.y + this.args.offsetY}px` //减掉marker高度的一半，居中
    div.style.position = 'absolute'
    //div.style.transform = 'translateX(-50%) translateY(-100%)'
    div.style.whiteSpace = 'nowrap'
    div.style.backgroundColor = this.backgroundStyle.backgroundColor
    div.style.color = this.backgroundStyle.color
    div.style.borderColor = this.backgroundStyle.borderColor
    this._div = div
  }

  TextView.prototype.draw = function () {
    const point = (this.getProjection()).fromLatLngToDivPixel(this.latlng)
    this._div.style.left = `${point.x + this.args.offsetX}px`
    this._div.style.top = `${point.y + this.args.offsetY}px`
  }

  TextView.prototype.onRemove = function () {
    this._div.parentNode.removeChild(this._div)
    //delete(this)
  }

  TextView.prototype.getPosition = function () {
    return this.latlng
  }

  TextView.prototype.setPosition = function (latlng) {
    this.latlng = latlng
    this.draw()
  }

  TextView.prototype.show = function () {
    this._div.style.removeProperty('display')
  }

  TextView.prototype.hide = function () {
    this._div.style.display = 'none'
  }

  // 模拟高德open方法
  TextView.prototype.open = function (map, latlng) {
    if (map) {
      this.setMap(map)
    }
    this.setPosition(latlng)
    this.show()
  }

  TextView.prototype.close = TextView.prototype.hide

  TextView.prototype.setContent = function (el) {
    if (this.args.el === el) {
      return
    }

    this.args.el = el
    if (this._div) {
      if (typeof el === 'object') {
        this._div.innerHTML = ''
        this._div.appendChild(this.args.el)
      } else {
        this._div.innerHTML = el
      }

      this.draw()
    } else {
      this.onAdd()
    }

    //this.show()
  }

  return TextView
}

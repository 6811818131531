import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function GetRegionList(containChild, queryKey) {
  const loginKey = getToken()
  return request({
    url: './add/GetRoadList',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      containChild,
      queryKey
    })
  })
}

export function SaveRegion(RoadID, data, points) {
  const loginKey = getToken()
  return request({
    url: './add/SaveRoad',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      RoadID,
      data, points
    })
  })
}

export function SaveRoad2(RoadID, data) {
  const loginKey = getToken()
  return request({
    url: './add/SaveRoad2',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      RoadID,
      data
    })
  })
}

export function DeleteRegion(RoadID) {
  const loginKey = getToken()
  return request({
    url: './add/DeleteRoad',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      RoadID
    })
  })
}

export function AddRegionObject(RoadID, ObjectIDs) {
  const loginKey = getToken()
  return request({
    url: './add/AddRoadObject',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      RoadID, ObjectIDs
    })
  })
}

export function DeleteRegionObject(RoadID, ObjectIDs) {
  const loginKey = getToken()
  return request({
    url: './add/DeleteRegionObject',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      RoadID, ObjectIDs
    })
  })
}

export function GetRegionObject(RoadID) {
  const loginKey = getToken()
  return request({
    url: './add/GetRoadObject',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      RoadID
    })
  })
}
<template>
  <div class="i-main-content-wrapper tool--none" :class="{'fullscreen-item': isFullScreen }">
    <div class="tree-nav">
      <tree-nav ref="aside" :show-arrow="true" :upgrade='upgrade' :updateDevice="updateDevice"
        @click-device="clickDevice" @modify="modifyVehicle" @command="commandDevice" @click-batch="clickBatch"
        @enter-alarm="activeAlarmList" @modify-device="modifyDevice" @modify-user="modifyUser" @sendmsg="sendMessage"
        show-count>
      </tree-nav>
    </div>
    <div class="subBlock">
      <div class="map"
        :class="{'is-panoopen': isShowRealScene, 'is-animate': isAnimating, 'is-FixedToolbar': isFixedToolBar}"
        ref="container">
      </div>
      <map-place class="online-map-place" @select="mapPlaceSelect" v-if="$i18n.locale==='zh'" style="top: 52px;">
      </map-place>
      <!-- end -地图 -->
      <div class="v-detail" :class="{'isHide':!isNavShow}" v-if="hasMenu">
        <drag-wrapper v-if="table.show" @end-drag="({y}) => table.height -= y" @start-drag="startDrag"></drag-wrapper>
        <arrow-button @click="table.show = !table.show" class="toggleTable" :position="table.show?'bottom':'top'">
        </arrow-button>
        <div class="tipGroup">
          <mapTips />
          <deviceNum :upgrade='upgrade' @enter-alarm="activeAlarmList" ref="deviceNum" />
        </div>
        <div v-if="table.show">
          <template v-if="table.viewTable===1">
            <el-table border stripe key="table1" size="mini" :data="tableList" :height="table.height"
              :default-sort="{prop:tableSort.name, order: tableSort.orderBy}" @row-dblclick="rowDbClick"
              @sort-change="changeSort" class="tracktable" style="width: 100%">
              <el-table-column prop="PeopleName" sortable :label="$t('vehicleDetail.peopleName')" width="100"
                v-if="viewColumns.includes('PeopleName')">
                <template slot-scope="{row}">{{row.PeopleName||'--'}}</template>
              </el-table-column>
              <el-table-column prop="VehicleName" sortable :label="$t('onlineMonitor.deviceName')" width="120"
                v-if="viewColumns.includes('VehicleName')" :show-overflow-tooltip="!table.isWrap">
                <template slot-scope="{row}">
                  <span v-if="row.VehicleList[0].TypeID===1">{{row.PersonName}}</span>
                  <span v-else-if="row.VehicleList[0].TypeID===2">{{row.ArtName}}</span>
                  <span v-else>{{row.VehicleName}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="VIN" sortable :label="$t('vehicleDetail.vehicleId')" width="140"
                v-if="viewColumns.includes('VIN')">
                <template slot-scope="{row}">{{row.VIN||'--'}}</template>
              </el-table-column>
              <el-table-column prop="SIM2" sortable :label="$t('deviceManage.deviceID')" width="140"
                v-if="viewColumns.includes('SIM2')">
                <template slot-scope="{row}">{{row.SIM2||'--'}}</template>
              </el-table-column>
              <el-table-column prop="TrackerTypeName" sortable :label="$t('deviceManage.TrackerTypeName')" width="70"
                v-if="viewColumns.includes('TrackerTypeName')">
                <template slot-scope="{row}">{{row.TrackerTypeName ? $t('dict')[row.TrackerTypeName] : '--'}}</template>
              </el-table-column>
              <el-table-column prop="Speed" sortable :label="$t('onlineMonitor.speed')+'(km/h)'" width="90"
                v-if="viewColumns.includes('Speed')">
                <template slot-scope="{row}">{{row.Speed}}</template>
              </el-table-column>
              <el-table-column prop="Mileage" sortable :label="$t('onlineMonitor.totalMile')+'(km)'" width="90"
                v-if="viewColumns.includes('Miles')">
                <template slot-scope="{row}">{{row.Mileage}}</template>
              </el-table-column>
              <el-table-column :key="80" prop="isOnline" sortable :label="$t('deviceManage.online')" width="55"
                v-if="viewColumns.includes('isOnline')">
                <template slot-scope="{row}">
                  <p class="square" :class="{isOnline: !!row.isOnline}"></p>
                </template>
              </el-table-column>
              <el-table-column :key="90" prop="Status" sortable :label="$t('deviceManage.acc')" width="55"
                v-if="viewColumns.includes('Acc')">
                <template slot-scope="{row}">
                  <template v-if="row.TypeID===0">
                    <template v-if="row.isOnline">
                      <p v-if="row.Status" class="imgcolumn">
                        <template v-if="!!(row.GPSFlag%2)">
                          <span v-if="!!row.Speed" class="status" alt="启动,行驶" :title="$t('deviceManage.keyOnRoad')">
                            <i class="s1"></i>
                          </span>
                          <span v-else class="status" alt="启动,怠速" :title="$t('deviceManage.keyDaisu')">
                            <i class="s2"></i>
                          </span>
                        </template>
                        <span v-else class="status" alt="启动,参考定位" :title="$t('deviceManage.keyRef')">
                          <i class="s3"></i>
                        </span>
                      </p>
                      <p v-else class="imgcolumn">
                        <template v-if="!!(row.GPSFlag%2)">
                          <span v-if="!!row.Speed" class="status" alt="行驶">
                            <i class="s1"></i>
                          </span>
                          <span class="status" alt="熄火" :title="$t('deviceManage.keyNoSpeed')" v-else>
                            <i class="s4"></i>
                          </span>
                        </template>
                        <span v-else class="status" alt="熄火,参考定位" :title="$t('deviceManage.keyNoSpeedRef')">
                          <i class="s5"></i>
                        </span>
                      </p>
                    </template>
                    <template v-else>
                      <p class="imgcolumn">
                        <span class="status" alt="离线" :title="$t('deviceManage.keyOffRef')">
                          <i class="s6"></i>
                        </span>
                      </p>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="row.isOnline">
                      <template v-if="!!(row.GPSFlag%2)">
                        <p class="imgcolumn">
                          <span v-if="!!row.Speed" class="status" alt="运动" :title="$t('deviceManage.keyMov')">
                            <i class="s1"></i>
                          </span>
                          <span v-else class="status" alt="静止" :title="$t('deviceManage.keyStop')">
                            <i class="s2"></i>
                          </span>
                        </p>
                      </template>
                      <template v-else>
                        <p class="imgcolumn">
                          <span class="status" alt="参考定位" :title="$t('deviceManage.keyNoGps')">
                            <i class="s5"></i>
                          </span>
                        </p>
                      </template>
                    </template>
                    <template v-else>
                      <p class="imgcolumn">
                        <span class="status" alt="离线" :title="$t('deviceManage.keyOffRef')">
                          <i class="s6"></i>
                        </span>
                      </p>
                    </template>
                  </template>
                </template>

              </el-table-column>
              <el-table-column :key="100" prop="isAlarm" sortable :label="$t('deviceManage.alarm')" width="55"
                v-if="viewColumns.includes('isAlarm')">
                <template slot-scope="{row}">
                  <p v-if="!!row.isAlarm" class="imgcolumn"><img src="../../assets/icon/报警.gif" alt="报警"
                      :title="$t('deviceManage.keyAlarm')" /></p>
                  <p v-else class="imgcolumn"><img src="../../assets/icon/正常.gif" alt="正常"
                      :title="$t('deviceManage.keyNormarl')" /></p>
                </template>
              </el-table-column>
              <el-table-column :label="$t('onlineMonitor.statsDes')" prop="StatusDes" sortable
                :show-overflow-tooltip="!table.isWrap" v-if="viewColumns.includes('Status')">
                <template slot-scope="{row}">
                  <!-- color:#FF5722; -->
                  <span style="line-height:16px;" class="status">{{row.StatusDes}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GPSTime" sortable :sort-by="['GPSTime']" :label="$t('onlineMonitor.gpsTime')"
                width="140" v-if="viewColumns.includes('GPSTime')">
                <p slot-scope="{row}">{{miment(row.GPSTime).format()}}</p>
              </el-table-column>
              <el-table-column prop="RcvTime" sortable :label="$t('onlineMonitor.rcvTime')" width="140"
                v-if="viewColumns.includes('RcvTime')">
                <p slot-scope="{row}">{{miment(row.RcvTime).format()}}</p>
              </el-table-column>
              <el-table-column :label="$t('onlineMonitor.place')" sortable prop="Address"
                :show-overflow-tooltip="!table.isWrap" width="380" v-if="viewColumns.includes('Address')">
                <template slot-scope="{row}">
                  <div>
                    <span @click.stop='toggleAddress(row,false)' class="pointer" v-if="row.showAddress">
                      {{row.Address}}</span>
                    <span @click.stop='toggleAddress(row,true)' class="pointer" v-else>{{row.Lon}},{{row.Lat}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="{row}">
                  <span class="i-link" @click.stop="removeOneDevice(row)">{{$t('onlineMonitor.remove')}}</span>
                </template>
              </el-table-column>
            </el-table>

            <el-pagination key="pager1" class="v-pager" small @size-change="handleSizeChange"
              @current-change="handleCurrentChange" :current-page.sync="table.pageIndex" :page-size="table.pageSize"
              :page-sizes="[10, 20, 50, 100,250]" layout="total, jumper, prev, pager,sizes, next" :total="list_length">
            </el-pagination>
          </template>
          <template v-if="table.viewTable===2">
            <el-table border stripe v-loading="!pageAlarmList.length && alarmTableLoading" key="table2" size="mini"
              :data="pageAlarmList" :height="table.height" @row-dblclick="rowDbClickAlarm" class="tracktable"
              style="width: 100%">
              <el-table-column prop="EventTime" :label="$t('onlineMonitor.gpsTime')" width="140">
                <p slot-scope="{row}">{{miment(row.EventTime).format()}}</p>
              </el-table-column>
              <el-table-column prop="VehicleNum" :label="$t('onlineMonitor.deviceName')" width="180"
                :show-overflow-tooltip="!table.isWrap"></el-table-column>

              <el-table-column prop="AlarmType" :label="$t('onlineMonitor.alarmType')" width="200">
                <p slot-scope="{row}" style="color:#FF5722;">{{row.AlarmType}}</p>
              </el-table-column>

              <el-table-column prop="Speed" :label="$t('onlineMonitor.speed')" width="70">
                <template slot-scope="{row}">{{row.Speed+" km/h"}}</template>
              </el-table-column>

              <el-table-column prop="Direct" :label="$t('onlineMonitor.directoin')" width="85">
                <template slot-scope="{row}">{{getDirectionDescription(row.Direct, $i18n.locale)}}</template>
              </el-table-column>

              <el-table-column :label="$t('onlineMonitor.place')" :show-overflow-tooltip="!table.isWrap">
                <template slot-scope="{row}">
                  <span-address :lon="row.Lon" :lat="row.Lat" :show-default="isShowAddressDefault"></span-address>
                </template>
              </el-table-column>

              <el-table-column :label="$t('onlineMonitor.enterprise')" :show-overflow-tooltip="!table.isWrap">
                <template slot-scope="{row}">{{row.HoldName}}</template>
              </el-table-column>

              <el-table-column width="100">
                <template slot-scope="{row}">
                  <span class="i-link" @click.stop="rowCancelAlarm(row)">{{$t('other.cancelAlarm')}}</span>
                </template>
              </el-table-column>

            </el-table>
            <el-pagination key="pager2" class="v-pager" small :current-page.sync="alarmPageIndex"
              :page-size="alarmPageSize" layout="total, jumper, prev, pager, next" :total="alarmList.length">
            </el-pagination>
          </template>

          <div class="table-check">
            <el-button type="text" class="flat tool-btn primary--text imr-4" @click="removeAllData" v-ripple><i
                class="el-icon-delete" /> {{$t('onlineMonitor.emptyList')}}</el-button>
            <el-checkbox v-model="isShowAddressDefault">{{$t('onlineMonitor.showAddress')}}</el-checkbox>
            <el-checkbox v-model="table.isWrap">{{$t('onlineMonitor.lineFeed')}}</el-checkbox>
            <img class="table-exchange" src="../../assets/icon/exchange.gif"
              @click="table.viewTable = 3 - table.viewTable" />
          </div>

        </div>
      </div>
      <!-- end 下拉列表 -->
      <div class="tool-btns" :class="{'isShowRealScene': isShowRealScene, 'is-FixedToolbar': isFixedToolBar}">
        <div class="loading-container">
          <div class="loading-container__inner" v-loading="isLoadingMapData"></div>
        </div>
        <el-button type="primary" class="flat tool-btn primary--text first" v-show="!openedSearchBox">
          <change-map></change-map>
        </el-button>
        <el-button type="primary" class="flat tool-btn primary--text" @click="sendMessage()"
          v-if="$i18n.locale==='zh' && currentHoldId===1" v-ripple><i class="el-icon-message"></i><span
            v-show="!openedSearchBox">{{$t('onlineMonitor.sendSMS')}}</span></el-button>
        <search-xingqudian v-model="openedSearchBox" label="1" key="1" @select="selectPoi"
          v-if="tConfig.poi && loginInfo.isUser && importantInfo" @show="isShowPoi=!isShowPoi" @delete="removePoi"
          @save="savePoi"></search-xingqudian>

        <search-place v-model="openedSearchBox" label="2" key="2" @select="selectRegion"
          v-if="tConfig.region && loginInfo.isUser && importantInfo && loginInfo.allFuns.includes(3035)"
          @show="isShowAlarmRegion=!isShowAlarmRegion" @delete="removeRegion"></search-place>
        <el-button type="primary" class="flat tool-btn primary--text" @click="startFindRect"
          v-if="tConfig.rect && loginInfo.isUser && importantInfo" v-ripple>{{$t('onlineMonitor.regionSearch')}}
        </el-button>
        <el-button type="primary" class="flat tool-btn primary--text" @click="removeAllData" v-ripple>
          {{$t('onlineMonitor.emptyMap')}}</el-button>
        <el-button type="primary" class="flat tool-btn primary--text" @click="openBatchCommand"
          v-if="loginInfo.isUser && importantInfo && loginInfo.allFuns.includes(20121)" v-ripple>
          {{$t('onlineMonitor.batchCommand')}}</el-button>
        <el-button type="primary" class="flat tool-btn" @click="$root.$emit('dialog-setting')"
          v-tooltip="$t('onlineMonitor.setViewItem')" v-ripple>
          <i-icon name="icon-settings" />
        </el-button>
        <el-button type="primary" class="flat tool-btn" @click="saveMapPosition" v-tooltip="$t('onlineMonitor.save')"
          v-ripple>
          <i-icon name="icon-save" />
        </el-button>
        <!-- <el-button type="primary" class="flat tool-btn" @click="toggle_ruler__start" v-tooltip="$t('onlineMonitor.ruler')" v-ripple><i-icon name="icon-juli" /></el-button> -->
        <div type="primary" v-if="hasMenu" class="mapRuler" @click="toggle_ruler__start"
          v-tooltip="$t('onlineMonitor.ruler')" v-ripple>
          <i-icon name="icon-juli" />
        </div>
        <el-button type="primary" class="flat tool-btn" @click="$refs.aside.refresh()" v-if="importantInfo" v-ripple
          v-tooltip="$t('onlineMonitor.refreshUserTree')"><i class="el-icon-refresh"></i></el-button>
        <el-button type="primary" class="flat tool-btn" @click="isFullScreen = !isFullScreen" v-if="importantInfo"
          v-ripple v-tooltip="$t('onlineMonitor.fullscreen')">
          <i-icon :name="isFullScreen?'icon-fullscreen-exit':'icon-fullscreen'" style="font-size:17px;"></i-icon>
        </el-button>
      </div>
      <!-- end 工具栏 -->
      <div class="map--tip" :class="{'isNavShow':isNavShow}">
        <transition-group name="slidegroup">
          <div class="countdown" v-if="list.length > 0" key="1">
            <i class="el-icon-time" style="margin-right:4px;"></i>
            {{$t('onlineMonitor.refreshTip', { count:countdown })}}
            <el-button @click="refreshNow" type="text" :disabled="isLoadingMapData || refreshBtnBusy">
              {{$t('common.refresh')}}</el-button>
            <el-button @click="end_timer" v-if="isShowPause && countdown_timer"
              :disabled="isLoadingMapData || refreshBtnBusy" type="text">{{$t('onlineMonitor.refreshAction')}}
            </el-button>
            <el-button @click="start_timer" v-if="isShowPause && !countdown_timer"
              :disabled="isLoadingMapData || refreshBtnBusy" type="text">{{$t('onlineMonitor.refreshResume')}}
            </el-button>
          </div>
          <p class="tip__item" v-if="mapTool.ruler" key="2">
            <i class="el-icon-warning" style="margin-right:4px;"></i>{{$t('onlineMonitor.distanceTip')}}
          </p>
          <p class="tip__item" v-if="isOnFindByRect" key="5">
            <i class="el-icon-warning" style="margin-right:4px;"></i>{{$t('onlineMonitor.regionSearchTip')}} <span
              style="color:#f44336; cursor:pointer;" @click="endFindRect">{{$t('onlineMonitor.exit')}}</span>
          </p>
          <p class="tip__item" v-if="isOnFindByRectLoading" key="6"><i class="el-icon-loading"
              style="margin-right:4px;"></i>正在查找...</p>
          <p class="tip__item ipt-1" v-if="mapTool.vToolGroup" key="3">
            <el-checkbox v-model="mapTool.bilichi">比例尺</el-checkbox><br />
            <el-checkbox v-model="mapTool.gongjutiao">工具条</el-checkbox><br />
            <el-checkbox v-model="mapTool.yingyan">鹰眼</el-checkbox><br />
            <span class="item__split"></span>
            <span class="item__action" @click="mapTool.vToolGroup=false">关闭</span>
          </p>
        </transition-group>
      </div>
      <!-- 工具辅助栏 -->
      <!-- <drag-dialog :visible.sync="isShowVideo" :videoparams="dvrInfo" v-show="isShowVideo"></drag-dialog> -->
      <div class="v-pano" :class="{'is-panoopen': isShowRealScene}" ref="">
        <div v-show="isShowRealScene" style="z-index: 9" class="v-wrap">
          <iframe id="mainIframe" ref="mainIframe" class="box" src="https://www.365qczx.com/Jiejing/StreetBD2.html"
            frameborder="0"></iframe>
        </div>
        <span class="closeBtn" @click="isShowRealScene=false" v-if="isShowRealScene"><i
            class="el-icon-close"></i></span>
      </div>
      <div class="fixed-tool">
        <div class="layers-ui" v-if="layerSelectVisible">
          <div class="sidebar_heading">
            <i class="el-icon-close" @click="layerSelectVisible=false"></i>
            <h4>{{$t('onlineMonitor.layers')}}</h4>
          </div>
          <div class="base-layers">
            <ul>
              <li v-for="(k, index) in mapLayerOptions" :key="index"
                :class="{'active': currentMapLayer===index, 'hide': !k.type.includes('google')}"
                @click="currentMapLayer=index">
                <img :src="k.img" />
                <label>{{$t(`onlineMonitor.${k.name}`)}}</label>
              </li>
            </ul>
          </div>
        </div>
        <ul>
          <li @click="layerSelectVisible=!layerSelectVisible" :class="{'active':layerSelectVisible}">
            <i-icon name="icon-layers"></i-icon>
          </li>
        </ul>
      </div>
      <!-- end 其他 -->
    </div>
    <!-- 弹窗 -->
    <el-dialog :custom-class="iframe.class" :visible.sync="isShowDialog" :width="iframe.width" :title="iframe.title"
      @close="closedIframe" append-to-body v-if="isShowDialogReal">
      <el-row type="flex" class="targetSIMInput" align="middle" v-if="targetSIMInputVisible">
        <span>{{$t('onlineMonitor.simcard')}}：</span>
        <el-input v-model="targetSIM" @blur="sendMessageBySIM(targetSIM)"
          :placeholder="$t('onlineMonitor.pleaseInputSimcard')"></el-input>
      </el-row>

      <div v-loading="iframeLoading" element-loading-spinner="el-icon-loading" element-loading-background="#FFFFFF"
        style="position:relative;">
        <iframe frameborder="0" class="mine" :height="iframe.height" width="100%" scrolling="yes" :src="iframe.src"
          @load="iframeLoading=false"></iframe>
        <div class="targetSIMInput-empty"
          v-if="targetSIMInputVisible && (iframe.src == '#' || iframe.src == 'about:blank')">
          <i-icon name="icon-message-bulleted"></i-icon>
          <span>{{$t('onlineMonitor.inputSimcardSeeHistory')}}</span>
        </div>
      </div>
    </el-dialog>
    <!-- 指令发送 弹窗 -->
    <el-dialog :visible.sync="isShowDialogBatch" :title="$t('onlineMonitor.batchCommand')"
      custom-class="v-dialog v-dialog--md v-dialog--mdpadding" append-to-body v-if="isShowDialogBatchReal"
      @closed="isShowDialogBatchReal = false">
      <batch-command :current-type="currentCommandTracker" :current-command-default="currentCommandDefault"
        :checked-object-keys-default="checkedObjectKeysDefault" :current-command-single="currentCommandSingle"
        :device-info="deviceInfo"></batch-command>
    </el-dialog>
    <!-- 基站/卫星 弹窗 -->
    <dialog-cellandlbs ref="dialogCellAndLbs"></dialog-cellandlbs>
    <detail-vehicle ref="detailVehicle"></detail-vehicle>
    <detail-device-info ref="detailDeviceInfo"></detail-device-info>
    <detail-hold ref="detailHold"></detail-hold>
    <dialog-message ref="dialogMessage"></dialog-message>
    <detail-device ref="detail" @success="handlerRefresh"></detail-device>
    <detail-device-object ref="detailObject" @success="handlerRefresh"></detail-device-object>
    <detail-device-person ref="detailPerson" @success="handlerRefresh"></detail-device-person>

  </div>
</template>

<script>
  import miment from 'miment'
  import ArrowButton from '@/components/arrow-button'
  import BatchCommand from '@/views/batch/batch-command'
  import ChangeMap from '@/views/common/change-map'
  import DragWrapper from '@/components/drag-wrapper'
  import TreeNav from '@/views/common/tree-nav'
  import SearchPlace from './components/search-place'
  import SearchEryadian from './components/search-eryadian'
  import SearchXingqudian from './components/search-xingqudian'
  import DialogCellandlbs from './components/dialog-cellandlbs'
  import DialogMessage from '@/views/online/components/dialog-message'
  import DetailDeviceInfo from '../manage/detail/detail-device-info'
  import DetailHold from '../manage/detail/detail-hold'
  import DetailDevice from '../manage/detail/detail-device'
  import DetailDevicePerson from '../manage/detail/detail-device.person'
  import DetailDeviceObject from '../manage/detail/detail-device.object'
  import mapTips from './components/mapTips'
  import deviceNum from './components/deviceNum'
  import MapLayers from '@/views/mixins/map-layers'
  import {
    mapGetters,
    mapMutations
  } from 'vuex'

  import * as API from '@/api/common'
  import {
    remoteLoad,
    findFromArray,
    deleteFromArray,
    debounce,
    bd_encrypt
  } from '@/common/utils'
  import {
    CancelAlarm
  } from '@/common/other'
  import {
    getMessagePhoneNumber,
    setMessagePhoneNumber,
    getLanguage
  } from '@/common/cache'
  // import {
  //   getDirectionDescription
  // } from '@/common/dictionary'
  import mixinMapCachePosition from '@/views/mixins/mixin-map-cache-position'
  import mixinOnlineMonitor from '@/views/mixins/mixin-online-monitor'
  const IS_DEBUG = process.env.NODE_ENV === 'development'
  const REFRESH_SPACE_TIME = 15 // 单位：秒

  export default {
    name: 'onlineMonitorGoogle',
    components: {
      ArrowButton,
      BatchCommand,
      ChangeMap,
      DragWrapper,
      TreeNav,
      SearchPlace,
      SearchEryadian,
      SearchXingqudian,
      DialogCellandlbs,
      DialogMessage,
      DetailDeviceInfo,
      DetailHold,
      DetailDevice,
      DetailDevicePerson,
      DetailDeviceObject,
      mapTips,
      deviceNum
    },
    data() {
      return {
        miment,
        REFRESH_SPACE_TIME, //系统定义倒计时
        isShowPause: IS_DEBUG, //开发环境暂停计时器
        mapTool: {
          ruler: null, // 比例尺
          vToolGroup: false, // 是否显示工具栏
          bilichi: false,
          gongjutiao: false,
          yingyan: false
        },
        isFullScreen: false,
        openedSearchBox: null,
        isShowPoi: false,
        isShowAlarmRegion: false,
        isOnFindByRect: false,
        isOnFindByRectLoading: false,
        isShowDialogReal: false,
        isLoadingMapData: false, // 是否在加载地图
        isShowRealScene: false, //显示实景
        isShowVideo: false, // 显示dvr视频
        isAnimating: false,
        isFixedToolBar: true,
        isNavShow: true, // 显示/隐藏详情
        upgrade: 15, //倒计时同步时间
        list: [], // 下拉设备列表1
        listItem: [], //加载列表数据
        table: {
          show: false,
          pageIndex: 1,
          pageSize: 10,
          isWrap: false,
          viewTable: 1,
          height: 224
        },
        tableSort: {
          name: '',
          orderBy: ''
        },
        timer_activeAlarmList: null,
        alarmTableLoading: false,
        alarmList: [], // 报警列表 50s刷新每次
        alarmPageIndex: 1,
        alarmPageSize: 20,
        isShowAddressDefault: true, // 报警列表是否翻译地址
        countdown: 15, // 倒计时
        isShowDialogBatch: false, // 发送指令弹窗
        isShowDialogBatchReal: false,
        currentCommandDefault: 10, // 批量指令默认指令类型
        checkedObjectKeysDefault: [], // 批量指令默认设备
        countdown_timer: null, // 是否开启定时器
        deviceInfo: {},
        dvrInfo: {
          name: '',
          paths: '',
          flag: '',
          deviceId: ''
        },
        parameter: { // 实景地图参数
          DeviceID: "",
          VN: "",
          ModelID: ""
        },
        iframe: {
          title: '',
          class: '',
          width: '920px',
          height: '700px',
          src: '#'
        },
        iframeLoading: false,
        updateDevice: []
      }
    },
    methods: {
      diffCompare(k, device) { //对比设备ObjectID
        return k.ObjectID === device.ObjectID
      },
      openVideo(paths, data) {
        this.isShowVideo = true;
        this.dvrInfo.name = data.ObjectName;
        this.dvrInfo.deviceId = data.SIM2;
        this.dvrInfo.paths = paths;
        this.dvrInfo.flag = 1;
      },
      video() {
        this.videoUrls = `http://119.23.233.52/iGPS/dvr31/index2.html` +
          `?DeviceID=${this.parameter.DeviceID}&VN=${this.parameter.VN}&ModelID=${this.parameter.ModelID}`;
        this.isShowVideo = true;
      },
      // 关闭复位URL
      showVideo() {
        this.isShowVideo = false;
        this.videoUrls = "";
      },
      showRealScene(lng, lat, heading, objectId) {
        if (this.infoWindow.getId() != objectId) {
          return
        }
        let data = {
          Lng: bd_encrypt(lng, lat).bd_lng,
          Lat: bd_encrypt(lng, lat).bd_lat,
          Direct: heading
        }
        this.isShowRealScene = true
        let jiejing = this.$refs['mainIframe']
        jiejing.contentWindow.postMessage(data, 'https://www.365qczx.com/Jiejing/StreetBD2.html');
      },
      toggleAddress(row, bool) {
        let that = this;
        that.list.forEach((k, index) => {
          if (row.ObjectID === k.ObjectID) {
            that.list[index].data.showAddress = bool;
          }
        })
      },
      clickDevice(item, bool) { // 点击设备列表（添加/删除到地图）
        if (bool) {
          this.addOneDevice(item)
        } else {
          this.removeOneDevice(item)
        }
      },
      addOneDevice(item, autoFresh, enforce) {
        // 添加一辆车
        const promise = new Promise(async (resolve) => {
          const device = await this.fetchOneDeviceData2(item.ObjectID, item.VehicleID)
          let arr = [];
          arr.push(device[0]);
          this.fetchAddress(arr, arr).then(list => {
            this.addDataToMap(list[0], autoFresh, enforce)
          })
          resolve()
        })
        return promise
      },
      removeOneDevice(device) {
        const promise = new Promise((resolve) => {
          const index = this.list.findIndex(k => this.diffCompare(k, device))
          this.list[index].marker.setMap(null)
          this.list[index].marker_text.setMap(null)
          this.list[index].overlays.forEach(j => {
            j.setMap(null)
          })
          this.listItem = this.listItem.filter(item => item !== device.ObjectID)
          this.list.splice(index, 1)
          if (this.infoWindow.getId() == device.ObjectID) {
            this.infoWindow.close()
          }
          // this.map.clearInfoWindow()
          if (this.list.length === 0 && this.listItem.length === 0) {
            this.table.show = false
          }
          this.delTaskDevice(device.ObjectID)
          resolve()
        })
        return promise
      },
      addDataToMap(device, isAutoRefresh, enforce) {
        if (!device.RcvTime) {
          this.$message({
            message: this.$t('onlineMonitor.noPositionTip'),
            type: 'info'
          })
          return
        }
        const index = this.list.findIndex(k => this.diffCompare(k, device))
        return ~index ? this.addDataToMap_change(device, isAutoRefresh, enforce) : this.addDataToMap_add(device,
          isAutoRefresh, enforce)
      },
      addDataToMap_add(device, isAutoRefresh) {
        var marker = new this.AMap.Marker({
          icon: new window.AMap.Icon({
            size: new window.AMap.Size(this.iconSize, this.iconSize),
            image: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
            imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
          }),
          position: [device.Lon, device.Lat],
          offset: new this.AMap.Pixel(-this.iconSize / 2, -this.iconSize / 2),
          angle: this.getIconAngle(device.VehicleImageUrl, device.Direct),
          extData: device.ObjectID,
          topWhenClick: true
        })

        var marker_text = new this.AMap.Text({
          text: this.getVehicleName(device),
          position: [device.Lon, device.Lat],
          offset: new this.AMap.Pixel(18, 0),
          extData: device.ObjectID,
          textAlign: 'left',
          topWhenClick: true,
          style: this.getTextStyle(device.VehicleNumBackColor)
        })

        if (!isAutoRefresh) {
          this.infoWindow.setContent(this.addDataToMap_getPanel(device))
          this.infoWindow.open(this.map, marker.getPosition())
          this.infoWindow.setId(device.ObjectID)
        } else if (this.infoWindow.getId() == device.ObjectID) {
          this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        }

        marker.on('click', this.carMarkerClick)
        // marker.on('click', () => {
        //   panel = this.addDataToMap_getPanel(device)
        //   this.infoWindow.setContent(panel)
        //   this.infoWindow.open(this.map, marker.getPosition())
        //   this.infoWindow.setId(marker.getExtData())
        // })
        const overlays = []
        const item = {
          data: device,
          marker,
          marker_text,
          overlays,
          ObjectID: device.ObjectID,
          VehicleID: device.VehicleID
        }

        this.list.push(item)
        this.map.add([marker, marker_text])

        if (!isAutoRefresh) {
          this.map.setCenter([device.Lon, device.Lat])
          // 切换地图zoom
          if (this.map.getZoom() < 14) {
            this.map.setZoom(14)
          }
        }
        // 修改实景位置
        this.isShowRealScene && this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)
      },
      addDataToMap_change(device, isAutoRefresh, enforce) {
        this.list.forEach(k => {
          if (device.ObjectID === k.ObjectID) {
            k.data.Address = device.Address;
          }
        })
        const item = findFromArray(this.list, k => this.diffCompare(k, device))
        if (!enforce) { //
          if (item.ObjectID === device.ObjectID && item.data.RcvTime === device.RcvTime && item.data.isOnline == device
            .isOnline && isAutoRefresh) {
            return
          }
        }

        item.data = device
        item.ObjectID = device.ObjectID
        item.marker.setIcon(new window.AMap.Icon({
          size: new window.AMap.Size(this.iconSize, this.iconSize),
          image: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
          imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
        }))
        item.marker.setAngle(this.getIconAngle(device.VehicleImageUrl, device.Direct))
        item.marker.setPosition([device.Lon, device.Lat])
        item.marker_text.setPosition([device.Lon, device.Lat])
        if (!isAutoRefresh) {
          this.infoWindow.setPosition([device.Lon, device.Lat])
          this.infoWindow.setContent(this.addDataToMap_getPanel(device))
          this.infoWindow.open(this.map, [device.Lon, device.Lat])
          this.infoWindow.setId(device.ObjectID)
          this.map.setCenter([device.Lon, device.Lat])
          // 切换地图zoom
          if (this.map.getZoom() < 14) {
            this.map.setZoom(14)
          }
        } else if (this.infoWindow.getId() == device.ObjectID) {
          this.infoWindow.setContent(this.addDataToMap_getPanel(device))
          this.infoWindow.setPosition([device.Lon, device.Lat])
          this.map.setCenter([device.Lon, device.Lat])
        }

        // 修改实景位置
        this.isShowRealScene && this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)
      },
      fetchAllData(list) {
        list = list || this.list.map(k => k.ObjectID)
        let items = list
        // 加载监控数据并刷新
        const promise = new Promise((resolve, reject) => {
          this.isLoadingMapData = true
          if (items.length) {
            API.QueryDeviceOnlineDataByObjectIDs(items).then(ret => {
              this.parameter.DeviceID = ret.data[0].SIM2;
              this.parameter.VN = ret.data[0].ObjectName;
              this.parameter.ModelID = ret.data[0].MDTModelID;
              if (ret.data.errCode) {
                this.$message({
                  message: ret.data.errMsg,
                  type: 'error'
                })
                return reject(ret.data)
              }
              let result = ret.data
              resolve(result)
            }).catch(err => {
              reject(err)
            }).finally(() => {
              this.isLoadingMapData = false
            })
          } else {
            let arr = []
            resolve(arr)
            this.isLoadingMapData = false
          }
        })
        return promise
      },
      fetchOneDeviceData2(ObjectID) {
        const promise = new Promise((resolve, reject) => {
          this.isLoadingMapData = true
          API.QueryDeviceOnlineDataByObjectIDs([ObjectID]).then(ret => {
            this.isLoadingMapData = false
            if (ret.data.errCode) {
              this.$message({
                message: ret.data.errMsg,
                type: 'error'
              })
              return reject(ret.data)
            }
            resolve(ret.data)
          }).catch(err => {
            reject(err)
          })
        })
        return promise
      },
      fetchAddress(list, pages) {
        let temp = [];
        pages.forEach(k => {
          let item = {
            'Lon': k.sLon || k.data.sLon,
            'Lat': k.sLat || k.data.sLat,
            'ObjectID': k.ObjectID
          }
          temp.push(item)
        })
        const promise = new Promise((resolve) => {
          API.GetAddressList(temp).then((ret) => {
            if (ret.data.errCode) {
              return reject(ret.data)
            }
            list.forEach(k => {
              ret.data.forEach(j => {
                if (j.ObjectId === k.ObjectID) {
                  k.Address = j.Address
                  if (j.showAddress === undefined) {
                    k.showAddress = this.isShowAddressDefault
                  } else {
                    k.showAddress = j.showAddress
                  }
                }
              })
            })
            resolve(list)
          })

        })
        return promise
      },
      fetchAlarmList() {
        this.alarmTableLoading = true
        API.QueryAlarms().then(ret => {
          this.alarmTableLoading = false
          if (!ret.data.errCode) {
            this.alarmList = ret.data.list
          }
        }).catch(() => {
          this.alarmTableLoading = false
          console.error('fetchAlarmList error')
        })
      },
      fetchByRect(minLng, minLat, maxLng, maxLat) {
        const promise = new Promise((resolve) => {
          API.FindCarByRect(minLng, minLat, maxLng, maxLat).then(ret => {
            resolve(ret.data)
          })
        })
        return promise
      },
      addTaskList() {
        this.fetchAllData(this.taskList).then(list => {
          list.forEach(data => {
            this.addDataToMap(data, true)
          })
        })
      },
      addTaskListOne(objectid) {
        console.log('addTaskListOne', objectid)
        let objectIdList = objectid.indexOf(',') == -1 ? [parseInt(objectid)] : objectid.split(',').map(k => parseInt(
          k))
        this.clickBatch(objectIdList)
      },
      start_timer() {
        this.countdown = REFRESH_SPACE_TIME
        const handler = async () => {
          this.upgrade = this.countdown //同步传值倒计时
          if (this.countdown === 1) {
            clearInterval(window.countdown_timer)
            window.countdown_timer = null
            try {
              let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
              let endRow = this.table.pageIndex * this.table.pageSize;
              this.fetchAllData().then(deviceInfos => {
                let pageblock = deviceInfos.slice(startRow, endRow)
                this.fetchAddress(deviceInfos, pageblock).then(list => {
                  list.forEach(data => {
                    this.addDataToMap(data, true)
                  })
                })
              })
              if (this.table.show && this.table.viewTable === 2) {
                this.fetchAlarmList()
                this.$refs.deviceNum.fetchSummar()
              }
            } catch (err) {
              console.error(err)
            }
            clearInterval(window.countdown_timer)
            window.countdown_timer = setInterval(handler, 1000)
          }
          this.countdown = this.countdown > 1 ? this.countdown - 1 : REFRESH_SPACE_TIME
        }
        clearInterval(window.countdown_timer)
        window.countdown_timer = setInterval(handler, 1000)
        this.countdown_timer = true
      },
      end_timer() {
        if (!window.countdown_timer) return
        clearInterval(window.countdown_timer)
        window.countdown_timer = null
        this.countdown_timer = null
      },
      async refreshNow() {
        this.busyRefreshBtn()
        this.end_timer()
        try {
          let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
          let endRow = this.table.pageIndex * this.table.pageSize;
          this.fetchAllData().then(deviceInfos => {
            let pageblock = deviceInfos.slice(startRow, endRow)
            this.fetchAddress(deviceInfos, pageblock).then(list => {
              list.forEach(data => {
                this.addDataToMap(data, true)
              })
            })
          })
          if (this.table.show && this.table.viewTable === 2) {
            this.fetchAlarmList()
            this.$refs.deviceNum.fetchSummar()
          }

        } catch (err) {
          console.error(err)
        }
        this.start_timer()
      },
      rowDbClick(row) {
        this.addDataToMap(row, false)
      },
      rowDbClickAlarm(row) {
        this.clickBatch([row.ObjectID])
      },
      rowCancelAlarm(row) {
        CancelAlarm(this, row.ObjectID).then(() => {
          this.updataInfowindowByData(row)
        }).catch(() => {})
      },
      changeSort(e) {
        this.tableSort.name = e.prop
        this.tableSort.orderBy = e.order
      },
      removeAllData() {
        this.map.clearMap()
        this.list.forEach(k => {
          k.marker = null
          k.marker_text = null
          k.overlays.forEach(j => {
            j.setMap && j.setMap(null)
          })
        })
        this.list.splice(0)
        this.listItem = []
        this.alarmList.splice(0)
        this.delTaskAllDevice()
      },
      handleSizeChange(val) {
        this.table.pageSize = val;
        let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
        let endRow = this.table.pageIndex * this.table.pageSize;
        let pages = this.list.slice(startRow, endRow).map(k => k.ObjectID);
        this.fetchAllData(pages).then(deviceInfos => {
          // let pageblock = deviceInfos.slice(startRow,endRow)
          this.fetchAddress(deviceInfos, deviceInfos).then(list => {
            list.forEach(data => {
              this.addDataToMap(data, false)
            })

            // 切换地图中心
            const markers = this.list.filter(k => deviceInfos.includes(k.ObjectID)).map(k => k.marker)
            this.setFitView(this.map, markers)

            // 点击单个设备ID时打开信息框
            if (list.length === 1) {
              // this.openInfowindowByData(list[0])
            } else {
              this.infoWindow.close()
              markers.forEach(k => {
                k.setAnimation('AMAP_ANIMATION_DROP')
              })
            }
          })
        })
      },
      handleCurrentChange(val) {
        this.table.pageIndex = val;
        let startRow = (val - 1) * this.table.pageSize;
        let endRow = val * this.table.pageSize;
        let pages = this.list.slice(startRow, endRow).map(k => k.ObjectID);
        this.fetchAllData(pages).then(deviceInfos => {
          // let pageblock = deviceInfos.slice(startRow,endRow)
          this.fetchAddress(deviceInfos, deviceInfos).then(list => {
            list.forEach(data => {
              this.addDataToMap(data, false)
            })
            // 切换地图中心
            const markers = this.list.filter(k => deviceInfos.includes(k.ObjectID)).map(k => k.marker)
            this.setFitView(this.map, markers)

            // 点击单个设备ID时打开信息框
            if (list.length === 1) {
              // this.openInfowindowByData(list[0])
            } else {
              this.infoWindow.close()
              markers.forEach(k => {
                k.setAnimation('AMAP_ANIMATION_DROP')
              })
            }

          })

        })
      },
      handlerRefresh(e) {
        this.addOneDevice(e)
      },
      modifyVehicle(VehicleID) {
        this.$refs.detailVehicle.open(VehicleID, {
          tabName: 'vehicle'
        })
      },
      modifyDevice(ObjectID) {
        this.$refs.detailDevice.open(ObjectID)
      },
      modifyUser(HoldID) {
        this.$refs.detailHold.open(HoldID)
      },
      commandDevice(device) {
        this.checkedObjectKeysDefault = [device.ObjectID]
        this.currentCommandDefault = device.MDTTypeID || 10
        this.isShowDialogBatch = true
        this.isShowDialogBatchReal = true
      },
      clickBatch(ObjectIDList, bool, enforce) {
        if (bool) {
          this.removeAllData()
        }
        this.isShowVideo = false;
        this.listItem = this.listItem.concat(ObjectIDList)
        let arrNew = new Set(this.listItem); //通过set集合去重
        this.listItem = Array.from(arrNew);
        let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
        let endRow = this.table.pageIndex * this.table.pageSize;
        this.fetchAllData(ObjectIDList).then(deviceInfos => {
          let pageblock = deviceInfos.slice(startRow, endRow)
          this.fetchAddress(deviceInfos, pageblock).then(list => {
            list.forEach(data => {
              this.addDataToMap(data, list.length !== 1, enforce)
            })
            // 切换地图中心
            const markers = this.list.filter(k => ObjectIDList.includes(k.ObjectID)).map(k => k.marker)
            this.setFitView(this.map, markers)

            // 点击单个设备ID时打开信息框
            if (list.length === 1) {
              // this.openInfowindowByData(list[0])
            } else {
              this.infoWindow.close()
              markers.forEach(k => {
                k.setAnimation('AMAP_ANIMATION_DROP')
              })
            }
          })
        })
      },
      routerEnterHandler() {
        if (this.$route.query.action === 'addTaskList') {
          if (this.$route.query.objectid) {
            this.addTaskListOne(this.$route.query.objectid)
          } else {
            this.addTaskList()
          }
          this.$router.replace('/online/monitor')
        }
      },
      refreshAlarmList() {
        if (this.timer_activeAlarmList) {
          return
        }
        this.timer_activeAlarmList = setInterval(() => {
          this.fetchAlarmList()
          this.$refs.deviceNum.fetchSummar()
        }, 50000)

        this.$once('hook:beforeDestroy', () => {
          console.log('clear timer_activeAlarmList')
          clearInterval(this.timer_activeAlarmList)
        })
      },
      // 打开并加载报警列表、每50s刷新一次
      activeAlarmList() {
        if (this.table.show && this.table.viewTable === 2) {
          this.$refs.deviceNum.fetchSummar()
          this.fetchAlarmList()
        } else {
          // 打开报警列表
          if (!this.timer_activeAlarmList) {
            this.refreshAlarmList()
          }
          this.table.show = true
          this.table.viewTable = 2
        }
      },
      sendMessage(sim) {
        this.targetSIMInputVisible = true
        this.openIframe({
          width: '800px',
          height: '595px',
          class: 'el-dialog--p0',
          src: 'about:blank',
          title: `${this.$t('onlineMonitor.sendSMS')}`
        })
        if (sim) {
          this.targetSIM = sim
          this.sendMessageBySIM(sim)
        } else {
          sim = getMessagePhoneNumber()
          this.targetSIM = sim
          this.sendMessageBySIM(sim)
        }
      },
      sendMessageBySIM(sim) {
        setMessagePhoneNumber(sim || '')
        if (!sim) {
          this.iframe.src = "about:blank"
          return
        }
        API.GetSimUrl(sim).then(ret => {
          if (!ret.data.errCode) {
            console.log(ret.data)
            this.targetSIMInputVisible = true
            this.openIframe({
              width: '800px',
              height: '595px',
              class: 'el-dialog--p0',
              src: ret.data.url,
              title: `${this.$t('onlineMonitor.sendSMS')}`
            })
          } else {
            this.$message({
              message: ret.data.errMsg,
              type: 'error'
            })
          }
        })
      },
      openIframe(options) {
        this.iframe = Object.assign(this.iframe, options)
        this.isShowDialog = true
        this.isShowDialogReal = true
      },
      closedIframe() {
        this.isShowDialogReal = false
        this.iframe.src = "about:blank";
      },
      openBatchCommand() {
        this.isShowDialogBatch = true
        this.isShowDialogBatchReal = true
        this.currentCommandSingle = true
      },
      initMap(center = [116.397428, 39.90923], zoom = 11) {
        const promise = new Promise(async resolve => {

          // 加载位置
          const position = this.loadPosition('google')
          center = position.center
          zoom = position.zoom

          // 加载高德js
          if (!window.AMap) {
            // console.log('AMap api load ', new Date())
            await remoteLoad(this.$AMAP_URL)
          }

          if (!window.AMapUI) {
            await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
          }

          // 初始化地图
          // console.log('api load success, init map', new Date())
          var layer = new window.AMap.TileLayer({
            getTileUrl: this.getLayerTile,
            zIndex: 2
          });
          // var layer = new window.AMap.TileLayer({
          //   getTileUrl: '//mt2.google.cn/vt/lyrs=m@258000000&hl=en-US&gl=US&src=app&x=[x]&y=[y]&z=[z]&s=Ga',
          //   zIndex:2
          // });

          this.mapLayer = layer

          // 初始化地图
          // console.log('api load success, init map', new Date())
          let map = new window.AMap.Map(this.$refs.container, {
            resizeEnable: true,
            zoom,
            center,
            isHotspot: false,
            features: [],
            preloadMode: false,
            zooms: [3, 20],
            lang: "en",
            layers: [layer],
            expandZoomRange: true
          })

          this.map = map
          this.AMap = window.AMap

          // 高德地图标记、信息窗体
          this.initSimpleMarker()
          this.ifNotExistsWindow()

          // 地图移动事件、加载二押点
          map.on('zoomend', this.roomEnd)
          map.on('mapmove', this.mapMove)

          // 加载插件
          // map.addControl(new window.AMap.Scale({
          //   offset: new window.AMap.Pixel(10, 10)
          // }))
          // map.addControl(new window.AMap.ToolBar({
          //   direction: false,
          //   offset: new window.AMap.Pixel(0, 60)
          // }))
          window.AMapUI.define(
            "polyfill/require/require-text/text!ui/control/BasicControl/tpl/zoom/container.html", [],
            function () {
              if (getLanguage() === 'fr') {
                return '<div class="amap-ui-control-zoom">\n    <a class="amap-ui-control-zoom-in zoom-btn disabled" title="Agrandir" role="button">+</a>\n    <div class="amap-ui-control-zoom-num hidden"></div>\n    <a class="amap-ui-control-zoom-out zoom-btn" title="Dézoomer" role="button">−</a>\n</div>';
              } else {
                return '<div class="amap-ui-control-zoom">\n    <a class="amap-ui-control-zoom-in zoom-btn disabled" title="Zoom in" role="button">+</a>\n    <div class="amap-ui-control-zoom-num hidden"></div>\n    <a class="amap-ui-control-zoom-out zoom-btn" title="Zoom out" role="button">−</a>\n</div>';
              }
            });
          window.AMapUI.loadUI(['control/BasicControl'], function (BasicControl) {
            map.addControl(new BasicControl.Zoom({
              position: {
                top: '60px',
                left: '20px'
              } //left top，左上角
            }));
          })

          // 加载实景
          // await this.initRealScene()
          resolve()
        })
        return promise
      },
      initSimpleMarker() {
        const promise = new Promise((resolve) => {
          window.AMapUI.loadUI(['overlay/SimpleMarker'], SimpleMaker => {
            this.SimpleMaker = SimpleMaker
            resolve()
          })
        })
        return promise
      },
      ifNotExistsWindow() {
        if (!this.infoWindow) {
          let infoWindow = new this.AMap.InfoWindow({
            isCustom: true,
            content: '',
            offset: new this.AMap.Pixel(0, -30)
          })
          infoWindow.on('close', () => {})
          // 根据getId获取当前监控车辆
          infoWindow.setId = id => infoWindow._id = id
          infoWindow.getId = () => infoWindow._id
          this.infoWindow = infoWindow
        }
      },
      getSimplePanel(title, datas, padding, others = {}) {
        let panel = this.$dataPanel.create(Object.assign({
          bgColor: true,
          title: title,
          datas,
          paddingLeft: padding,
          showMore: false,
          showHeader: false,
          close: true,
          lon: others.lon,
          lat: others.lat,
          onClose: () => {
            this.map.clearInfoWindow()
          },
          onChange: () => {}
        }, others))
        return panel
      },
      updataInfowindowByData(row) {
        const promise = new Promise(async (resolve) => {
          const reuslt = await this.fetchOneDeviceData2(row.ObjectID, row.VehicleID)
          const device = reuslt[0]
          // console.log(device)
          this.updateDevice = reuslt
          const index = this.list.findIndex(k => this.diffCompare(k, device)) // 图标是否存在于地图
          if (~index) { // 更新地图图标和信息窗状态// 列表信息
            const item = findFromArray(this.list, k => this.diffCompare(k, device))
            item.data = device
            item.ObjectID = device.ObjectID
            item.marker.setIcon(new window.AMap.Icon({
              size: new window.AMap.Size(this.iconSize, this.iconSize),
              image: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
              imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
            }))
            item.marker.setAngle(this.getIconAngle(device.VehicleImageUrl, device.Direct))
            item.marker.setPosition([device.Lon, device.Lat])
            item.marker_text.setPosition([device.Lon, device.Lat])
            if (this.infoWindow.getId() == device.ObjectID) {
              this.infoWindow.setContent(this.addDataToMap_getPanel(device))
              this.infoWindow.setPosition([device.Lon, device.Lat])
              this.map.setCenter([device.Lon, device.Lat])
            }
            this.list = Object.assign(this.list, [item])
          }
          // this.alarmList.forEach((k, eq) => { //更新报警列表1
          //   if (k.ObjectID === row.ObjectID) {
          //     // console.log(eq).filter
          //     this.alarmList.splice(eq, 1)
          //   }
          // })
          // this.alarmList = this.alarmList.filter(k => k.ObjectID!==row.ObjectID) //更新报警列表2
          if (this.table.show && this.table.viewTable === 2) {
            this.fetchAlarmList() //更新报警列
          }
          this.$refs.deviceNum.fetchSummar() // 更新统计数据
          // this.fetchAlarmList()
          // this.fetchAddress([device[0]],[device[0]]).then(list=>{
          //   this.addDataToMap(list[0],autoFresh,enforce)
          // })
          resolve()
        })
        return promise
      },
      openInfowindowByData(device) { //暂时不需要
        const panel = this.addDataToMap_getPanel(device)
        this.infoWindow.setContent(panel)
        this.infoWindow.open(this.map, [device.Lon, device.Lat])
        this.infoWindow.setId(device.ObjectID)
      },
      selectRegion(item) {
        const target = findFromArray(this.regionOverlays, k => k.getExtData() === item.RegionID)
        if (target) {
          this.map.setFitView([target])
        } else {
          let k = item
          let polygon = new this.AMap.Polygon({
            map: this.map,
            path: k.points.map(j => [j.Lon, j.Lat]),
            strokeColor: '#1890ff',
            strokeOpacity: '0.9',
            fillColor: '#1890ff',
            fillOpacity: '0.1',
            extData: k.RegionID
          })
          polygon.getDraggable = () => false
          polygon.getPosition = () => new this.AMap.LngLat(k.points[0].Lon, k.points[0].Lat)
          polygon.on('click', () => {
            this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
                title: `${this.$t('onlineMonitor.regionName')}`,
                text: k.RegionName
              },
              {
                title: `${this.$t('onlineMonitor.createTime')}`,
                text: miment(k.CreateTime).format()
              }
            ], '5em', {
              lon: k.points[0].lon,
              lat: k.points[0].lat
            }))
            this.infoWindow.open(this.map, [k.points[0].Lon, k.points[0].Lat])
            this.infoWindow.setId(null)
          })

          this.regionOverlays.push(polygon)
          this.map.setFitView([polygon])
        }
      },
      removeRegion() {
        this.regionOverlays.forEach(k => {
          k.setMap(null)
          k = null
        })
        this.regionOverlays.splice(0)
        this.regionOverlaysJuhe.forEach(k => {
          k.setMap(null)
          k = null
        })
        this.regionOverlaysJuhe.splice(0)
      },
      selectPoi(item) {
        let map = this.map
        const target = findFromArray(this.poiOverlays, k => k.getExtData() === item.PointID)
        if (target) {
          this.map.setFitView([target])
          return
        }
        let k = item
        let marker = new this.AMap.Marker({
          map: map,
          position: [k.Lon_F, k.Lat_F],
          extData: k.PointID,
          icon: require('../../assets/icon/shadow_marker.png'),
          offset: new this.AMap.Pixel(-16, -32),
          label: {
            content: k.PointName,
            offset: new this.AMap.Pixel(32, 8)
          }
        })
        marker.on('click', () => {
          const d = k
          this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
            title: `${this.$t('onlineMonitor.lngLat')}`,
            text: d.Lon + ',' + d.Lat
          }], '5em', {
            links: [this.$t('onlineMonitor.removeFavirite')],
            onLinkClick: () => {
              this.deletePoi(d)
            },
            lon: d.Lon,
            lat: d.Lat
          }))
          this.infoWindow.open(this.map, marker.getPosition())
          this.infoWindow.setId(null)
        })
        this.poiOverlays.push(marker)
        this.map.setFitView([marker])
      },
      addPoi(map) {
        map = map || this.map
        console.log('addPoi')
        API.GetPOI('', true).then(ret => {
          const list = ret.data.splice(0, 500)
          this.removePoi()
          list.forEach((k, index) => {
            let marker = new this.AMap.Marker({
              map: map,
              position: [k.Lon_F, k.Lat_F],
              extData: k.PointID,
              icon: require('../../assets/icon/shadow_marker.png'),
              // content: `<i class="el-icon-star-on" style="color:red;font-size:24px;"></i>`,
              offset: new this.AMap.Pixel(-16, -32),
              label: {
                content: k.PointName,
                offset: new this.AMap.Pixel(32, 8)
              }
            })
            marker.on('click', () => {
              const d = list[index]
              this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
                title: `${this.$t('onlineMonitor.lngLat')}`,
                text: d.Lon_F + ',' + d.Lat_F
              }], '5em', {
                links: [this.$t('onlineMonitor.removeFavirite')],
                onLinkClick: () => {
                  this.deletePoi(d)
                },
                lon: d.Lon_F,
                lat: d.Lat_F
              }))
              this.infoWindow.open(this.map, marker.getPosition())
              this.infoWindow.setId(null)
            })
            this.poiOverlays.push(marker)
          })
        })
      },
      removePoi() {
        this.poiOverlays.forEach(k => {
          k.setMap(null)
          k = null
        })
        this.poiOverlays.splice(0)
      },
      deletePoi(item) {
        API.DeletePoi([item.PointID]).then(() => {
          this.$message({
            message: this.$t('onlineMonitor.deletePoiTip', {
              name: item.PointName
            }),
            type: 'success'
          })
          this.map.clearInfoWindow()
          let target = findFromArray(this.poiOverlays, k => k.getExtData() === item.PointID)
          if (target) {
            target.setMap(null)
            target = null
          }
        })
      },
      savePoi() {
        const handler = async e => {
          this.map.off('click', handler)
          let err = this.$t('onlineMonitor.savePoiTitle')
          let err2 = this.$t('onlineMonitor.savePoiTip2')
          const {
            value
          } = await this.$prompt(this.$t('onlineMonitor.savePoiTitle'), this.$t('onlineMonitor.savePoiTip'), {
            confirmButtonText: this.$t('common.save'),
            cancelButtonText: this.$t('common.cancel'),
            inputValidator(val) {
              if (!val) {
                return err
              } else if (val.length > 50) {
                return err2
              } else {
                return true
              }
            }
          })
          const poi = {
            HoldID: -1,
            Lon_F: e.lnglat.getLng(),
            Lat_F: e.lnglat.getLat(),
            PointName: value,
            PointType: 5,
            Remark: ''
          }
          API.AddPoi(poi).then(ret => {
            this.selectPoi(ret.data)
            this.$message({
              message: this.$t('onlineMonitor.saveSuccessed'),
              type: 'success'
            })
          })
        }
        this.map.on('click', handler, this)
        this.$message(this.$t('onlineMonitor.savePoiTip3'))
      },
      startFindRect() {
        this.removeAllData()
        this.isOnFindByRect = true

        if (this.mousetool) {
          this.mousetool.rectangle()
          return
        }
        this.map.plugin(["AMap.MouseTool"], () => {
          let mousetool = new this.AMap.MouseTool(this.map)
          mousetool.rectangle()
          mousetool.on('draw', async ({
            obj
          }) => {
            // this.isOnFindByRect = false
            const points = obj.getPath()
            const minLon = Math.min(...points.map(k => k.lng))
            const maxLon = Math.max(...points.map(k => k.lng))
            const minLat = Math.min(...points.map(k => k.lat))
            const maxLat = Math.max(...points.map(k => k.lat))
            // 查找
            this.isOnFindByRectLoading = true
            const list = await this.fetchByRect(minLon, minLat, maxLon, maxLat)
            this.isOnFindByRectLoading = false
            obj.setMap(null)
            list.forEach(k => this.addDataToMap(k))
          })
          this.mousetool = mousetool
        })
      },
      endFindRect() {
        this.isOnFindByRect = false
        this.mousetool.close(true)
      },
      toggle_ruler() {
        if (this.mapTool.ruler) {
          this.toggle_ruler__end()
        } else {
          this.toggle_ruler__start()
        }
      },
      toggle_ruler__start() {
        this.map.plugin(['AMap.RangingTool'], () => {
          this.mapTool.ruler = new this.AMap.RangingTool(this.map, {
            startLabelText: 'Start',
            endLabelText: 'end'
          })
          var str = this.mapTool.ruler.midLabelText
          this.mapTool.ruler.midLabelText = str.replace(/公里/g, 'km')
          this.mapTool.ruler.turnOn()
          this.AMap.event.addListener(this.mapTool.ruler, "end", () => {
            this.mapTool.ruler.turnOff()
            this.mapTool.ruler = null
          })
        })
      },
      toggle_ruler__end() {
        this.mapTool.ruler.turnOff()
        this.mapTool.ruler = null
      },
      setFitView(map, markers) {
        map.setFitView(markers)
      },
      ...mapMutations({
        'delTaskCar': 'DEL_TASK_CAR',
        'addTaskCar': 'ADD_TASK_CAR',
        'addTaskDevice': 'ADD_TASK_DEVICES',
        'delTaskDevice': 'DEL_TASK_DEVICES',
        'delTaskAllDevice': 'DEL_ALL_TASK_DEVICES',
        'setAmapGl': 'SET_AMAP_GL'
      }),
      getDirectionDescription(direction, locale) {
        const desc = ['正北', '东北', '正东', '东南', '正南', '西南', '正西', '西北']
        const desc_en = ['North', 'North East', 'East', 'South East', 'South', 'South West', 'West', 'North West']
        direction = parseInt(direction)
        //console.log('getDirectionDescription', direction)
        if (direction != direction) {
          return ''
        }
        const index = parseInt((direction + 22) % 360 / 45)
        return locale === 'zh' ? desc[index] : desc_en[index]
      }
    },
    watch: {
      $route(val, oldVal) {
        if (val.path === '/online/monitor') {
          this.refreshNow()
          if (oldVal && oldVal.path !== '/online/monitor') {
            this.$nextTick(function () {
              this.map.setCenter([this.map.getCenter().getLng() + 0.000001, this.map.getCenter().getLat() +
                0.000001
              ])
            })
          }
          if (val.query.action === 'addTaskList') {
            if (val.query.objectid) {
              if (val.query.hasOwnProperty('clear')) {
                this.removeAllData()
              }
              this.addTaskListOne(val.query.objectid)
            } else {
              this.addTaskList()
            }
            this.$router.replace('/online/monitor')
          }
        } else {
          this.isShowVideo = false;
          this.end_timer()
          this.timer_activeAlarmList && clearInterval(this.timer_activeAlarmList)
        }
      },
      'table.viewTable': {
        deep: true,
        handler(val) {
          if (val === 2 && this.alarmList.length === 0) {
            this.fetchAlarmList()
          } else {
            this.timer_activeAlarmList && clearInterval(this.timer_activeAlarmList)
          }
        }
      },
      iframeLoading(val) {
        if (val) {
          this.iframeLoading_timer = setTimeout(() => {
            this.iframeLoading = false
            this.iframeLoading_timer = null
          }, 15000)
        } else {
          if (this.iframeLoading_timer) {
            clearTimeout(this.iframeLoading_timer)
            this.iframeLoading_timer = null
          }
        }
      },
      'iframe.src': {
        deep: true,
        handler(val) {
          if (val != '#' && val != 'about:blank') {
            this.iframeLoading = true
          }
        }
      },
      isShowAlarmRegion(val) {
        if (!val) {
          this.map.clearInfoWindow()
        }
        return val ? this.addRegion(this.map) : this.removeRegion()
      },
      isShowPoi(val) {
        if (!val) {
          this.map.clearInfoWindow()
        }
        return val ? this.addPoi(this.map) : this.removePoi()
      },
      list_length(val, oldVal) {
        if (val === 0 && oldVal > 0) {
          // 关闭计时器
          this.end_timer()
        } else if (val > 0 && oldVal === 0) {
          // 打开计时器
          this.start_timer()
        }
      },
    },
    computed: {
      list_length() {
        return this.list.length || 0
      },
      tableList() {
        let sheetList = this.list.map(k => k.data).slice((this.table.pageIndex - 1) * this.table.pageSize, this.table
          .pageIndex * this.table.pageSize)
        return sheetList
      },
      pageAlarmList() {
        return this.alarmList.map(k => k).splice((this.alarmPageIndex - 1) * this.alarmPageSize, this.alarmPageSize)
      },
      ...mapGetters([
        'loginInfo',
        'taskList',
        'currentHoldId'
      ])
    },
    created() {
      this.delTaskAllDevice()
      this.regionOverlays = []
      this.poiOverlays = []
      this.regionOverlaysJuhe = []
      this.currentMapLayer = 4
    },
    mounted() {
      this.initMap().then(this.routerEnterHandler)
    },
    mixins: [
      mixinMapCachePosition,
      mixinOnlineMonitor,
      MapLayers
    ]
  }

</script>
<style lang="scss" scoped>
  @import "../../styles/variables-simple.scss";

  // // 左导航栏z-index
  // $page-nav-zindex: 112;
  // // 左导航栏z-index
  // $page-nav-tool-zindex: 112;
  .tool-btns {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // z-index: 200;
    z-index: $page-nav-tool-zindex;
    height: 40px;
    background-color: rgba(255, 255, 255, .7);
    float: right;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $page-main-tool-height;
    border-bottom: 1px solid rgba($--color-primary, .12);

    &.is-FixedToolbar {
      background-color: rgba(255, 255, 255, 1);
    }

    &>.el-button:not(.first) {
      margin-left: 10px;
      font-size: 13px;
    }

    &.isNavShow {
      left: 250px;
      width: calc(100% - 250px);
    }

    // &.isShowRealScene {
    //   //transform: translateX(-40px);
    // }
    .loading-container {
      position: absolute;
      z-index: 1;
      height: 40px;
      width: 40px;
      left: 0;
      top: 0;

      &__inner {
        height: 40px;
        width: 40px;

        & ::v-deep .el-loading-mask {
          background-color: transparent;

          .el-loading-spinner {
            margin-top: -12px;

            svg {
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }
  }

  .tool-btn {
    font-size: 14px;
    padding: 4px;
  }

  .i-main-content-wrapper {
    position: relative;
    display: flex;
    align-items: stretch;

    .tree-nav {
      background-color: #FFF;
      // width: 540px;
      // flex-shrink: 0;
      // overflow-y: auto;
      position: relative;
      // z-index: 112;
      z-index: $page-nav-zindex;
    }

    .subBlock {
      display: flex;
      flex-grow: 1;
      position: relative;
      z-index: 1;
    }

    .map {
      flex-grow: 1;
      overflow: hidden;

      &.is-animate {
        overflow: hidden;
      }

      &.is-FixedToolbar {
        margin-top: 40px;
      }

      & ::v-deep .amap-logo,
      & ::v-deep .amap-copyright {
        display: none !important;
        z-index: -1;
      }

      & ::v-deep .amap-marker-label {
        border: 1px solid #CCC;
        border-radius: 2px;
        background-color: rgba(#FFF, .85);
        font-weight: 600;
      }
    }

    .toggleNav {
      position: absolute;
      left: 250px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 102;
      font-size: 56px;
      cursor: default;

      &.isHide {
        left: 0;
      }
    }

    .v-detail {
      position: absolute;
      z-index: 111;
      left: 0px;
      bottom: 0;
      //height: 250px;
      border-top: 1px solid #DCDFE6;
      width: calc(100% - 0px);
      box-sizing: content-box;
      background-color: #FFF;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);

      &.isHide {
        left: 0;
        width: calc(100%);
      }

      .v-pager {
        $color: rgba(0, 0, 0, .95);
        text-align: right;
        background-color: #F4F4F8;
        padding: 0;

        & ::v-deep span:not([class*=suffix]),
        & ::v-deep button,
        & ::v-deep .el-pager,
        & ::v-deep .el-pager li,
        & ::v-deep .el-input,
        & ::v-deep .el-input input {
          height: 20px;
          line-height: 20px;
        }

        & ::v-deep .el-pagination__total {
          float: left;
          color: $color;
          font-size: 12px;
          padding-left: 8px;
        }

        & ::v-deep .el-pagination__sizes {
          float: left;
          color: $color;
        }

        & ::v-deep .el-input__inner {
          border-radius: 2px !important;
          color: $color;
        }

        & ::v-deep .el-pagination__jump {
          color: $color;
        }
      }

      .table-check {
        position: absolute;
        z-index: 111;
        right: 10px;
        top: 8px;
        background-color: #F4F4F8;

        .el-button {
          padding: 0 4px;

          & ::v-deep span {
            font-size: 12px;
          }

          & ::v-deep i {
            font-size: 13px;
          }
        }

        .table-exchange {
          height: 20px;
          width: 20px;
          margin-left: 8px;
          vertical-align: middle;
          cursor: pointer;
        }

        .el-checkbox {
          vertical-align: middle;

          & ::v-deep .el-checkbox__label {
            font-size: 12px;
          }
        }
      }

      .toggleTable {
        position: absolute;
        left: 50%;
        top: -16px;
        transition: all 0.3s ease;
        transform: translateX(-50%) translateY(0);
        z-index: 102;
        font-size: 56px;
        cursor: default;
      }
    }

    .v-video.is-video {
      position: absolute;
      // top: 64%;
      left: 14%;
      z-index: 888;

      .video {
        width: 500px;
        height: 300px;
      }

      &:hover {
        z-index: 1000;
      }

      .closeVideo {
        position: absolute;
        top: 15px;
        left: 445px;
        z-index: 5;
        font-size: 16px;
        padding: 8px 9px;
        border-radius: 2px;
        opacity: .7;
        background-color: $--color-danger;
        color: #FFF;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }

    .v-pano.is-panoopen {
      flex-grow: 1;
      position: relative;
      width: 0%;
      margin-top: 40px;
      z-index: 109;
      overflow: hidden;

      &:hover {
        z-index: 109;
      }

      .closeBtn {
        position: absolute;
        top: 20px;
        left: 100px;
        z-index: 5;
        font-size: 16px;
        padding: 8px 9px;
        border-radius: 2px;
        opacity: .7;
        background-color: $--color-danger;
        color: #FFF;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }

    .v-pano {
      .v-wrap {
        height: 105%;

        .box {
          width: 100%;
          height: 100%;
        }
      }

      .nofollow {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    .map--tip {
      position: absolute;
      z-index: 151;
      left: 0;
      top: 48px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;

      &.isNavShow {
        left: 50px;
      }

      $bk: rgba(255, 255, 255, 0.7);
      $color: #607D8B;
      $border: #CFD8DC;
      $bk-highlight: #BBDEFB;
      $bk-highlight-color: $--color-primary;

      .countdown {
        min-width: 120px;
        padding: 0 12px;
        background-color: $bk;
        color: $color;
        user-select: none;

        .countdown__number {
          color: $--color-primary;
          margin-right: 2px;
        }
      }

      .tip__item {
        background-color: $bk;
        color: $bk-highlight-color;
        padding: 0 12px;
        white-space: nowrap;
        width: fit-content;
        border-radius: 4px;

        .item__action {
          color: $color;
          margin-left: 2px;
          cursor: pointer;
        }

        .item__split {
          height: 1px;
          display: block;
          background-color: $border;
        }

        & ::v-deep span {
          color: $color;
        }
      }

      p:not(:first-child) {
        margin-top: 5px;
      }
    }

    & ::v-deep .amap-logo {
      bottom: 2px !important;
    }

    & ::v-deep .amap-overlay-text-container {
      font-size: 12px;
      background-color: #FFF;
      border-color: #1E88E5;
      padding: 4px 4px;
      opacity: 0.8;
      //box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
    }

    & ::v-deep .amap-scalecontrol {
      z-index: 99;
    }
  }

  .badge-item {
    height: 28px;
    max-width: 90px;
    display: flex;
    align-items: center;

    .icon {
      font-size: 14px;
      margin-right: 2px;
      margin-left: -2px;
      color: #f44336;
      animation: flash infinite 1s linear;
    }

    & .el-button--small.is-round {
      padding: 6px 16px;
    }
  }

  @keyframes flash {
    10% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    90% {
      opacity: 1;
    }
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 201;
    position: fixed;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: $--color-text-regular;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);

    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;

      &:hover {
        background: #eee;
      }

      &.active {
        color: $--color-primary;
      }
    }
  }


  .targetSIMInput {
    padding: 0 20px;
    height: 48px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .1);
    position: relative;
    z-index: 3;

    span {
      color: var(--color-p);
      white-space: nowrap;
      flex-shrink: 0;
      font-size: 16px;
    }

    .el-input {
      flex-grow: 1;

      & ::v-deep .el-input__inner {
        border-color: transparent;
        background-color: #F4F4F8;
      }
    }
  }

  .targetSIMInput-empty {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      color: rgba(0, 0, 0, .26);
      font-size: 128px;
      margin-top: -24px;
    }

    span {
      color: rgba(0, 0, 0, .26);
      margin-top: 24px;
      font-size: 16px;
    }
  }

  .square {
    height: 14px;
    width: 14px;
    display: block;
    border-radius: 2px;
    background-color: #BDBDBD;
    margin-left: 4px;

    &.isOnline {
      background-color: #4CAF50;
    }
  }

  .imgcolumn {
    img {
      margin-left: 4px;
      vertical-align: text-top;
      margin-top: -1px;
    }
  }

  .kycsTip {
    margin: 0 auto;
    color: #ed242b;
    font-size: 18px;
    font-weight: 600;

    a {
      color: #11aef4;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .tipGroup {
    width: 100%;
    position: absolute;
    top: 0px;
  }

  .gl {
    width: 26px;
    height: 26px;
    position: absolute;
    right: 10px;
    top: 20%;
    box-shadow: #0000003d 1px 2px 1px;
    background: url(../../assets/images/gl-navi-control-pc4.png) -110px 1px / 266px no-repeat #fff;
  }

  .d3 {
    background: url(../../assets/images/gl-navi-control-pc4.png) -188px 1px / 266px no-repeat #fff;
  }

  .fixed-tool {
    position: absolute;
    right: 0;
    top: 100px;
    z-index: 151;
    font-size: 14px;

    >ul {
      list-style: none;
      display: inline-block;
      vertical-align: top;

      li {
        height: 40px;
        width: 40px;
        background-color: rgba(0, 0, 0, 0.6);
        color: #FFF;
        border-radius: 4px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;

        &:hover {
          background-color: #000;
        }

        &.active {
          background-color: #9ed485;
        }

        .icon {
          font-size: 20px;
        }
      }
    }

    .layers-ui {
      width: 200px;
      display: inline-block;
      vertical-align: top;
      background-color: #FFF;
      overflow-y: auto;

      .sidebar_heading {
        padding: 10px 20px;

        .el-icon-close {
          float: right;
          cursor: pointer;
        }
      }

      .base-layers {
        padding: 10px 20px;
        border-bottom: 1px solid #DDD;

        ul {
          li {
            overflow: hidden;
            border-radius: 3px;
            border: 2px solid #FFF;
            margin-bottom: 8px;
            position: relative;
            height: 50px;
            display: inline-block;
            cursor: pointer;
            transition: border-color 0.08s ease-in;

            &:hover {
              border-color: #CCC;
            }

            &.active {
              border-color: #60a94f;
            }

            &.hide {
              display: none;
            }

            label {
              position: absolute;
              top: 0;
              z-index: 1;
              left: 0;
              padding: 2px 6px;
              font-size: 16px;
              font-weight: 600;
              background-color: rgba(255, 255, 255, 0.9);
              border-bottom-right-radius: 3px;
            }
          }
        }
      }
    }
  }

  .tracktable {
    & ::v-deep .caret-wrapper {
      height: 22px;

      .ascending {
        top: 0;
      }

      .descending {
        bottom: 0;
      }
    }
  }

</style>

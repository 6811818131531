import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function GetRegionList(containChild, queryKey) {
  const loginKey = getToken()
  return request({
    url: './query/GetRegionList',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      containChild,
      queryKey
    })
  })
}

export function DeleteCenterCircleObjects(CircleID,ObjectIDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteCenterCircleObjects',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      CircleID,
      ObjectIDs
    })
  })
}

export function GetRegionCircleObject(CircleID) {
  const loginKey = getToken()
  return request({
    url: './query/GetRegionCircleObject',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      CircleID
    })
  })
}

export function SaveRegion(regionId, regionName, maxLon, maxLat, minLon, minLat, points) {
  const loginKey = getToken()
  return request({
    url: './query/SaveRegion',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      regionId, regionName, maxLon, maxLat, minLon, minLat, points
    })
  })
}

export function SaveCenterCircle(RegionList,roadID) {
  const loginKey = getToken()
  return request({
    url: './add/SaveCenterCircle',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      RegionList,
      roadID
    })
  })
}

export function DeleteRegion(regionId) {
  const loginKey = getToken()
  return request({
    url: './query/DeleteRegion',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      regionId
    })
  })
}

export function AddRegionObject(regionId, objectIdList, IOType, needAlarm, checkSpeed, maxSpeed, startTime, endTime, inTips, outTips) {
  const loginKey = getToken()
  return request({
    url: './query/AddRegionObject',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      regionId, objectIdList, IOType, needAlarm, checkSpeed, maxSpeed, startTime, endTime, inTips, outTips
    })
  })
}


export function ZD_AddRegionObject(regionId, objectIdList, IOType, needAlarm, checkSpeed, maxSpeed, startTime, endTime, inTips, outTips, CheckRetention,MaxRetentionMinutes) {
  const loginKey = getToken()
  return request({
    url: './add/ZD_AddRegionObject',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      regionId, objectIdList, IOType, needAlarm, checkSpeed, maxSpeed, startTime, endTime, inTips, outTips, CheckRetention,MaxRetentionMinutes
    })
  })
}

export function SaveCenterCircleObject(CircleIDs, ObjectIDs, Setting) {
  const loginKey = getToken()
  return request({
    url: './add/SaveCenterCircleObject',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      CircleIDs, ObjectIDs, Setting
    })
  })
}

export function DeleteRegionObject(regionId, objectIdList) {
  const loginKey = getToken()
  return request({
    url: './query/DeleteRegionObject',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      regionId,
      objectIdList
    })
  })
}

export function GetRegionObject(regionId) {
  const loginKey = getToken()
  return request({
    url: './query/GetRegionObject',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      regionId
    })
  })
}

export function GetProvinceList(containChild, queryKey) {
  const loginKey = getToken()
  return request({
    url: './query/GetProvinceList',
    method: 'POST',
    data: Object.assign({}, commonParams, {
      loginKey,
      containChild,
      queryKey
    })
  })
}
import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function GetTrackTrip(objectId, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/GetTrackTrip',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      objectId,
      startTime,
      endTime
    })
  })
}

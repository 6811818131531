import ChangeMap from '@/views/common/change-map'
import { getTrackCache, setTrackCache, getTrackConfig } from '@/common/cache'
import { mapGetters } from 'vuex'
import { getDirectionDescription } from '@/common/dictionary'
import miment from 'miment'
import { ExportTrackDataFilter, ExportKMLTrackDataFilter } from '@/api/common'
import { localTime, saveBlob, translateFR, saveBlobKML } from '@/common/utils'
import * as API from '@/api/common'
import MapPlace from '@/views/common/map-place'
import { GetWrapper, GetTextView } from '@/views/online/google-map.js'
export default {
  components: {
    ChangeMap,
    MapPlace
  },
  created() {
    // let localTrace = getTrackConfig()?getTrackConfig().line:'';
    // this.setting = Object.assign(this.setting,localTrace)
    const mixinTrackCache = Object.assign({
      isShowMoreOptions: false,
      showAddress: false
    }, getTrackCache())

    this.isShowMoreOptions = mixinTrackCache.isShowMoreOptions
    this.setting.showAddress = mixinTrackCache.showAddress
  },
  data() {
    return {
      flashNotAllow: false,
      infoWindowSimple: null,
      trackDataTable: [],
      showPath: true,
      iconSize: 38,
      showHomeAndCompanyPos: false,
      homeAndCompanyMsg: '',
      visibleColumns: ['OilNum'],
      exportTrackDataBtnLoading: false,
      exportTrackDatakmlBtnLoading: false,
      deviceViews: []
    }
  },
  computed: {
    ...mapGetters([
      'hasMenu',
      'loginInfo'
    ]),
    currentDeviceInfo() {
      if (!this.objectId) {
        return null
      }
      const index = this.group.devices.findIndex(k => k.ObjectID == this.objectId)
      if (index == -1) {
        return null
      } else {
        return this.group.devices[index]
      }
    },
    currentTrackType() {
      if (this.currentDeviceInfo && this.currentDeviceInfo.TrackerType === 1) {
        return 1
      } else {
        this.setting.hideZeroSpeed = true
        return 0
      }
    }
  },
  watch: {
    isShowMoreOptions(val) {
      const cache = getTrackCache({})
      cache.isShowMoreOptions = val
      setTrackCache(cache)
    },
    showPath() {
      if (this.setting.lineMode == 2) {
        this.addLine()
      }
    },
    currentTrackType(val) {
      if (val === 0) {
        // 有线设备
        // if(getTrackConfig()&&getTrackConfig().line){
        //   Object.assign(this.setting,getTrackConfig().line)
        // }
        console.log('有线设备')
        this.setting.hideZeroSpeed = true
        this.setting.referLocation = false
        this.showPath = true

        // if (window.AMap && this.map instanceof window.AMap.Map) {
        //   this.setting.lineMode = 1
        // }
      } else {
        // 无线设备
        // if(getTrackConfig()&&getTrackConfig().point){
        //   Object.assign(this.setting,getTrackConfig().point)
        // }
        console.log('无线设备')
        // this.setting.showPoint = true
        // this.setting.hideZeroSpeed = false
        // this.setting.referLocation = true
        // this.setting.lineMode = 2
        // this.setting.showDetail = true
        this.showPath = false
      }
    },
    showHomeAndCompanyPos(val) {

      this.homeAndCompanyMsg = ''

      if (val) {
        // 显示家的位置
        this.showHomeAddressInMap()
      } else {
        // 关闭家的位置
        this.closeHomeAddressInMap()
      }
    },
    'setting.showAddress': {
      deep: true,
      handler(val) {
        const cache = getTrackCache({})
        cache.showAddress = val
        setTrackCache(cache)
      }
    }
  },
  methods: {
    checkFlash(el) {
      this.flashNotAllow = !(el.querySelector('object:not([data-i])') != null)
    },
    toggle_ruler() {
      if (window.BMap && (this.map instanceof window.BMap.Map)) {
        return this.toggle_ruler_bmap()
      }

      if (this.mapToolRuler) {
        this.toggle_ruler__end()
      } else {
        this.toggle_ruler__start()
      }
    },
    toggle_ruler__start() {
      this.map.plugin(['AMap.RangingTool'], () => {
        this.mapToolRuler = new this.AMap.RangingTool(this.map)
        this.mapToolRuler.turnOn()
        this.$message({
          message: this.$t('onlineMonitor.distanceTip'),
          type: 'success'
        })
        this.AMap.event.addListener(this.mapToolRuler, "end", () => {
          this.mapToolRuler.turnOff()
          this.mapToolRuler = null
        })
      })
    },
    toggle_ruler__end() {
      this.mapToolRuler.turnOff()
      this.mapToolRuler = null
    },
    toggle_ruler_bmap() {
      if (this.mapToolRulerBmap) {
        this.toggle_ruler__end_bmap()
      } else {
        this.toggle_ruler__start_bmap()
      }
    },
    toggle_ruler__start_bmap() {
      // this.map.plugin(['AMap.RangingTool'], () => {
      //   this.mapTool.ruler = new this.AMap.RangingTool(this.map)
      //   this.mapTool.ruler.turnOn()
      //   this.AMap.event.addListener(this.mapTool.ruler, "end", () => {
      //     this.mapTool.ruler.turnOff()
      //     this.mapTool.ruler = null
      //   })
      // })
      this.mapToolRulerBmap = new window.BMapLib.DistanceTool(this.map)
      this.mapToolRulerBmap.open();
      this.$message({
        message: this.$t('onlineMonitor.distanceTip'),
        type: 'success'
      })
      this.mapToolRulerBmap.addEventListener('ondrawend', () => {
        this.mapToolRulerBmap = null
      })
    },
    toggle_ruler__end_bmap() {
      this.mapToolRulerBmap.close()
      this.mapToolRulerBmap = null
    },

    ifNotExistsWindowSimple() {
      if (!this.infoWindowSimple) {
        let infoWindowSimple = new this.AMap.InfoWindow({
          isCustom: true,
          content: '',
          offset: new this.AMap.Pixel(0, -42)
        })
        // 根据getId获取当前监控车辆
        infoWindowSimple.setId = id => infoWindowSimple._id = id
        infoWindowSimple.getId = () => infoWindowSimple._id
        this.infoWindowSimple = infoWindowSimple
      }
    },
    ifNotExistsWindowSimple2() {
      this.Wrapper = GetWrapper(window.google)
      if (!this.infoWindowSimple) {
        let infoWindowSimple = new this.Wrapper(
          this.map,
          new this.google.maps.LatLng(0, 0),
          { el: document.createElement('div'), offsetY: -35 },
          null)

        infoWindowSimple.setId = id => infoWindowSimple._id = id
        infoWindowSimple.getId = () => infoWindowSimple._id
        this.infoWindowSimple = infoWindowSimple
      }
    },
    // ifNotExistsWindowSimple2() {
    //   if (!this.infoWindowSimple) {
    //     let infoWindowSimple = new this.Wrapper(
    //       this.map,
    //       new this.google.maps.LatLng(0, 0),
    //       { el: document.createElement('div'), offsetY: -35 },
    //       null)

    //     this.infoWindowSimple = infoWindowSimple
    //   }
    //     // 根据getId获取当前监控车辆
    //     infoWindowSimple.setId = id => infoWindowSimple._id = id
    //     infoWindowSimple.getId = () => infoWindowSimple._id
    //     this.infoWindowSimple = infoWindowSimple
    //   }
    // },

    // 轨迹纠偏
    getGraspRoadList(list) {
      var data = list.map(k => ({
        x: k.Lon,
        y: k.Lat,
        sp: k.Speed,
        ag: k.Direct,
        tm: k.tm
      }))
      return new Promise((resolve, reject) => {
        let collection = []

        let newData = []
        // 每组500个
        let i = 0
        while (data.length > i * 500) {
          let promise = new Promise((res, rej) => {
            let start = i * 500
            let end = data.length > (start + 500) ? (start + 500) : data.length
            end = end - 1
            let part = data.slice(start, end + 1)
            if (!this.graspRoad) {
              return rej('GraspRoad plugin is undefined.')
            }
            this.graspRoad.driving(part, (error, result) => {
              if (error) {
                return rej(error)
              }
              res(result)
            })
          })
          collection.push(promise)
          i++
        }

        Promise.all(collection).then(resultArray => {
          let totalMile = 0
          for (let j = 0; j < resultArray.length; j++) {
            newData = newData.concat(resultArray[j].data.points)
            totalMile += resultArray[j].data.distance
          }
          this.totalMile = (totalMile / 1000).toFixed(1)
          // let a = { text: JSON.stringify(data)}
          // console.log('Upload origin data and get grasproad response is:', a, newData)
          resolve(newData)
        }).catch(error => {
          console.error(error)
          reject(error)
        })
      })
    },

    showHomeAddressInMap() {
      if (!this.showHomeAndCompanyPos) {
        return
      }

      this.homeAndCompanyMsg = ''
      this.comPosition = false

      if (this.group.people && this.group.people.HomeLon && this.group.people.HomeLat) {
        let lon = this.group.people.HomeLon
        let lat = this.group.people.HomeLat

        if (window.google) {
          if (this.homeMarker) {
            this.homeMarke.setMap(null)
          }

          let marker = new this.google.maps.Marker({
            position: { lng: lon, lat: lat },
            map: this.map,
            icon: {
              url: require('../../assets/icon/home.svg'),
              anchor: new this.google.maps.Point(16, 32),
              size: new google.maps.Size(24, 24),
              scaledSize: new google.maps.Size(24, 24)
            },
          })
          marker.addListener('click', () => {
            this.infoWindow.setContent(this.getSimplePanel(this.$t('onlineTrack.homeAddress'), [
            ], '6em', [lon, lat]))
            this.infoWindow.open(this.map, marker.getPosition())
          })
          // this.map.setCenter(marker.getPosition())
          this.homeMarker = marker
          this.homePosition = true
          // this.homeCircle = new this.google.maps.Circle({
          //   map: this.map,
          //   center:new this.google.maps.LatLng(lat, lon), 
          //   strokeColor:'#409EFF',
          //   strokeWeight: 1,
          //   fillColor:'rgba(0,0,255,1)',
          //   fillOpacity:0.1,
          //   radius: 400
          // })
          this.homePosition = true
        } else if (window.BMap) {

          if (this.homeMarker) {
            this.homeMarke.setMap(null)
          }
          let marker = new this.BMap.Marker(
            new this.BMap.Point(lon, lat),
            {
              icon: new this.BMap.Icon(
                require('../../assets/icon/home.svg'),
                new this.BMap.Size(24, 24),
                {
                  anchor: new this.BMap.Size(24, 24),
                  imageSize: new this.BMap.Size(24, 24)
                })
            })

          marker.on('click', () => {
            this.infoWindow.setContent(this.getSimplePanel(this.$t('onlineTrack.homeAddress'), [
            ], '6em', [lon, lat]))
            this.infoWindow.open(this.map, marker.getPosition())
          })
          // this.map.setCenter(marker.getPosition())
          this.homeMarker = marker
          this.homeMarker.setMap(this.map)
          this.homePosition = true
          // this.homeCircle = new this.google.maps.Circle({
          //   map: this.map,
          //   center:new this.google.maps.LatLng(lat, lon), 
          //   strokeColor:'#409EFF',
          //   strokeWeight: 1,
          //   fillColor:'rgba(0,0,255,1)',
          //   fillOpacity:0.1,
          //   radius: 400
          // })
          this.homePosition = true

        } else {
          if (this.homeMarker) {
            this.homeMarker.setPosition([lon, lat])
            this.homeCircle.setPosition([lon, lat])
          } else {
            let marker = new this.SimpleMaker({
              iconLabel: {
                innerHTML: `<img src="${require('../../assets/icon/home.svg')}" style="height:24px;width:24px;background:#FFF;border-radius:50%;padding:2px;transform:translateY(6px);">`
              },
              iconTheme: 'fresh',
              iconStyle: 'red',
              map: this.map,
              position: [lon, lat]
            })
            marker.on('click', () => {
              this.infoWindow.setContent(this.getSimplePanel(this.$t('onlineTrack.homeAddress'), [
              ], '6em', [lon, lat]))
              this.infoWindow.open(this.map, marker.getPosition())
              this.isShowRealScene && this.showRealScene(lon, lat)
            })
            this.homeMarker = marker
            this.homePosition = true

            this.homeCircle = new this.AMap.Circle({
              map: this.map,
              strokeColor: '#409EFF',
              strokeWeight: 1,
              fillColor: 'rgba(0,0,255,1)',
              fillOpacity: 0.1,
              center: [lon, lat],
              radius: 400
            })

          }
        }
        this.homePosition = true
      } else if (this.homeMarker) {
        this.homeMarker.setMap(null)
        this.homeMarker = null
        this.homeCircle.setMap(null)
        this.homeCircle = null
      }

      if (this.group.people && this.group.people.CompanyLon && this.group.people.CompanyLat) {
        let lon = this.group.people.CompanyLon
        let lat = this.group.people.CompanyLat
        if (window.google) {
          if (this.companyMarker) {
            this.companyMarker.setMap(null)
          }

          let marker = new this.google.maps.Marker({
            position: { lng: lon, lat: lat },
            map: this.map,
            icon: {
              url: require('../../assets/icon/department.svg'),
              anchor: new this.google.maps.Point(16, 32),
              size: new google.maps.Size(24, 24),
              scaledSize: new google.maps.Size(24, 24)
            },
          })
          marker.addListener('click', () => {
            this.infoWindow.setContent(this.getSimplePanel(this.$t('onlineTrack.homeAddress'), [
            ], '6em', [lon, lat]))
            this.infoWindow.open(this.map, marker.getPosition())
          })
          // this.map.setCenter(marker.getPosition())
          this.companyMarker = marker
          // this.comCircle = new this.google.maps.Circle({
          //   map: this.map,
          //   center:new this.google.maps.LatLng(lat, lon), 
          //   strokeColor:'#409EFF',
          //   strokeWeight: 1,
          //   fillColor:'rgba(0,0,255,1)',
          //   fillOpacity:0.1,
          //   radius: 400
          // })
          this.comPosition = true
        } else if (window.BMap) {

          if (this.companyMarker) {
            this.companyMarker.setMap(null)
          }
          let marker = new this.BMap.Marker(
            new this.BMap.Point(lon, lat),
            {
              icon: new this.BMap.Icon(
                require('../../assets/icon/department.svg'),
                new this.BMap.Size(24, 24),
                {
                  anchor: new this.BMap.Size(24, 24),
                  imageSize: new this.BMap.Size(24, 24)
                })
            })

          marker.on('click', () => {
            this.infoWindow.setContent(this.getSimplePanel(this.$t('onlineTrack.homeAddress'), [
            ], '6em', [lon, lat]))
            this.infoWindow.open(this.map, marker.getPosition())
          })
          // this.map.setCenter(marker.getPosition())
          this.companyMarker = marker
          this.companyMarker.setMap(this.map)
          this.comPosition = true
          // this.homeCircle = new this.google.maps.Circle({
          //   map: this.map,
          //   center:new this.google.maps.LatLng(lat, lon), 
          //   strokeColor:'#409EFF',
          //   strokeWeight: 1,
          //   fillColor:'rgba(0,0,255,1)',
          //   fillOpacity:0.1,
          //   radius: 400
          // })
          this.comPosition = true
        } else {
          if (this.companyMarker) {
            this.companyMarker.setPosition([lon, lat])
            this.comCircle.setPosition([lon, lat])
          } else {
            let marker = new this.SimpleMaker({
              iconLabel: {
                innerHTML: `<img src="${require('../../assets/icon/department.svg')}" style="height:24px;width:24px;background:#FFF;border-radius:50%;padding:2px;transform:translateY(6px);">`
              },
              iconTheme: 'fresh',
              iconStyle: 'red',
              map: this.map,
              position: [lon, lat]
            })
            marker.on('click', () => {
              this.infoWindow.setContent(this.getSimplePanel(this.$t('onlineTrack.companyAddress'), [
              ], '6em', [lon, lat]))
              this.infoWindow.open(this.map, marker.getPosition())
              this.isShowRealScene && this.showRealScene(lon, lat)
            })
            this.companyMarker = marker
            this.comCircle = new this.AMap.Circle({
              map: this.map,
              strokeColor: '#409EFF',
              strokeWeight: 1,
              fillColor: 'rgba(0,0,255,1)',
              fillOpacity: 0.1,
              center: [lon, lat],
              radius: 400
            })
          }
        }
        this.comPosition = true
      } else if (this.companyMarker) {
        this.companyMarker.setMap(null)
        this.companyMarker = null
        this.comCircle.setMap(null)
        this.comCircle = null
      }
      if (window.AMap) {
        this.setFitView(this.map, [this.companyMarker, this.homeMarker])
      }

      if (!this.homePosition && !this.comPosition) {
        this.homeAndCompanyMsg = this.$t('onlineTrack.noHomeAndComPosition')
      } else if (!this.homePosition) {
        this.homeAndCompanyMsg = this.$t('onlineTrack.noHomePosition')
      } else if (!this.comPosition) {
        this.homeAndCompanyMsg = this.$t('onlineTrack.noComPosition')
      }

      if (this.homeAndCompanyMsg) {
        this.$message(this.homeAndCompanyMsg)
      }
    },

    closeHomeAddressInMap() {
      if (this.homeMarker) {
        this.homeMarker.setMap(null)
        this.homeMarker = null
        this.homeCircle && this.homeCircle.setMap(null)
        this.homeCircle = null
      }
      if (this.companyMarker) {
        this.companyMarker.setMap(null)
        this.companyMarker = null
        this.comCircle && this.comCircle.setMap(null)
        this.comCircle = null
      }
    },
    addDataToMap_getPanel(device) {

      // 如果在监控列表里面就取最新数据
      // const index = this.list.findIndex(k => this.diffCompare(k, device))
      // if (~index) {
      //   device = this.list[index].data
      // }

      // let options = [
      //   { text: 'GPS', theme: 'success', desc: '正在使用GPS定位' },
      //   { text: 'LBS', theme: 'warning', desc: '正在使用基站定位' },
      //   { text: 'WIFI', theme: 'default', desc: '正在使用WIFI定位' }
      // ]
      //let location = options[device.MainMode - 1]

      // 判断是有线设备还是无线设备
      let externalData = null
      if (device.TrackerType !== 0 || device.Battery > 0) {
        // 无线设备
        externalData = {
          trackerType: device.TrackerType,
          battery: device.Battery,
          batteryLife: device.BatteryLife,
          schedule: localTime(device.Schedule)
        }
      }
      let replceStatus = ''
      if (device.StatusDes.search(device.Schedule) > 0) {
        replceStatus = device.StatusDes.replace(new RegExp(device.Schedule, 'g'), localTime(device.Schedule))
      }
      if (this.$i18n.locale === 'fr') {
        replceStatus = translateFR(device.StatusDes)
      }
      // console.log('externalData', externalData)
      // titleBackground
      let titleBackground = '#757575'
      if (device.isAlarm) {
        // 报警色
        titleBackground = '#F44336'
      } else if (device.isOnline && !device.Status) {
        // 在线Acc关
        titleBackground = '#757575'
      } else if (device.isOnline && (device.GPSFlag % 2) == 0) {
        // 非精确定位
        titleBackground = '#862B7C'
      } else if (device.isOnline && device.Speed > 0) {
        // 运行
        titleBackground = '#4CAF50'
      } else if (device.isOnline) {
        // 在线停车
        titleBackground = '#95221D'
      } else {
        // 离线
        titleBackground = '#757575'
      }

      let viewInfoFun = this.loginInfo.allFuns.includes(5042) || this.loginInfo.allFuns.includes(5044)
      let isVipImg = device.isVip ? `<img src="${require('../../assets/icon/vip.png')}" style="margin-left:8px;height:14px;vertical-align:sub;"/>` : ''
      //console.log(this.$i18n.locale === 'zh' ? this.$t('onlineMonitor.panelLink6') : null)

      let links = []
      if (this.$i18n.locale === 'zh') {
        links.push({ icon: 'icon-jizhan', text: this.$t('onlineMonitor.panelLink1') })
      }
      if (viewInfoFun && this.importantInfo) {
        links.push({ icon: 'icon-ziliao', text: this.$t('onlineMonitor.panelLink2') })
      }

      {
        links.push({ icon: 'icon-jiejing', text: this.$t('onlineMonitor.panelLink3') })
        links.push({ icon: 'icon-guiji', text: this.$t('onlineMonitor.panelLink4') })
      }
      if (this.$i18n.locale !== 'fr') {
        links.push({ icon: 'icon-iconfontgongjuxiang', text: this.$t('onlineMonitor.panelLink12') })   //定位
      }
      if (this.$i18n.locale === 'zh' && this.importantInfo) {
        if (device.SF) {
          links.push({ icon: 'icon-chefang', text: this.$t('onlineMonitor.panelLink9') })
        } else {
          links.push({ icon: 'icon-shefang', text: this.$t('onlineMonitor.panelLink8') })
        }
      }

      if (this.loginInfo.isUser && this.importantInfo && this.loginInfo.allFuns.includes(20120)) {
        links.push({ icon: 'icon-zhilingfasong', text: this.$t('onlineMonitor.panelLink5') })
      }
      if (this.$i18n.locale === 'zh' && this.importantInfo) {
        links.push({ icon: 'icon-message2', text: this.$t('onlineMonitor.panelLink7') })
        // links.push({icon:'icon-jiance', text:this.$t('onlineMonitor.panelLink6')})
      }

      let ObjectID = device.ObjectID
      let panel = this.$dataPanel.create({
        objectId: device.ObjectID,
        headClick: true,
        bgColor: true,
        titleBackground,
        lon: device.Lon,
        lat: device.Lat,
        showHeadButton: true,
        SF: device.SF,
        showAddrDefault: this.isShowAddressDefault,
        showDvr: device.MDTModelID === '3169' && !device.soures ? true : false,
        dvrData: device.VideoCircuit ? device.VideoCircuit.split(",") : [],
        title: device.ObjectName + (device.VehicleList.length > 0 ? '<i class="el-icon-caret-bottom"/>' : null),
        datas: [
          { title: `${this.$t('onlineTrack.rcvt')}`, text: miment(device.RcvTime).format() },
          { title: `${this.$t('onlineTrack.gpst')}`, text: miment(device.GPSTime).format() },
          { title: `${this.$t('onlineTrack.speed')}`, text: this.$t('onlineTrack.speedDesc', { speed: device.Speed }) },
          { title: `${this.$t('onlineTrack.direction')}`, text: getDirectionDescription(device.Direct, this.$i18n.locale) },
          { title: `${this.$t('onlineTrack.mile')}`, text: device.Mileage + this.$t('onlineTrack.kilo') },
          { title: `${this.$t('onlineTrack.oil')}`, text: device.OilNum + this.$t('onlineTrack.oilUnit') },
          { title: `${this.$t('onlineTrack.status')}`, text: status }
        ],
        links,
        // links: [
        //   this.$i18n.locale === 'zh' ? this.$t('onlineMonitor.panelLink1') : null,
        //   viewInfoFun ? this.$t('onlineMonitor.panelLink2') : null,
        //   this.$t('onlineMonitor.panelLink3'),
        //   this.$t('onlineMonitor.panelLink4'),
        //   this.loginInfo.isUser ? this.$t('onlineMonitor.panelLink5') : null,
        //   this.$i18n.locale === 'zh' ? this.$t('onlineMonitor.panelLink6') : null,
        // ],
        onLinkClick: (item, e) => this.panelLinkClick(item, e, ObjectID),
        onHeadClick: (e, objectId) => this.panelHeadClick(e, objectId),
        onHeadButtonClick: () => this.panelHeadButtonClick(ObjectID),
        videoURL: (e) => this.panelVideoClick(e, device),
        isAlarm: device.isAlarm,
        onCancelAlarm: this.handleCancelAlarm,
        paddingLeft: '5em',
        showMore: false,
        externalData: externalData,
        close: true,
        //visible: this.panel_open,
        onClose: () => {
          if (process.env.VUE_APP_PLAT === '_kc') {
            this.infoWindow.close()
          } else {
            this.map.clearInfoWindow()
          }
        },
        onChange: () => {
        }
      })
      return panel
    },

    getPanelImmediate(device) {
      let status = device.StatusDes
      if (this.$i18n.locale === 'fr') {
        status = translateFR(device.StatusDes)
      }
      Object.assign(this.infoContent, {
        bgColor: true,
        title: this.group.vehicle.VehicleNum,
        datas: [
          { title: `${this.$t('onlineTrack.rcvt')}`, text: miment(device.RcvTime).format() },
          { title: `${this.$t('onlineTrack.gpst')}`, text: miment(device.GPSTime).format() },
          { title: `${this.$t('onlineTrack.speed')}`, text: this.$t('onlineTrack.speedDesc', { speed: device.Speed }) },
          { title: `${this.$t('onlineTrack.direction')}`, text: getDirectionDescription(device.Direct, this.$i18n.locale) },
          { title: `${this.$t('onlineTrack.mile')}`, text: device.Mileage + this.$t('onlineTrack.kilo') },
          { title: `${this.$t('onlineTrack.oil')}`, text: device.OilNum + this.$t('onlineTrack.oilUnit') },
          { title: `${this.$t('onlineTrack.status')}`, text: status }
        ],
        showAddrDefault: this.setting.showAddress,
        paddingLeft: '4em',
        showMore: false,
        titleBackground: '#409EFF',
        close: true,
        externalTag: false,
        lon: device.sLon,
        lat: device.sLat,
        //visible: this.panel_open,
        onClose: () => {
          this.map.clearInfoWindow()
        },
        onChange: () => {
        }
      })
    },
    getPanelImmediate2(device) {
      let status = device.StatusDes
      if (this.$i18n.locale === 'fr') {
        status = translateFR(device.StatusDes)
      }
      let statusText = '';
      switch (device.tag) {
        case 1:
          statusText = `${this.$t('onlineTrack.ubiRollover')}`
          break;
        case 2:
          statusText = `${this.$t('onlineTrack.ubiCollision')}`
          break;
        case 3:
          statusText = `${this.$t('onlineTrack.ubiAccelerate')}`
          break;
        case 4:
          statusText = `${this.$t('onlineTrack.ubiDecelerate')}`
          break;
        case 5:
          statusText = `${this.$t('onlineTrack.ubiTurnRight')}`
          break;
        case 6:
          statusText = `${this.$t('onlineTrack.ubiTurnLeft')}`
          break;
        default:
          console.log("");
          break;
      }
      Object.assign(this.infoContent, {
        bgColor: true,
        title: this.group.vehicle.VehicleNum,
        datas: [
          { title: `${this.$t('onlineTrack.gpst')}`, text: `${miment(device.GPSTime).format()}    (${statusText})` },
          { title: `${this.$t('onlineTrack.Attachment')}`, text: device.Attachment ? device.Attachment : `${this.$t('dialogCellAndLbs.emtpy')}`, hidden: false },
          { title: `${this.$t('onlineTrack.status')}`, text: status }
        ],
        showAddrDefault: this.setting.showAddress,
        paddingLeft: '4em',
        showMore: false,
        titleBackground: '#409EFF',
        close: true,
        lon: device.rLon,
        lat: device.rLat,
        externalTag: true,
        externalTagData: device.tag,
        //visible: this.panel_open,
        onClose: () => {
          this.map.clearInfoWindow()
        },
        onChange: () => {
        }
      })
    },
    exportTrackData() {
      let sTime = this.setting.startTime
      let eTime = this.setting.endTime
      this.exportTrackDataBtnLoading = true
      ExportTrackDataFilter(this.objectId, sTime, eTime, this.setting.hideZeroSpeed, false, this.setting.showAddress).then(ret => {
        saveBlob(ret.data, this.$t('onlineTrack.exportFileName', {
          name: this.group.vehicle.VehicleNum,
          start: miment(sTime).format('YYYYMMDDhhmm'),
          end: miment(eTime).format('YYYYMMDDhhmm')
        }))
      }).finally(() => {
        this.exportTrackDataBtnLoading = false
      })
    },
    exportKmlData() {
      let sTime = this.setting.startTime
      let eTime = this.setting.endTime
      this.exportTrackDatakmlBtnLoading = true
      ExportKMLTrackDataFilter(this.objectId, sTime, eTime, this.setting.hideZeroSpeed, false, this.setting.showAddress).then(ret => {
        saveBlobKML(ret.data, this.group.vehicle.VehicleNum + '.kml')
      }).finally(() => {
        this.exportTrackDatakmlBtnLoading = false
      })
    },

    getIconAngle(imageUrl, angle) {
      if (imageUrl.indexOf('Alarm') != -1 ||
        imageUrl.indexOf('Personal') != -1 ||
        imageUrl.indexOf('hat') != -1 ||
        imageUrl.indexOf('RV') != -1 ||
        imageUrl.indexOf('Fifth Wheel') != -1 ||
        imageUrl.indexOf('person') != -1 ||
        imageUrl.indexOf('article') != -1 ||
        imageUrl.indexOf('Trailer') != -1 ||
        imageUrl.indexOf('child') != -1 ||
        imageUrl.indexOf('youth') != -1 ||
        imageUrl.indexOf('middle-aged') != -1 ||
        imageUrl.indexOf('old-man') != -1 ||
        imageUrl.indexOf('other') != -1 ||
        imageUrl.indexOf('child1') != -1 ||
        imageUrl.indexOf('youth1') != -1 ||
        imageUrl.indexOf('middle-aged1') != -1 ||
        imageUrl.indexOf('old-man1') != -1 ||
        imageUrl.indexOf('other1') != -1 ||
        imageUrl.indexOf('child0') != -1 ||
        imageUrl.indexOf('youth0') != -1 ||
        imageUrl.indexOf('middle-aged0') != -1 ||
        imageUrl.indexOf('old-man0') != -1 ||
        imageUrl.indexOf('other0') != -1 ||
        imageUrl.indexOf('cuboid') != -1 ||
        imageUrl.indexOf('round-ellipse') != -1 ||
        imageUrl.indexOf('irregular-form') != -1 ||
        imageUrl.indexOf('gs_A+0') != -1 ||
        imageUrl.indexOf('gs_A+1') != -1 ||
        imageUrl.indexOf('gs_A0') != -1 ||
        imageUrl.indexOf('gs_A1') != -1 ||
        imageUrl.indexOf('gs_image') != -1 ||
        imageUrl.indexOf('gs_media') != -1 ||
        imageUrl.indexOf('gs_other0') != -1 ||
        imageUrl.indexOf('gs_other1') != -1 ||
        imageUrl.indexOf('gs_rescue') != -1 ||
        imageUrl.indexOf('gs_shuttlebus') != -1 ||
        imageUrl.indexOf('Moto') != -1 ||
        imageUrl.indexOf('Marine') != -1) {
        return 0
      } else if (imageUrl.indexOf('Moto') != -1) {
        return 90
      } else {
        return angle || 0
      }
    },
    setIconRouter(imageUrl) {
      if (imageUrl.indexOf('Alarm') != -1
        || imageUrl.indexOf('Personal') != -1
        || imageUrl.indexOf('hat') != -1
        || imageUrl.indexOf('RV') != -1
        || imageUrl.indexOf('Fifth Wheel') != -1
        || imageUrl.indexOf('person') != -1
        || imageUrl.indexOf('article') != -1
        || imageUrl.indexOf('Trailer') != -1
        || imageUrl.indexOf('child') != -1
        || imageUrl.indexOf('youth') != -1
        || imageUrl.indexOf('middle-aged') != -1
        || imageUrl.indexOf('old-man') != -1
        // || imageUrl.indexOf('other') != -1
        || imageUrl.indexOf('child1') != -1
        || imageUrl.indexOf('youth1') != -1
        || imageUrl.indexOf('middle-aged1') != -1
        || imageUrl.indexOf('old-man1') != -1
        // || imageUrl.indexOf('other1') != -1
        || imageUrl.indexOf('child0') != -1
        || imageUrl.indexOf('youth0') != -1
        || imageUrl.indexOf('middle-aged0') != -1
        || imageUrl.indexOf('old-man0') != -1
        // || imageUrl.indexOf('other0') != -1
        || imageUrl.indexOf('gs_A+0') != -1 
        || imageUrl.indexOf('gs_A+1') != -1 
        || imageUrl.indexOf('gs_A0') != -1 ||
        imageUrl.indexOf('gs_A1') != -1 ||
        imageUrl.indexOf('gs_image') != -1 ||
        imageUrl.indexOf('gs_media') != -1 ||
        imageUrl.indexOf('gs_other0') != -1 ||
        imageUrl.indexOf('gs_other1') != -1 ||
        imageUrl.indexOf('gs_rescue') != -1 ||
        imageUrl.indexOf('gs_shuttlebus') != -1
        || imageUrl.indexOf('Moto') != -1
        || imageUrl.indexOf('cuboid') != -1
        || imageUrl.indexOf('round-ellipse') != -1
        || imageUrl.indexOf('irregular-form') != -1
        || imageUrl.indexOf('Marine') != -1) {
        return true
      } else {
        return false
      }
    },
    mapPlaceSelect(lng, lat) {
      let location = [lng, lat]
      if (window.BMap && this.map instanceof window.BMap.Map) {

        API.ConvertFrom([{ lng, lat }], 'mars', 'bd').then(ret => {
          lng = ret.data[0].lng
          lat = ret.data[0].lat
          this.map.setZoom(15)
          this.map.setCenter(new window.BMap.Point(lng, lat))
          if (this.mapPlaceSelectMarker) {
            this.mapPlaceSelectMarker.setPosition(new this.BMap.Point(lng, lat))
          } else {
            this.mapPlaceSelectMarker = new this.BMap.Marker(new this.BMap.Point(lng, lat))
            this.mapPlaceSelectMarker.setMap(this.map)
          }
        })

      } else if (this.google) {

        this.map.setCenter(new this.google.maps.LatLng(lat, lng))
        // 切换地图zoom
        if (this.map.getZoom() < 15) {
          this.map.setZoom(15)
        }

        if (this.mapPlaceSelectMarker) {
          this.mapPlaceSelectMarker.setMap(null)
          this.mapPlaceSelectMarker = null
        }
        this.mapPlaceSelectMarker = new this.google.maps.Marker({
          position: { lng: lng, lat: lat },
          map: this.map,
          title: this.$t('onlineMonitor.clickRightRemove')
        })

      } else if (window.AMap && this.map instanceof window.AMap.Map) {
        this.map.setZoomAndCenter(15, location)
        if (this.mapPlaceSelectMarker) {
          this.mapPlaceSelectMarker.setPosition(location);
        } else {
          this.mapPlaceSelectMarker = new window.AMap.Marker({
            position: location,
            map: this.map,
            title: this.$t('onlineMonitor.clickRightRemove')
          })
          this.mapPlaceSelectMarker.on('rightclick', () => {
            this.mapPlaceSelectMarker.setMap(null)
            this.mapPlaceSelectMarker = null
          })
        }
      }
    },
    // // 添加路径
    // addGraspRoadPath(data) {
    //   let handle = () => {

    //     this.getGraspRoadList(data).then(result => {
    //       if (this.graspRoadPath) {
    //         this.graspRoadPath.setMap(null)
    //         this.graspRoadPath = null
    //       }

    //       this.graspRoadPath = new window.AMap.Polyline({
    //         path: result.map(k => [k.x, k.y]),
    //         strokeWeight: 3,
    //         strokeOpacity: 1,
    //         strokeColor:'#4CAF50',
    //         showDir: false,
    //         map: this.map
    //       })
    //     }).catch(err => {
    //       console.log('请求分组数据异常', err)
    //     })

    //     this.graspRoad.driving(data, (error, result) => {
    //       if (error) {
    //         console.error('轨迹纠偏失败，', error)
    //         this.$message({
    //           message: '轨迹纠偏失败，' + error.errdetail,
    //           type: 'error'
    //         })
    //         return
    //       }

    //       if (this.graspRoadPath) {
    //         this.graspRoadPath.setMap(null)
    //         this.graspRoadPath = null
    //       }

    //       this.graspRoadPath = new window.AMap.Polyline({
    //         path: result.data.points.map(k => [k.x, k.y]),
    //         strokeWeight: 3,
    //         strokeOpacity: 1,
    //         strokeColor:'#4CAF50',
    //         showDir: false,
    //         map: this.map
    //       })

    //     })
    //   }

    //   if (this.graspRoad) {
    //     handle()
    //   } else {
    //     window.AMap.plugin('AMap.GraspRoad', () => {
    //       this.graspRoad = new window.AMap.GraspRoad()
    //       handle()
    //     })
    //   }
    // },
  },
  beforeDestroy() {
    if (this.map && this.map.destroy) {
      this.map.destroy()
    }
  }
}

<template>
  <div>
    <el-dialog @closed="resetDialog" append-to-body title="关联设备(圆形围栏）" :visible.sync="dialogVisible"
      custom-class="v-dialog v-dialog--lg v-dialog--mdpadding v-dialog--footer">
      <div class="tab-wrapper tab--flex">
        <div class="tabPanels">
          <div class="cols step1">
            <tree-user v-if="dialogVisible" :title="$t('regionManage.selectVehicle')" show-icon show-vehicle
              :widthFixed='false' :show-object-count.sync="showObjectCount" :use-local-setting="false"
              @check="treeCheck"></tree-user>
          </div>
          <div class="cols step2">
            <div class="searchInput">
              <SelectDevice @select="handleQuery" :clear="searchCheck.VehicleName"></SelectDevice>
              <div class="group">
                <el-tag size="mini" v-if="searchCheck" clsss="tag" closable @close="clearSearch">
                  {{searchCheck.VehicleName}}</el-tag>
              </div>
            </div>
            <el-form ref="form" label-width="100px" label-position='top' size="small">
              <div class="solid">
                <el-form-item :label="$t('regionManage.regionName')">
                  <!-- <span class="label-form">{{$t('regionManage.regionName')}}</span> -->
                  <el-select v-model="form.regionId" :disabled="!!modifyRow">
                    <el-option v-for="item in regionList" :key="item.PolygonID" :label="item.PolygonName"
                      :value="item.PolygonID"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('regionManage.recordType')">
                  <el-select v-model="form.IOType">
                    <el-option :label="$t('regionManage.enterRegion')" :value="1"></el-option>
                    <el-option :label="$t('regionManage.leaveRegion')" :value="2"></el-option>
                    <el-option :label="$t('regionManage.enterLeaveRegion')" :value="0"></el-option>
                    <el-option :label="$t('regionManage.enterLeaveRegionNoAction')" :value="3"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('regionManage.validTime')">
                  <div class="elRow">
                    <div class="elCol">
                      <el-time-picker class="v-range" v-model="form.timeRange[0]" :placeholder="$t('common.selectDate')"
                        :clearable="false">
                      </el-time-picker>
                    </div>
                    <div class="elCol">
                      <el-time-picker class="v-range" v-model="form.timeRange[1]" :placeholder="$t('common.selectDate')"
                        :clearable="false">
                      </el-time-picker>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label-position='left'>
                  <div class="line">
                    <span @click="()=>{form.needAlarm =!form.needAlarm}">
                      <i-icon name="iconxuanzhong" v-if="form.needAlarm"></i-icon><i v-else></i>
                    </span>
                    <p>{{$t('regionManage.triggerAlarm')}}</p>
                  </div>
                </el-form-item>
                <el-form-item label-position='left'>
                  <div class="line"><span @click="()=>{form.checkSpeed =!form.checkSpeed}">
                      <i-icon name="iconxuanzhong" v-if="form.checkSpeed"></i-icon><i v-else></i>
                    </span>
                    <p>{{$t('regionManage.regionLimit')}}</p>
                    <div class="input">
                      <el-input v-model="form.maxSpeed" :disabled="!form.checkSpeed" size="mini" :min="1" :max="999"
                        :controls="false">
                        <template slot="append">{{$t('regionManage.limitTip')}}</template>
                      </el-input>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label-position='left'>
                  <div class="line">
                    <span @click="()=>{form.CheckRetention =!form.CheckRetention}">
                      <i-icon name="iconxuanzhong" v-if="form.CheckRetention"></i-icon><i v-else></i>
                    </span>
                    <p>{{$t('regionManage.triggerAlarm2')}}</p>
                    <div class="input">
                      <el-input v-model="form.MaxRetentionMinutes" :disabled="!form.CheckRetention" size="mini" :min="1"
                        :max="999" :controls="false">
                        <template slot="append">{{$t('regionManage.limitTip2')}}</template>
                      </el-input>
                    </div>
                  </div>
                </el-form-item>
              </div>
              <div class="bot">
                <el-form-item :label="$t('regionManage.enterTip')">
                  <el-input v-model="form.inTips" maxlength="200"></el-input>
                </el-form-item>
                <el-form-item :label="$t('regionManage.leaveTip')">
                  <el-input v-model="form.outTips" maxlength="200"></el-input>
                </el-form-item>
                <p class="tips">*
                  {{$t('regionManage.regionTip')}}
                </p>
                <!-- <div class="clk" @click="() => openDialogList(form.regionId)">
                  {{$t('regionManage.viewBindDevice')}}</div> -->
              </div>
            </el-form>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="border" plain type="primary" @click="dialogVisible = false">{{$t('common.close')}}</el-button>
        <el-button class="border" plain type="primary" @click="handlerPolygonSave" :loading="btnLoading">
          {{$t('common.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import * as API from '@/api/region'
  import {
    // ConvertFrom,
    GetObjectIdByMixID,
    // SearchVehicleInfo
  } from '@/api/common'
  import miment from 'miment'
  import SelectDevice from '@/views/common/select-device'
  import TreeUser from '@/views/common/tree-user'
  import MapPlace from '@/views/common/map-place'
  import SelectCar from '@/views/common/select-car'
  export default {
    data() {
      return {
        miment,
        dialogVisible: false,
        modifyRow: '',
        searchState: '',
        searchCheck: '',
        treeCheckedKeys: [],
        regionList: [],
        btnLoading: false,
        form: {
          regionId: null,
          IOType: 0,
          needAlarm: true,
          checkSpeed: false,
          CheckRetention: false,
          MaxRetentionMinutes: 60,
          maxSpeed: 100,
          timeRange: [miment('2018/11/11 00:00:00'), miment('2018/11/11 23:59:59')],
          inTips: '',
          outTips: ''
        },
        showObjectCount: false,
      }
    },
    components: {
      SelectDevice,
      TreeUser,
      MapPlace,
      SelectCar
    },
    computed: {
      dialogTitle() {
        return this.modifyRow ? (this.$t('regionManage.modify') + `【${this.modifyRow.PolygonName}】`) : this.$t(
          'regionManage.add')
      }
    },
    methods: {
      open(item) {
        console.log(item)
        this.clearSearch()
        this.regionList = []
        this.dialogVisible = true
        this.form.regionId = item.obj.RegionID || 0
        if (item.obj.tag) {
          this.regionList = item.obj.tag
        } else {
          let obj = {
            PolygonID: item.obj.RegionID,
            PolygonName: item.obj.PolygonName
          }
          this.regionList.push(obj)
        }

        this.modifyRow = item
      },
      close() {
        this.dialogVisible = false
      },
      resetDialog() {
        this.activeName = 'first'
        this.modifyRow = null
        this.btnLoading = false
      },
      openDialogList() {
        const obj = {
          type: this.modifyRow.type,
          PolygonID: this.form.regionId
        }
        this.$emit('openPolygonlist', obj)
      },
      clearSearch() {
        this.searchCheck = '';
        this.searchState = '';
      },
      handleQuery(val) {
        this.searchCheck = val;
        this.treeCheckedKeys = [];
      },
      handlerPolygonSave() {
        const modifyRow = this.modifyRow
        if (modifyRow.type === 1) {
          this.addRegionObject()
        } else if (modifyRow.type === 2) {
          this.addCircleObject()
        }
      },
      async addRegionObject() {
        this.btnLoading = true
        let list = [];
        if (this.searchCheck) {
          list.push(this.searchCheck.ObjectID)
        } else {
          if (!this.form.regionId) {
            this.$alert(this.$t('regionManage.selectTip'))
            return
          }
          list = await this.fetchObjectList()
        }
        API.ZD_AddRegionObject(this.form.regionId, list, this.form.IOType, this.form.needAlarm ? 1 : 0, this.form
          .checkSpeed ? 1 : 0, this.form.maxSpeed, this.form.timeRange[0], this.form.timeRange[1], this.form.inTips,
          this.form.outTips, this.form.CheckRetention ? 1 : 0, this.form.MaxRetentionMinutes).then(({
          data
        }) => {
          if (!data.errCode) {
            this.$message({
              message: this.$t('regionManage.saveSuccessed'),
              type: 'success'
            })
            this.dialogVisible = false
            // this.fetchRegionList()
          } else {
            console.log(data.errMsg)
          }
        }).catch(err => {
          console.error(err)
          this.btnLoading = false
        })
      },
      async addCircleObject() {
        this.btnLoading = true
        let list = [];
        let ids = this.form.regionId ? [this.form.regionId] : this.regionList.map(k => {
          return k.CircleID
        })
        if (this.searchCheck) {
          list.push(this.searchCheck.ObjectID)
        } else {
          if (!ids) {
            this.$alert(this.$t('regionManage.selectTip'))
            return
          }
          list = await this.fetchObjectList()
        }
        const settting = {
          CheckSpeed: this.form.checkSpeed,
          StartTime: this.form.timeRange[0],
          EndTime: this.form.timeRange[1],
          IOType: this.form.IOType,
          InTips: this.form.inTips,
          OutTips: this.form.outTips,
          MaxSpeed: this.form.maxSpeed,
          NeedAlarm: this.form.needAlarm
        }
        API.SaveCenterCircleObject(ids, list, settting).then(({
          data
        }) => {
          if (!data.errCode) {
            this.$message({
              message: this.$t('regionManage.saveSuccessed'),
              type: 'success'
            })
            this.dialogVisible = false
            // this.fetchRegionList()
          } else {
            console.log(data.errMsg)
          }
        }).catch(err => {
          console.error(err)
          this.btnLoading = false
        })
      },
      // regionId, objectIdList, IOType, needAlarm, checkSpeed, maxSpeed, startTime, endTime, inTips, outTips, CheckRetention,MaxRetentionMinutes
      fetchObjectList() {
        const promise = new Promise((resolve) => {
          GetObjectIdByMixID(this.treeCheckedKeys, null).then(({
            data
          }) => {
            resolve(data)
          })
        })
        return promise
      },
      treeCheck(keys) {
        this.treeCheckedKeys = keys.slice()
      },
    }
  }

</script>

<style lang="scss" scoped>
  .tab-wrapper {
    height: 618px;

    .tab-place-search {
      position: absolute;
      z-index: 111;
      top: 50px;
      left: 20px;
      width: 350px;
      border-radius: 20px;
      box-shadow: 0 2px 6px 0 rgba(181, 181, 181, .4);
    }

    .v-tool {
      position: absolute;
      z-index: 111;
      top: 25%;
      left: 25%;
      height: 50%;
      width: 50%;
      transition: all 0.3s ease;
      background-color: rgba(64, 158, 255, .8);
      border-radius: 4px;

      .tip {
        color: #FAFAFA;
        height: 38px;
        line-height: 38px;
        padding-left: 12px;
      }

      .start {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 12px 24px;
        font-size: 18px;
        color: #FFF;
        transition: all 0.3s ease;
        cursor: default;

        & span:hover {
          // color: #ef5350;
          cursor: pointer;
          transition: all 0.3s ease;
          display: inline-block;
          transform: translateY(-5px);
        }
      }

      &.minimize {
        top: 0;
        left: 0;
        height: 38px;
        width: 100%;

        .start {
          display: none;
        }
      }
    }

    &.tab--flex {
      display: flex;

      .tab-nav {
        width: 250px;
        border-left: 1px solid #E4E7ED;

        &.en {
          width: 350px;

          .v-item {
            .v_title {
              width: 11em;
              text-align: right;
            }

            .v_text {
              margin-left: 11em;
            }
          }
        }
      }

      .tab-body {
        flex-grow: 1;
      }
    }

    .v-item {
      .v_title {
        line-height: 32px;
        height: 32px;
      }
    }

    & ::v-deep .amap-logo,
    & ::v-deep .amap-copyright {
      display: none !important;
      z-index: -1;
    }
  }

  .tabPanels {
    width: 100%;
    // height: 560px;
    display: flex;

    .cols {
      flex-grow: 1;
      overflow: hidden;
    }

    .step1 {
      width: 50%;
      border: 1px solid #afdff2;
      border-radius: 10px;

      & ::v-deep .treeBlock {
        border-right: 0;
      }
    }

    .step2 {
      padding-left: 20px;
       & ::v-deep .el-input__inner {
        color: #fff;
        background-color: #001025;
        border-color: #617895;
      }
      .searchInput {
        .group {
          padding-top: 10px;
        }
      }
    }

    .con {
      width: 100%;
      height: 100%;
    }

    .selectUser {
      border: 1px solid #e0e1e1;
      padding: 5px;
      border-radius: 10px;

      .searchBar {
        margin-bottom: 15px;
      }
    }

    .solid {
      padding: 0px 10px 10px 10px;
    }

    .label-form {
      color: #999;
      margin-right: 10px;
    }

    .bot {
      border: 1px dashed #1890ff;
      border-radius: 5px;
      padding: 5px 10px;

      .tips {
        margin-top: 10px;
        height: 20px;
        color: #1890ff;
      }

      .clk {
        cursor: pointer;
        line-height: 28px;
        text-indent: 10px;
        color: #1890ff;
      }
    }

    .elRow {
      display: flex;

      .elCol {
        margin-right: 15px;
         & ::v-deep .el-input__inner {
          color: #fff;
          background-color: #001025;
          border-color: #001025;
          border-bottom-color: #617895;
        }
      }
    }

    .line {
      display: flex;

      span {
        width: 40px;
        position: relative;
        z-index: 1;

        i {
          position: absolute;
          left: 10px;
          top: 6px;
          z-index: 1;
          display: block;
          width: 18px;
          height: 18px;
          border: 1px solid #ccc;
          border-radius: 50%;
        }

        .icon {
          width: 26px;
          height: 26px;
          color: #1890ff;
          position: absolute;
          left: 6px;
          top: 3px;
          z-index: 1;
        }
      }

      p {
        // width: 100px;
        padding-right: 30px;
        color:#fff;
      }

      .input {
        & ::v-deep .el-input__inner {
          width: 160px;
          color: #fff;
          background-color: #001025;
          border-color: #001025;
          border-bottom-color: #617895;
        }

        & ::v-deep.el-input-group__append {
          min-width: 96px;
          height: 30px;
          line-height: 38px;
          background: none;
          border:none;
          color:#3a78c5;
        }
      }
    }

    & ::v-deep .el-form-item--small.el-form-item {
      margin-bottom: 0px;
    }

    & ::v-deep .el-form-item__label {
      padding-bottom: 0;
    }
  }

</style>

import {
  GetPOI,
  DeletePoi,
  GetRegionByLngLat,
  AddPoi
} from '@/api/common'
import {
  findFromArray
} from '@/common/utils'
import miment from 'miment'

// 混入POI与区域加载
export const mixinAmap = {
  created() {
    this.mixinPoiOverlays = []
    this.mixinRegionOverlays = []
    this.mixinRegionOverlaysJuhe = []
    this.mixinCircleOverlays = []
    this.mixinCircleOverlaysJuhe = []
    this.mixinCircleMarkers = []
    this.mixinRoadOverlays = []
    this.mixinRoadOverlaysJuhe = []
  },
  methods: {
    mixinAddPoi(map, list = null) {
      map = map || this.map
      return new Promise(resolve => {
        GetPOI('', true).then(ret => {

          this.mixinRemovePoi()
          list = list || ret.data.splice(0, 500)

          list.forEach((k, index) => {
            let marker = new window.AMap.Marker({
              map: map,
              position: [k.Lon_F, k.Lat_F],
              extData: k.PointID,
              icon: require('../../assets/icon/shadow_marker.png'),
              offset: new window.AMap.Pixel(-16, -32),
              label: {
                content: k.PointName,
                offset: new window.AMap.Pixel(32, 8)
              }
            })

            marker.on('click', () => {
              const d = list[index]
              this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
                title: `${this.$t('onlineMonitor.lngLat')}：`,
                text: d.Lon_F + ',' + d.Lat_F
              }], '5em', {
                links: [this.$t('onlineMonitor.removeFavirite')],
                onLinkClick: () => {
                  this.deletePoi(d)
                },
                lon: d.Lon_F,
                lat: d.Lat_F
              }))

              this.infoWindow.open(map, marker.getPosition())
              this.infoWindow.setId(null)
            })

            this.mixinPoiOverlays.push(marker)
          })
        }).finally(() => {
          resolve()
        })
      })
    },
    mixinAddPoiKC(map, list = null) {
      map = map || this.map
      return new Promise(resolve => {
        GetPOI('', true).then(ret => {
          this.mixinRemovePoi()
          list = list || ret.data.splice(0, 500)
          list.forEach((k, index) => {
            let marker = new window.google.maps.Marker({
              position: {
                lng: k.Lon_F,
                lat: k.Lat_F
              },
              label: k.PointName,
              map: map,
              icon: {
                url: require('../../assets/icon/shadow_marker.png'),
                anchor: new window.google.maps.Point(16, 32)
              },
              extID: k.PointID
            })
            marker.getExtData = function () {
              return marker.extID
            }
            marker.addListener('click', () => {
              const d = k
              this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
                title: `${this.$t('onlineMonitor.lngLat')}：`,
                text: d.Lon + ',' + d.Lat
              }], '5em', {
                links: [this.$t('onlineMonitor.removeFavirite')],
                onLinkClick: () => {
                  this.deletePoi(d)
                },
                lon: d.Lon,
                lat: d.Lat
              }))
              this.infoWindow.setPosition(marker.getPosition())
              this.infoWindow.setId(null)
              this.infoWindow.show()
            })
            this.mixinPoiOverlays.push(marker)
          })
        }).finally(() => {
          resolve()
        })
      })
    },
    mixinRemovePoi() {
      this.mixinPoiOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinPoiOverlays.splice(0)
    },
    mixinDeletePoi(map, item) {
      map = map || this.map
      DeletePoi([item.PointID])
        .then(() => {
          this.$message({
            message: this.$t('onlineMonitor.deletePoiTip', {
              name: item.PointName
            }),
            type: 'success'
          })

          map.clearInfoWindow()
          let marker = findFromArray(this.mixinPoiOverlays, k => k.getExtData() === item.PointID)
          if (marker) {
            marker.setMap(null)
            marker = null
          }
        })
    },
    mixinSavePoi(map) {
      map = map || this.map
      const handler = async e => {
        this.map.off('click', handler)
        const {
          value
        } = await this.$prompt(this.$t('onlineMonitor.savePoiTitle'), this.$t('onlineMonitor.savePoiTip'), {
          confirmButtonText: this.$t('common.save'),
          cancelButtonText: this.$t('common.cancel'),
          inputValidator(val) {
            if (!val) return this.$t('onlineMonitor.savePoiTitle')
            if (val.length > 50) return this.$t('onlineMonitor.savePoiTip2')
            return true
          }
        })

        const poi = {
          HoldID: -1,
          Lon_F: e.lnglat.getLng(),
          Lat_F: e.lnglat.getLat(),
          PointName: value,
          PointType: 5,
          Remark: ''
        }
        AddPoi(poi).then(ret => {
          this.selectPoi(ret.data)
          this.$message({
            message: this.$t('onlineMonitor.saveSuccessed'),
            type: 'success'
          })
        })
      }
      map.on('click', handler, this)
      this.$message(this.$t('onlineMonitor.savePoiTip3'))
    },
    mixinSelectPoi(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinPoiOverlays, k => k.getExtData() === item.PointID)
      if (target) {
        map.setFitView([target])
        return
      }
      let k = item
      let marker = new window.AMap.Marker({
        map: map,
        position: [k.Lon_F, k.Lat_F],
        extData: k.PointID,
        icon: require('../../assets/icon/shadow_marker.png'),
        offset: new window.AMap.Pixel(-16, -32),
        label: {
          content: k.PointName,
          offset: new window.AMap.Pixel(32, 8)
        }
      })
      marker.on('click', () => {
        const d = k
        this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
          title: `${this.$t('onlineMonitor.lngLat')}：`,
          text: d.Lon + ',' + d.Lat
        }], '5em', {
          links: [this.$t('onlineMonitor.removeFavirite')],
          onLinkClick: () => {
            this.deletePoi(d)
          },
          lon: d.Lon,
          lat: d.Lat
        }))
        this.infoWindow.open(this.map, marker.getPosition())
        this.infoWindow.setId(null)
      })
      this.mixinPoiOverlays.push(marker)
      map.setFitView([marker])
    },
    mixinAddRegion(map) {
      map = map || this.map
      // 大于级别13开始显示
      const bounds = map.getBounds()
      // 西南角
      const point_xinan = bounds.getSouthWest()
      // 东北角
      const point_dongbei = bounds.getNorthEast()
      GetRegionByLngLat(point_xinan.getLng(), point_xinan.getLat(), point_dongbei.getLng(), point_dongbei.getLat()).then(ret => {
        const list = ret.data
        this.mixinAddRegionDataToMap(map, list)
      })
    },
    mixinAddRegionDataToMap(map, list) {
      map = map || this.map
      this.mixinRemoveRegion()
      list.forEach(k => {
        let polygon = new window.AMap.Polygon({
          map,
          path: k.points.map(j => [j.Lon, j.Lat]),
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extData: k.RegionID
        })
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.AMap.LngLat(k.points[0].Lon, k.points[0].Lat)
        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.RegionName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.Lon,
            lat: k.Lat
          }))
          this.infoWindow.open(map, [k.points[0].Lon, k.points[0].Lat])
          this.infoWindow.setId(null)
        })

        this.mixinRegionOverlays.push(polygon)
      })
      // 添加聚合
      const opts = {
        url: require('../../assets/icon/darkRed.png'),
        size: new window.AMap.Size(48, 48),
        offset: new window.AMap.Pixel(-24, -24),
        textColor: '#FFF'
      }
      const sts = [opts, opts, opts, opts, opts]

      map.plugin(["AMap.MarkerClusterer"], () => {
        let cluster = new window.AMap.MarkerClusterer(map, this.mixinRegionOverlays, {
          styles: sts,
          gridSize: 80,
          maxZoom: 14
        })

        this.mixinRegionOverlaysJuhe.push(cluster)
        // this.cluster = cluster
        map.setFitView()
      })
    },
    mixinAddCircleDataToMap(map, list) {
      map = map || this.map
      console.log(list)
      this.mixinRemoveCircle()
      list.forEach(k => {
        let polygon = new window.AMap.Circle({
          map,
          center: [k.Lon, k.Lat],
          radius: k.R, //半径
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extData: k.CircleID
        })
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.AMap.LngLat(k.Lon,  k.Lat)
        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.CircleName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.CircleName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.Lon,
            lat: k.Lat
          }))
          this.infoWindow.open(map, [k.Lon,  k.Lat])
          this.infoWindow.setId(null)
        })

        this.mixinCircleOverlaysJuhe.push(polygon)
      })
      map.setFitView()
      // // 添加聚合
      // const opts = {
      //   url: require('../../assets/icon/darkRed.png'),
      //   size: new window.AMap.Size(48, 48),
      //   offset: new window.AMap.Pixel(-24, -24),
      //   textColor: '#FFF'
      // }
      // const sts = [opts, opts, opts, opts, opts]

      // map.plugin(["AMap.MarkerClusterer"], () => {
      //   let cluster = new window.AMap.MarkerClusterer(map, this.mixinCircleOverlays, {
      //     styles: sts,
      //     gridSize: 80,
      //     maxZoom: 14
      //   })

      //   this.mixinCircleOverlaysJuhe.push(cluster)
      //   // this.cluster = cluster
      //   map.setFitView()
      // })
    },
    mixinAddMarkerDataToMap(map, list) {
      map = map || this.map
      var icon = new window.AMap.Icon({
        size: new window.AMap.Size(40, 50),    // 图标尺寸
        image: '//webapi.amap.com/theme/v1.3/images/newpc/way_btn2.png',  // Icon的图像
        imageOffset: new window.AMap.Pixel(0, -60),  // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new window.AMap.Size(40, 50)   // 根据所设置的大小拉伸或压缩图片
       });
      list.forEach(k => {
        let marker = new window.AMap.Marker({
          map,
          position: [k.Lon, k.Lat],
          content:k.CircleName,
          anchor: 'center', //设置锚点
          icon: icon, // 添加 Icon 实例
          offset: new window.AMap.Pixel(0,0), //设置偏移量
          extData: k.CircleID,
          zoom: 13
        })
        this.mixinCircleMarkers.push(marker)
      })
      map.setFitView()
    },
    mixinAddRoadDataToMap(map, list) {
      map = map || this.map
      console.log(list)
      this.mixinRemoveRoad()
      list.forEach(row => {
        let polygon = new window.AMap.Polyline({
          map,
          path: row.Points.map(j => [j.Lon, j.Lat]),
          strokeColor: 'red',
          strokeOpacity: '0.9',
          strokeWeight:'10',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extData: row.PolygonID
        })
        // polygon.getDraggable = () => false
        // polygon.getPosition = () => new window.AMap.LngLat(k.Lon,  k.Lat)
        // polygon.on('click', () => {
        //   this.infoWindow.setContent(this.getSimplePanel(k.PolygonName, [{
        //       title: `${this.$t('onlineMonitor.regionName')}：`,
        //       text: k.PolygonName
        //     },
        //     {
        //       title: `${this.$t('onlineMonitor.createTime')}：`,
        //       text: miment(k.CreateTime).format()
        //     }
        //   ], '5em', {
        //     lon: k.Lon,
        //     lat: k.Lat
        //   }))
        //   this.infoWindow.open(map, [k.Lon,  k.Lat])
        //   this.infoWindow.setId(null)
        // })
        this.mixinRoadOverlaysJuhe.push(polygon)
      })
      // 添加聚合
      const opts = {
        url: require('../../assets/icon/darkRed.png'),
        size: new window.AMap.Size(48, 48),
        offset: new window.AMap.Pixel(-24, -24),
        textColor: '#FFF'
      }
      const sts = [opts, opts, opts, opts, opts]

      map.plugin(["AMap.MarkerClusterer"], () => {
        let cluster = new window.AMap.MarkerClusterer(map, this.mixinRoadOverlays, {
          styles: sts,
          gridSize: 80,
          maxZoom: 14
        })

        this.mixinRoadOverlaysJuhe.push(cluster)
        // this.cluster = cluster
        map.setFitView()
      })
    },
    mixinAddRegionDataToMapKC(map, list) {
      map = map || this.map
      this.mixinRemoveRegion()
      list.forEach(k => {
        let polygon = new this.google.maps.Polygon({
          map: map,
          paths: k.points.map(j => ({
            lng: j.Lon,
            lat: j.Lat
          })),
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extID: k.RegionID
        })
        polygon.getDraggable = () => false
        // polygon.getPosition = () => new this.AMap.LngLat(k.points[0].Lon, k.points[0].Lat)
        polygon.addListener('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.RegionName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.points[0].lon,
            lat: k.points[0].lat
          }))
          this.infoWindow.setPosition(new this.google.maps.LatLng(k.points[0].Lat, k.points[0].Lon))
          this.infoWindow.setId(null)
          this.infoWindow.show()
        })
        this.setFitViewKC(map,k.points.map(j => ({ lng: j.Lon, lat: j.Lat })))
        this.mixinRegionOverlays.push(polygon)
      })
      // 添加聚合

      // new MarkerClusterer(map, this.mixinRegionOverlays, {
      //   imagePath: require('../../assets/icon/darkRed.png'),
      // });
      // const opts = {
      //   url: require('../../assets/icon/darkRed.png'),
      //   size: new window.AMap.Size(48, 48),
      //   offset: new window.AMap.Pixel(-24, -24),
      //   textColor: '#FFF'
      // }
      // const sts = [opts, opts, opts, opts, opts]

      // map.plugin(["AMap.MarkerClusterer"], () => {
      //   let cluster = new window.AMap.MarkerClusterer(map, this.mixinRegionOverlays, {
      //     styles: sts,
      //     gridSize: 80,
      //     maxZoom: 14
      //   })

      //   this.mixinRegionOverlaysJuhe.push(cluster)
      //   // this.cluster = cluster
      //   map.setFitView()
      // })

    },
    mixinRemoveRegion() {
      this.mixinRegionOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinRegionOverlays.splice(0)
      this.mixinRegionOverlaysJuhe.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinRegionOverlaysJuhe.splice(0)
    },
    mixinRemoveCircle() {
      this.mixinCircleMarkers.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinCircleMarkers.splice(0)
      this.mixinCircleMarkers.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinCircleOverlays.splice(0)
      this.mixinCircleOverlaysJuhe.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinCircleOverlaysJuhe.splice(0)
    },
    mixinRemoveRoad() {
      this.mixinRoadOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinRoadOverlays.splice(0)
      this.mixinRoadOverlaysJuhe.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinRoadOverlaysJuhe.splice(0)
    },
    mixinSelectRegion(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinRegionOverlays, k => k.getExtData() === item.RegionID)
      if (target) {
        this.map.setFitView([target])
      } else {
        let k = item
        let polygon = new window.AMap.Polygon({
          map: map,
          path: k.points.map(j => [j.Lon, j.Lat]),
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extData: k.RegionID
        })
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.AMap.LngLat(k.points[0].Lon, k.points[0].Lat)
        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.RegionName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.points[0].Lon,
            lat: k.points[0].Lat
          }))
          this.infoWindow.open(map, [k.points[0].Lon, k.points[0].Lat])
          this.infoWindow.setId(null)
        })

        this.mixinRegionOverlays.push(polygon)
        map.setFitView([polygon])
      }
    },
    mixinSelectRoad(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinRoadOverlays, k => k.getExtData() === item.PolygonID)
      if (target) {
        this.map.setFitView([target])
      } else {
        let k = item
        let polygon = new window.AMap.Polyline({
          map,
          path: k.Points.map(j => [j.Lon, j.Lat]),
          strokeColor: 'red',
          strokeOpacity: '0.9',
          strokeWeight:'10',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extData: k.PolygonID
        })
        // polygon.getDraggable = () => false
        // polygon.getPosition = () => new window.AMap.LngLat(k.Lon,  k.Lat)
        // polygon.on('click', () => {
        //   this.infoWindow.setContent(this.getSimplePanel(k.CircleName, [{
        //       title: `${this.$t('onlineMonitor.regionName')}：`,
        //       text: k.CircleName
        //     },
        //     {
        //       title: `${this.$t('onlineMonitor.createTime')}：`,
        //       text: miment(k.CreateTime).format()
        //     }
        //   ], '5em', {
        //     lon: k.Points[0].Lon,
        //     lat: k.Points[0].Lat
        //   }))
        //   this.infoWindow.open(map, [k.Points[0].Lon,  k.Points[0].Lat])
        //   this.infoWindow.setId(null)
        // })

        this.mixinRoadOverlays.push(polygon)
        map.setFitView([polygon])
      }
    },
    mixinSelectCircle(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinCircleOverlays, k => k.getExtData() === item.CircleID)
      if (target) {
        this.map.setFitView([target])
      } else {
        let k = item
        let polygon = new window.AMap.Circle({
          map,
          center: [k.Lon, k.Lat],
          radius: k.R, //半径
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extData: k.CircleID
        })
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.AMap.LngLat(k.Lon,  k.Lat)
        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.CircleName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.CircleName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.Lon,
            lat: k.Lat
          }))
          this.infoWindow.open(map, [k.Lon,  k.Lat])
          this.infoWindow.setId(null)
        })

        this.mixinCircleOverlays.push(polygon)
        map.setFitView([polygon])
      }
    },
    mixinSelectRegionKC(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinRegionOverlays, k => k.extID === item.RegionID)
      if (target) {
        this.setFitViewKC(map, target.getPath().getArray())
      } else {
        let k = item
        let polygon = new window.google.maps.Polygon({
          map: map,
          paths: k.points.map(j => ({
            lng: j.Lon,
            lat: j.Lat
          })),
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extID: k.RegionID
        })
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.google.maps.LatLng(k.points[0].Lat, k.points[0].Lon)
        polygon.addListener('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.RegionName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.points[0].lon,
            lat: k.points[0].lat
          }))
          this.infoWindow.setPosition(new window.google.maps.LatLng(k.points[0].Lat, k.points[0].Lon))
          this.infoWindow.setId(null)
          this.infoWindow.show()
        })
        this.mixinRegionOverlays.push(polygon)
        this.setFitViewKC(map, k.points.map(j => ({
          lng: j.Lon,
          lat: j.Lat
        })))
      }
    },

    mixinSetFitView(map, markers) {
      if (!markers) {
        map.setFitView()
      } else {
        map.setFitView(markers)
      }
    },
    setFitViewKC(map, markers) {
      // const points = markers.map(k => k.getPosition())
      // const minLon = Math.min(...points.map(k => k.lng()))
      // const maxLon = Math.max(...points.map(k => k.lng()))
      // const minLat = Math.min(...points.map(k => k.lat()))
      // const maxLat = Math.max(...points.map(k => k.lat()))
      // const bounds = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(minLat, minLon), new window.google.maps.LatLng(maxLat, maxLon))
      const bounds = new window.google.maps.LatLngBounds()

      if (markers instanceof Array && markers && markers.length > 0) {
        var k = markers[0]
        if (k instanceof window.google.maps.Marker) {
          const points = markers.map(k => k.getPosition())

          for (let i = 0; i < points.length; i++) {
            bounds.extend(points[i])
          }
        } else if (k instanceof window.google.maps.LatLng) {
          for (let i = 0; i < markers.length; i++) {
            bounds.extend(markers[i])
          }
        } else if (k.lng && k.lat) {
          for (let i = 0; i < markers.length; i++) {
            bounds.extend(markers[i])
          }
        }

        map.fitBounds(bounds)
      } else {
        console.error('error occured in setFitView, invlaid input')
      }
    },
    mixinSelectPoiKC(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinPoiOverlays, k => k.extID === item.PointID)
      if (target) {
        this.setFitViewKC(map, [target])
        return
      }
      let k = item
      let marker = new window.google.maps.Marker({
        position: {
          lng: k.Lon_F,
          lat: k.Lat_F
        },
        label: k.PointNam,
        map: map,
        icon: {
          url: require('../../assets/icon/shadow_marker.png'),
          anchor: new window.google.maps.Point(16, 32)
        },
        extID: k.PointID
      })
      marker.getExtData = function () { return marker.extID }
      marker.addListener('click', () => {
        const d = k
        this.infoWindow.setContent(this.getSimplePanel(d.PointName, [
          { title: `${this.$t('onlineMonitor.lngLat')}：`, text: d.Lon + ',' + d.Lat }
        ], '5em', {
          links: [this.$t('onlineMonitor.removeFavirite')],
          onLinkClick: () => {
            this.deletePoi(d)
          },
          lon: d.Lon,
          lat: d.Lat
        }))
        this.infoWindow.open(this.map, marker.getPosition())
        this.infoWindow.setId(null)
      })
      this.mixinPoiOverlays.push(marker)
      this.setFitViewKC(map, [marker])
    },
    getSimplePanel(title, datas, padding, others = {}) {
      let panel = this.$dataPanel.create(Object.assign({
        bgColor: true,
        title: title,
        datas,
        paddingLeft: padding,
        showMore: false,
        showHeader: false,
        close: true,
        lon: others.lon,
        lat: others.lat,
        onClose: () => {
          if (window.google) {
            this.infoWindow.close()
          } else {
            this.map.clearInfoWindow()
          }
        },
        onChange: () => {}
      }, others))
      return panel
    },
  }
}

export const mixinBmap = {
  created() {
    this.mixinPoiOverlays = []
    this.mixinRegionOverlays = []
    this.mixinRegionOverlaysJuhe = []
    this.mixinCircleOverlays = []
    this.mixinCircleOverlaysJuhe = []
    this.mixinRoadOverlays = []
    this.mixinRoadOverlaysJuhe = []
  },
  methods: {
    mixinAddPoi(map, list = null) {
      map = map || this.map
      return new Promise(resolve => {
        GetPOI('', true).then(ret => {

          this.mixinRemovePoi()
          list = list || ret.data.splice(0, 500)

          list.forEach((k, index) => {
            let marker = new window.BMap.Marker(new window.BMap.Point(k.Lon_F, k.Lat_F), {
              icon: new window.BMap.Icon(require('../../assets/icon/shadow_marker.png'),
                new window.BMap.Size(32, 32), {
                  anchor: new window.BMap.Size(16, 32)
                }),
              title: k.PointName
            })

            marker.setMap(map)
            marker.extData = k.PointID
            marker.getExtData = function () {
              return this.extData
            }

            marker.on('click', () => {
              const d = list[index]
              this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
                title: `${this.$t('onlineMonitor.lngLat')}：`,
                text: d.Lon_F + ',' + d.Lat_F
              }], '5em', {
                links: [this.$t('onlineMonitor.removeFavirite')],
                onLinkClick: () => {
                  this.deletePoi(d)
                },
                lon: d.Lon_F,
                lat: d.Lat_F
              }))

              this.infoWindow.open(map, marker.getPosition())
              this.infoWindow.setId(null)
            })

            this.mixinPoiOverlays.push(marker)
          })
        }).finally(() => {
          resolve()
        })
      })
    },
    mixinRemovePoi() {
      this.mixinPoiOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinPoiOverlays.splice(0)
    },
    mixinDeletePoi(map, item) {
      map = map || this.map
      DeletePoi([item.PointID])
        .then(() => {
          this.$message({
            message: this.$t('onlineMonitor.deletePoiTip', {
              name: item.PointName
            }),
            type: 'success'
          })

          map.clearInfoWindow()
          let marker = findFromArray(this.mixinPoiOverlays, k => k.getExtData() === item.PointID)
          if (marker) {
            marker.setMap(null)
            marker = null
          }
        })
    },
    mixinSavePoi(map) {
      map = map || this.map
      const handler = async e => {
        this.map.off('click', handler)
        const {
          value
        } = await this.$prompt(this.$t('onlineMonitor.savePoiTitle'), this.$t('onlineMonitor.savePoiTip'), {
          confirmButtonText: this.$t('common.save'),
          cancelButtonText: this.$t('common.cancel'),
          inputValidator(val) {
            if (!val) return this.$t('onlineMonitor.savePoiTitle')
            if (val.length > 50) return this.$t('onlineMonitor.savePoiTip2')
            return true
          }
        })

        const poi = {
          HoldID: -1,
          Lon_F: e.point.lng,
          Lat_F: e.point.lat,
          PointName: value,
          PointType: 5,
          Remark: ''
        }
        AddPoi(poi).then(ret => {
          this.selectPoi(ret.data)
          this.$message({
            message: this.$t('onlineMonitor.saveSuccessed'),
            type: 'success'
          })
        })
      }
      map.on('click', handler, this)
      this.$message(this.$t('onlineMonitor.savePoiTip3'))
    },
    mixinSelectPoi(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinPoiOverlays, k => k.getExtData() === item.PointID)
      if (target) {
        this.mixinSetFitView(map, [target])
        return
      }
      let k = item
      let marker = new window.BMap.Marker(new window.BMap.Point(k.Lon_F, k.Lat_F), {
        icon: new window.BMap.Icon(require('../../assets/icon/shadow_marker.png'),
          new window.BMap.Size(32, 32), {
            anchor: new window.BMap.Size(16, 32)
          }),
        title: k.PointName
      })
      marker.setMap(map)
      marker.extData = k.PointID
      marker.getExtData = function () {
        return this.extData
      }

      marker.on('click', () => {
        const d = k
        this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
          title: `${this.$t('onlineMonitor.lngLat')}：`,
          text: d.Lon + ',' + d.Lat
        }], '5em', {
          links: [this.$t('onlineMonitor.removeFavirite')],
          onLinkClick: () => {
            this.deletePoi(d)
          },
          lon: d.Lon,
          lat: d.Lat
        }))
        this.infoWindow.open(this.map, marker.getPosition())
        this.infoWindow.setId(null)
      })
      this.mixinPoiOverlays.push(marker)
      this.mixinSetFitView(map, [marker])
    },
    mixinAddRegion(map) {
      map = map || this.map
      // 大于级别13开始显示
      const bounds = map.getBounds()
      // 西南角
      const point_xinan = bounds.getSouthWest()
      // 东北角
      const point_dongbei = bounds.getNorthEast()
      GetRegionByLngLat(point_xinan.getLng(), point_xinan.getLat(), point_dongbei.getLng(), point_dongbei.getLat()).then(ret => {
        const list = ret.data
        this.mixinAddRegionDataToMap(map, list)
      })
    },
    mixinAddRegionDataToMap(map, list) {
      map = map || this.map
      this.mixinRemoveRegion()
      list.forEach(k => {
        let polygon = new window.BMap.Polygon(k.points.map(j => new window.BMap.Point(j.Lon, j.Lat)), {
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          strokeWeight: 2
        })

        polygon.setMap(map)
        polygon.extData = k.RegionID
        polygon.getExtData = function () {
          return this.extData
        }
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.BMap.Polygon(k.points[0].Lon, k.points[0].Lat)

        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.RegionName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.Lon,
            lat: k.Lat
          }))
          this.infoWindow.open(map, [k.points[0].Lon, k.points[0].Lat])
          this.infoWindow.setId(null)
        })

        this.mixinRegionOverlays.push(polygon)
      })
      // // 添加聚合
      // const opts = {
      //   url: require('../../assets/icon/darkRed.png'),
      //   size: new window.AMap.Size(48, 48),
      //   offset: new window.AMap.Pixel(-24, -24),
      //   textColor: '#FFF'
      // }
      // const sts = [opts, opts, opts, opts, opts]

      // map.plugin(["AMap.MarkerClusterer"], () => {
      //   let cluster = new window.AMap.MarkerClusterer(map, this.mixinRegionOverlays, {
      //     styles: sts,
      //     gridSize: 80,
      //     maxZoom: 14
      //   })

      //   this.mixinRegionOverlaysJuhe.push(cluster)
      //   // this.cluster = cluster
      //   map.setFitView()
      // })
    },
    mixinAddCircleDataToMap(map, list) {
      map = map || this.map
      this.mixinRemoveCircle()
      list.forEach(k => {
        let polygon = new window.BMap.Polygon(k.points.map(j => new window.BMap.Point(j.Lon, j.Lat)), {
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          strokeWeight: 2
        })

        polygon.setMap(map)
        polygon.extData = k.RegionID
        polygon.getExtData = function () {
          return this.extData
        }
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.BMap.Polygon(k.points[0].Lon, k.points[0].Lat)

        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.CircleName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.CircleName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.Lon,
            lat: k.Lat
          }))
          this.infoWindow.open(map, [k.Lon,  k.Lat])
          this.infoWindow.setId(null)
        })
        this.mixinCircleOverlaysJuhe.push(polygon)
      })
      // // 添加聚合
      // const opts = {
      //   url: require('../../assets/icon/darkRed.png'),
      //   size: new window.AMap.Size(48, 48),
      //   offset: new window.AMap.Pixel(-24, -24),
      //   textColor: '#FFF'
      // }
      // const sts = [opts, opts, opts, opts, opts]

      // map.plugin(["AMap.MarkerClusterer"], () => {
      //   let cluster = new window.AMap.MarkerClusterer(map, this.mixinCircleOverlays, {
      //     styles: sts,
      //     gridSize: 80,
      //     maxZoom: 14
      //   })

      //   this.mixinCircleOverlaysJuhe.push(cluster)
      //   // this.cluster = cluster
      //   map.setFitView()
      // })
    },
    mixinRemoveRegion() {
      this.mixinRegionOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinRegionOverlays.splice(0)
      this.mixinRegionOverlaysJuhe.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinRegionOverlaysJuhe.splice(0)
    },
    mixinRemoveCircle() {
      this.mixinCircleOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinCircleOverlays.splice(0)
      this.mixinCircleOverlaysJuhe.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinCircleOverlaysJuhe.splice(0)
    },
    mixinSelectRegion(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinRegionOverlays, k => k.getExtData() === item.RegionID)
      if (target) {
        this.mixinSetFitView(map, [target])
      } else {
        let k = item
        let polygon = new window.BMap.Polygon(k.points.map(j => new window.BMap.Point(j.Lon, j.Lat)), {
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          strokeWeight: 2
        })
        polygon.setMap(this.map)
        polygon.extData = k.RegionID
        polygon.getExtData = function () {
          return this.extData
        }
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.AMap.LngLat(k.points[0].Lon, k.points[0].Lat)
        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.RegionName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.points[0].Lon,
            lat: k.points[0].Lat
          }))
          this.infoWindow.open(map, [k.points[0].Lon, k.points[0].Lat])
          this.infoWindow.setId(null)
        })

        this.mixinRegionOverlays.push(polygon)
        this.mixinSetFitView(map, [polygon])
      }
    },
    // mixinSelectRoad(map, item) {
    //   map = map || this.map
    //   const target = findFromArray(this.mixinRoadOverlays, k => k.getExtData() === item.PolygonID)
    //   if (target) {
    //     this.map.setFitView([target])
    //   } else {
    //     let k = item
    //     let polygon = new window.AMap.Polyline({
    //       map,
    //       path: k.Points.map(j => [j.Lon, j.Lat]),
    //       strokeColor: 'red',
    //       strokeOpacity: '0.9',
    //       strokeWeight:'10',
    //       fillColor: '#1890ff',
    //       fillOpacity: '0.1',
    //       extData: k.PolygonID
    //     })
    //     this.mixinRoadOverlays.push(polygon)
    //     map.setFitView([polygon])
    //   }
    // },
    mixinSelectRoad(map, item) {
      map = map || this.map
      const target = null
      if (target) {
        this.mixinSetFitView(map, [target])
      } else {
        let k = item
        console.log(k)
        let polygon = new window.BMap.Polyline(k.Points.map(j => new window.BMap.Point(j.Lon, j.Lat)),
        {
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          strokeWeight: 2
        })
        polygon.setMap(this.map)

        // polygon.getDraggable = () => false
        // polygon.getPosition = () => new window.AMap.LngLat(k.Lon,  k.Lat)
        // polygon.on('click', () => {
        //   this.infoWindow.setContent(this.getSimplePanel(k.CircleName, [{
        //       title: `${this.$t('onlineMonitor.regionName')}：`,
        //       text: k.CircleName
        //     },
        //     {
        //       title: `${this.$t('onlineMonitor.createTime')}：`,
        //       text: miment(k.CreateTime).format()
        //     }
        //   ], '5em', {
        //     lon: k.Points[0].Lon,
        //     lat: k.Points[0].Lat
        //   }))
        //   this.infoWindow.open(map, [k.Points[0].Lon,  k.Points[0].Lat])
        //   this.infoWindow.setId(null)
        // })
        this.mixinRoadOverlays.push(polygon)
        this.mixinSetFitView(map, [polygon])
      }
    },
    mixinSelectCircle(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinCircleOverlays, k => k.getExtData() === item.CircleID)
      if (target) {
        this.mixinSetFitView(map, [target])
      } else {
        let k = item
        let polygon = new window.BMap.Circle(new window.BMap.Point(k.Lon, k.Lat),k.R,{
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          strokeWeight: 2
        }); 
        polygon.setMap(this.map)
        polygon.extData = k.CircleID
        polygon.getExtData = function () {
          return this.extData
        }
        polygon.getDraggable = () => false
        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.CircleName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.CircleName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.Lon,
            lat: k.Lat
          }))
          this.infoWindow.open(map, [k.Lon,  k.Lat])
          this.infoWindow.setId(null)
        })

        this.mixinCircleOverlays.push(polygon)
        this.mixinSetFitView(map, [target])
      }
    },
    mixinSetFitView(map, markers) {
      let points = []

      if (markers instanceof Array) {
        for (let i = 0; i < markers.length; i++) {
          let k = markers[i]
          console.log(k)
          if (k instanceof window.BMap.Marker) {
            points.push(k.getPosition())
          } else if (k instanceof window.BMap.Polygon) {
            let pos = k.getPath()
            points = points.concat(pos)
          }
        }
      }

      console.log(markers, points)
      if (points.length) {
        map.setViewport(points)
      }
    },
    getSimplePanel(title, datas, padding, others = {}) {
      console.log(others)
      let panel = this.$dataPanel.create(Object.assign({
        bgColor: true,
        title: title,
        datas,
        paddingLeft: padding,
        showMore: false,
        showHeader: false,
        close: true,
        lon: others.lon,
        lat: others.lat,
        onClose: () => {
          if (this.google) {
            this.infoWindow.close()
          } else {
            this.map.clearInfoWindow()
          }
        },
        onChange: () => {}
      }, others))
      return panel
    },
  }
}

// export const mixinGoogle = {
//   created() {
//     this.mixinPoiOverlays = []
//   },
//   methods: {
//     mixinAddPoi(map) {
//       map = map || this.map

//     },
//     mixinRemovePoi(map) {
//       map = map || this.map

//     },
//     mixinDeletePoi(map, item) {
//       map = map || this.map

//     },
//     mixinSavePoi(map) {
//       map = map || this.map

//     },
//     mixinSelectPoi(map, item) {
//       map = map || this.map

//     },
//     mixinAddRegion(map) {
//       map = map || this.map

//     },
//     mixinAddRegionDataToMap(map, list) {
//       map = map || this.map

//     },
//     mixinRemoveRegion(map) {
//       map = map || this.map

//     },
//     mixinSelectRegion(map) {
//       map = map || this.map

//     }
//   }
// }

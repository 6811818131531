<template>
  <div class="wrapper" :class="{'visible': visible}">
    <!-- <p class="btn" @click="visible=!visible">{{$t('onlineTrack.poiPanel')}}</p> -->
    <transition name="slide-top-small">
      <div class="content" v-if="contentVisible">
        <p class="content-title">
          <span v-for="(item, index) in options" :key="index"
            :class="{'active': activeIndex == item.value}"
            @click="activeIndex = item.value">{{item.label}}</span>
        </p>
        <div class="content-body" v-if="activeIndex==1">
          <el-input v-model="key1" prefix-icon="el-icon-search" size="small" @input="d_key1Change"></el-input>
          <p class="content-body-line content-body-top">
            <el-checkbox v-model="checked1" :label="$t('onlineTrack.loadAll')"></el-checkbox>
          </p>
          <el-scrollbar wrap-class="content-body-scroll">
            <el-checkbox-group v-model="checkPOIList" >
            <p v-for="(item, index) in (key1 ? POIList_B : POIList)" :key="index"
              class="content-body-line">
              <el-checkbox :label="item.PointID" :key="item.PointID" @click.native="poiCheckChangeSingle(item)">{{item.PointName}}</el-checkbox>
            </p>
            </el-checkbox-group>
          </el-scrollbar>
        </div>
        <div class="content-body" v-else-if="activeIndex==2">
          <div class="n-flex-top">
            <el-input v-model="key2" prefix-icon="el-icon-search" size="small" @input="d_key2Change"></el-input>
            <tool-tip content="添加多边形围栏" placement="top" type="light-tip" :offset="-10">
              <el-button type="primary" plain @click="handlerPolyonAdd('')" circle v-ripple>
                <i-icon name="iconxinxiguanli_tianjia_weixuanzhong"></i-icon>
              </el-button>
            </tool-tip>
          </div>
          <!-- <tool-tip content="修改围栏" placement="top" type="light-tip" :offset="-10">
            <el-button type="primary" plain  @click="addHandle" circle v-ripple>
              <i-icon name="iconbaodanguanli"></i-icon>
            </el-button>
          </tool-tip> -->
          <p class="content-body-line content-body-top">
            <el-checkbox v-model="checked2" :label="$t('onlineTrack.loadAll')"></el-checkbox>
          </p>
          <el-scrollbar wrap-class="content-body-scroll">
            <el-checkbox-group v-model="checkRegionList">
              <p v-for="(item, index) in (key2 ? RegionList_B : RegionList)" :key="index" class="content-body-line">
                <el-checkbox :label="item.RegionID" :key="item.RegionID" @click.native="regionCheckChangeSingle(item)">
                  {{item.RegionName}}</el-checkbox>
                  <span class='tagMode' @click="handlerRelation(1,item)">关联设备</span>
                  <span class='tagEdit' @click="handlerPolyonAdd(item)">编辑</span>
              </p>
            </el-checkbox-group>
          </el-scrollbar>
        </div>
        <div class="content-body" v-else-if="activeIndex==3">
          <el-input v-model="key3" prefix-icon="el-icon-search" size="small" @input="d_key3Change"></el-input>
          <p class="content-body-line content-body-top">
            <el-checkbox v-model="checked3" :label="$t('onlineTrack.loadAll')"></el-checkbox>
          </p>
          <el-scrollbar wrap-class="content-body-scroll">
            <el-checkbox-group v-model="checkRegionList" >
            <p v-for="(item, index) in (key3 ? RegionListLink_B : RegionListLink)" :key="index"
              class="content-body-line">
              <el-checkbox :label="item.RegionID" :key="item.RegionID" @click.native="regionCheckChangeSingle(item)">{{item.RegionName}}</el-checkbox>
            </p>
            </el-checkbox-group>
          </el-scrollbar>
        </div>
        <div class="content-body" v-else-if="activeIndex==4">
          <div class="n-flex-top">
            <el-input v-model="key4" prefix-icon="el-icon-search" size="small" @input="d_key4Change"></el-input>
            <tool-tip content="添加圆形围栏" placement="top" type="light-tip" :offset="-10">
              <el-button type="primary" plain @click="handlerCircleAdd('')" circle v-ripple>
                <i-icon name="iconxinxiguanli_tianjia_weixuanzhong"></i-icon>
              </el-button>
            </tool-tip>
          </div>
          <p class="content-body-line content-body-top">
            <el-checkbox v-model="checked4" :label="$t('onlineTrack.loadAll')"></el-checkbox>
          </p>
          <el-scrollbar wrap-class="content-body-scroll">
            <el-checkbox-group v-model="checkRegionCircleList">
              <p v-for="(item, index) in (key4 ? RegionCircleList_B : RegionCircleList)" :key="index"
                class="content-body-line">
                <el-checkbox :label="item.CircleID" :key="item.CircleID" @click.native="circleCheckChangeSingle(item)">
                  {{item.CircleName}}</el-checkbox>
                <span class='tagMode' @click="handlerRelation(2,item)">关联设备</span>
                <span class='tagEdit' @click="handlerCircleAdd(item)">编辑</span>
              </p>
            </el-checkbox-group>
          </el-scrollbar>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import {mixinBmap as mixinAmap} from '@/views/mixins/mixin-poi.js'
import { GetPOI, GetRegionByLngLat, GetRegionByObjectID,GetRegionCircleList  } from '@/api/common'
import { debounce } from '@/common/utils'
export default {
  props: {
    map: {
      required: true
    },
    infoWindow: {
      required: true
    },
    objectId: {
      type: Number,
      default: null
    },
    display: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      visible: false,
      contentVisible: false,
      activeIndex: 1,
      checkPOIList: [],
      POIList: [],
      checkRegionList: [],
      checkRegionCircleList: [],
      RegionList: [],
      RegionListLink: [],
      RegionCircleList:[],
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      key1: '',
      key2: '',
      key3: '',
      key4: '',
      mountedReal: false,
      POIList_B: [],
      RegionList_B: [],
      RegionListLink_B: [],
      RegionCircleList_B: []
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo'
    ]),
    options() {
      if (this.objectId) {
        return  [
          {label: this.$t('onlineTrack.poiTab'), value: 1},
          {label: this.$t('onlineTrack.regionTab'), value: 2},
          {label: this.$t('onlineTrack.regionTabLink'), value: 3},
          {label: this.$t('onlineTrack.circleTab'),value: 4},
        ]
      } else {
        return  [
          {label: this.$t('onlineTrack.poiTab'), value: 1},
          {label: this.$t('onlineTrack.regionTab'), value: 2},
          {label: this.$t('onlineTrack.circleTab'),value: 4},
        ]
      }
    }
  },
  watch: {
    display(val) {
      if (val && !this.mountedReal) {
        this.mountedReal = true
        this.fetchData()
      }

      if (val) {
        setTimeout(() => {
          this.contentVisible = val
        }, 300)
      } else {
        this.contentVisible = val
      }

      this.$emit('visible', val)
    },
    checked1(val) {
      if (val) {
        let list = this.key1 ? this.POIList_B : this.POIList
        list.forEach(k => {
          if (!this.checkPOIList.includes(k.PointID)) {
            this.checkPOIList.push(k.PointID)
          }
        })

        this.mixinAddPoi(this.map, list.slice()).then(() => {
          this.mixinSetFitView(this.map, this.mixinPoiOverlays)
        })
      } else {
        this.checkPOIList.splice(0, this.checkPOIList.length)
        this.mixinRemovePoi()
      }
    },
    checked2(val) {
      if (val) {
        let list = this.key2 ? this.RegionList_B : this.RegionList
        list.forEach(k => {
          if (!this.checkRegionList.includes(k.RegionID)) {
            this.checkRegionList.push(k.RegionID)
          }
        })

        this.mixinAddRegionDataToMap(this.map, list.slice())
        // this.mixinSetFitView(this.map)
      } else {
        this.checkRegionList.splice(0, this.checkRegionList.length)
        this.mixinRemoveRegion()
      }
    },
    checked3(val) {
      if (val) {
        let list = this.key3 ? this.RegionListLink_B : this.RegionListLink

        list.forEach(k => {
          const index = this.checkRegionList.indexOf(k.RegionID)
          if (index == -1) {
            this.checkRegionList.push(k.RegionID)
          }
        })

        this.mixinAddRegionDataToMap(this.map, list.slice())
        // this.mixinSetFitView(this.map)
      } else {
        this.RegionListLink.forEach(k => {
          const index = this.checkRegionList.indexOf(k.RegionID)
          if (index != -1) {
            this.checkRegionList.splice(index, 1)
          }
        })

        this.mixinRemoveRegion()
      }
    },
    checked4(val) {
      if (val) {
        let list = this.key4 ? this.RegionCircleList_B : this.RegionCircleList

        list.forEach(k => {
          const index = this.checkRegionCircleList.indexOf(k.CircleID)
          if (index == -1) {
            this.checkRegionCircleList.push(k.CircleID)
          }
        })

        if (process.env.VUE_APP_PLAT === '_kc') {
          this.mixinAddRegionDataToMapKC(this.map, list.slice())
        } else {
          this.mixinAddCircleDataToMap(this.map, list.slice())
        }
        // this.mixinSetFitView(this.map)
      } else {
        this.RegionCircleList.forEach(k => {
          const index = this.checkRegionCircleList.indexOf(k.CircleID)
          if (index != -1) {
            this.checkRegionCircleList.splice(index, 1)
          }
        })

        this.mixinRemoveCircle()
      }
    },
    objectId() {
      // 清空地图围栏
      this.RegionListLink.forEach(k => {
        const index = this.checkRegionList.indexOf(k.RegionID)
        if (index != -1) {
          this.checkRegionList.splice(index, 1)
        }
      })

      this.mixinRemoveRegion()
      this.checked3 = false

      this.fetchDataLink()
    },
  },
  created(){
    this.key1Change = () => {
      let key = this.key1
      let list = this.POIList
      this.POIList_B = list.filter(k => k.PointName.indexOf(key) != -1)
    }
    this.key2Change = () => {
      let key = this.key2
      let list = this.RegionList
      this.RegionList_B = list.filter(k => k.RegionName.indexOf(key) != -1)
    }
    this.key3Change = () => {
      let key = this.key3
      let list = this.RegionListLink
      this.RegionListLink_B = list.filter(k => k.RegionName.indexOf(key) != -1)
    }
    this.d_key4Change = () => {
        let key = this.key4
        let list = this.RegionCircleList
        this.RegionCircleListk_B = list.filter(k => k.CircleName.indexOf(key) != -1)
      }
      this.d_key1Change = debounce(this.key1Change, 300)
      this.d_key2Change = debounce(this.key2Change, 300)
      this.d_key3Change = debounce(this.key3Change, 300)
      this.d_key4Change = debounce(this.key4Change, 300)
    },
  methods: {
    handlerPolyonAdd(row) {
      this.$emit('editPolyon', row)
    },
    handlerCircleAdd(row) {
      this.$emit('editCircle', row)
    },
    handlerRelation(index,row){
        const temp ={
          type:index,
          obj:row
        }
        this.$emit('relation', temp)
    },
    fetchData() {
      GetPOI('', true).then(ret => {
        this.POIList = ret.data.splice(0, 500)
      })

      const bounds = this.map.getBounds()
      // 西南角
      const point_xinan = bounds.getSouthWest()
      // 东北角
      const point_dongbei = bounds.getNorthEast()
      GetRegionByLngLat(point_xinan.getLng(), point_xinan.getLat(), point_dongbei.getLng(), point_dongbei.getLat()).then(ret => {
        //console.log(ret.data)
        this.RegionList = ret.data
      })
      GetRegionCircleList(1,40,true,this.loginInfo.HoldID).then(ret => {
          //console.log(ret.data)
        this.RegionCircleList = ret.data.data
      })

      if (this.objectId) {
        GetRegionByObjectID(this.objectId).then(ret => {
          this.RegionListLink = ret.data
        })
      }
    },
    fetchDataLink() {
      if (this.objectId) {
        GetRegionByObjectID(this.objectId).then(ret => {
          this.RegionListLink = ret.data
        })
      }
    },
    poiCheckChangeSingle(item) {
      setTimeout(() => {
        if (this.checkPOIList.includes(item.PointID)) {
          this.mixinSelectPoi(this.map, item)
        } else {
          const index = this.mixinPoiOverlays.findIndex(k => k.getExtData() === item.PointID)
          if (index != -1) {
            this.mixinPoiOverlays[index].setMap(null)
            this.mixinPoiOverlays[index] = null
            this.mixinPoiOverlays.splice(index, 1)
          }
        }
      }, 50)
    },
    regionCheckChangeSingle(item) {
      setTimeout(() => {
        if (this.checkRegionList.includes(item.RegionID)) {
          this.mixinSelectRegion(this.map, item)
        } else {
          const index = this.mixinRegionOverlays.findIndex(k => k.getExtData() === item.RegionID)
          if (index != -1) {
            this.mixinRegionOverlays[index].setMap(null)
            this.mixinRegionOverlays[index] = null
            this.mixinRegionOverlays.splice(index, 1)
          }
        }
      }, 50)
    },
    circleCheckChangeSingle(item){
         setTimeout(() => {
          if (this.checkRegionCircleList.includes(item.CircleID)) {
            if (process.env.VUE_APP_PLAT === '_kc') {
              this.mixinSelectRegionKC(this.map, item)
            } else {
              this.mixinSelectCircle(this.map, item)
            }
          } else {
            let index = null
            if (process.env.VUE_APP_PLAT === '_kc') {
              index = this.mixinCircleOverlays.findIndex(k => k.extID === item.CircleID)
            } else {
              index = this.mixinCircleOverlays.findIndex(k => k.getExtData() === item.CircleID)
            }

            if (index != -1) {
              this.mixinCircleOverlays[index].setMap(null)
              this.mixinCircleOverlays[index] = null
              this.mixinCircleOverlays.splice(index, 1)
            }
          }
        }, 50)
      },
    // poiCheckChange(val) {
    //   const array = this.POIList.filter(k => val.includes(k.PointID))
    //   array.forEach(k => {
    //     this.mixinSelectPoi(this.map, k)
    //   })
    // },
    // regionCheckChange(val) {
    //   const array = this.RegionList.filter(k => val.includes(k.RegionID))
    //   array.forEach(k => {
    //     this.mixinSelectRegion(this.map, k)
    //   })
    // }
  },
  mixins: [
    mixinAmap
  ]
}
</script>

<style lang="scss" scoped>
$primaryColor: black;
.wrapper {
  // transform: rotate(90deg);
  // transform-origin: 0 32px;
  // transition: all .3s ease;
  // opacity: .8;
  &.visible {
    transform:none;
    opacity: 1;
    z-index: 111 !important;
  }
  .btn {
    padding: 0 8px;
    height: 32px;
    line-height: 32px;
    width: 180px;
    background-color: rgba($primaryColor, 1);
    color: #FFF;
    box-shadow: 2px -2px 3px 1px rgba(0, 0, 0, .3);
    cursor: pointer;
    user-select: none;
    font-size: 18px;
    font-style: italic;
    position: relative;
    z-index: 2;
    text-align: center;
  }

    $contentH: 320px;
    $contentTitleH: 36px;
    .n-flex-top {
      display: flex;
      padding: 0 18px;

      & ::v-deep .el-button.is-circle {
        margin-top: 15px;
        padding: 8px;
        margin-left: 10px;
      }
    }

    .content {
      height: $contentH;
      width: 360px;
      // box-shadow: 2px 0 3px 1px rgba(0, 0, 0, .3);
      border-radius: 5px;
      background-color: rgba(255, 255, 255, .8);
      position: relative;
      z-index: 1;
      border-top: none;

      &-title {
        height: $contentTitleH;
        line-height: $contentTitleH;
        text-align: center;
        cursor: pointer;
        display: flex;
        border-bottom:1px solid #ccc;

        &>span {
          flex: 1 1 50%;
          text-align: center;
          // background-color: #F4F4F8;
          cursor: pointer;
          user-select: none;
          position: relative;
          top:1px;
          border-bottom: 1px solid transparent;
          font-size: 16px;
          color: rgba(0, 0, 0, .54);

          &.active {
            color: #1890ff;
            border-bottom: 1px solid #1890ff;
          }
        }
      }

      &-body {
        height: $contentH - $contentTitleH;
        & ::v-deep .el-input{
          display: block;
          width:90%;
          margin: 15px auto 0 auto;
        }
        & ::v-deep .el-input__inner{
          border-radius: 20px;
        }
      &-line {
        height: $contentTitleH;
        line-height: $contentTitleH;
        padding-left: 12px;
        position: relative;
          z-index: 0;
          .tagEdit{
            cursor: pointer;
            position: absolute;
            right: 0;
            top:0;
            z-index: 1;
            padding: 0 10px;
            font-size: 14px;
            color: #1890ff;
          }
          .tagMode{
            cursor: pointer;
            position: absolute;
            right: 50px;
            top:0;
            z-index: 1;
            padding: 0 10px;
            font-size: 14px;
            color: #1890ff;
          }
      }

        & ::v-deep .content-body-scroll {
          height: $contentH - 2 * $contentTitleH + 17px - 65px;
        }
      }
    }
  }

</style>

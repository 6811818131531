<template>
  <el-dialog :visible.sync="visible" :title="$t('batchImport.title')" :close-on-press-escape="false"
    :close-on-click-modal="false" append-to-body custom-class="v-dialog v-dialog--min v-dialog--footer" @closed="cancel"
    center>
    <el-upload class="upload-demo" :multiple="false" drag :on-remove="() => fileList.splice()" :file-list="fileList"
      :on-change="fileChange" :on-error="fileError" :limit="1" :on-success="handleAvatarSuccessMultiple"
      :action="`${POST_URL}?loginKey=${loginInfo.loginKey}&userid=${loginInfo.UserID}&type=kml`">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">{{$t('batchImport.tip1')}}<em>{{$t('batchImport.tip2')}}</em></div>
    </el-upload>
    <div slot="footer">
      <el-button @click="cancel" size="medium">{{$t('common.cancel')}}</el-button>
      <el-button @click="confirm" :disabled="!filePath" type="primary" size="medium" v-ripple>{{$t('common.confirm')}}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    POST_URL,
    DOWNLOAD_URL
  } from '@/api/config'
  import {
    mapGetters
  } from 'vuex'
  import {
    GetKml
  } from '@/api/common'
  export default {
    props: {
      title: {
        type: String,
        default: '导入Kml'
      },
      downloadUrl: {
        type: String,
        default: ''
      },
      showTarget: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        visible: false,
        resolve: null,
        reject: null,
        POST_URL,
        DOWNLOAD_URL,
        filePath: '',
        fileList: [],
        target: '',
        loading: false
      }
    },
    computed: {
      ...mapGetters([
        'loginInfo',
        'currentHoldId'
      ])
    },
    methods: {
      import() {
        this.visible = true
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.reject(false)
        this.fileList.splice(0)
        this.filePath = ''
        this.visible = false
      },
      confirm() {
        this.resolve(this.filePath)
        this.visible = false
      },
      fileChange(file, fileList) {
        // console.log(file)
        // console.log(fileList)
        if (fileList.length > 0) {
          this.fileList = fileList.slice(-1)
          this.filePath = fileList[0].response
        }
      },
      handleAvatarSuccessMultiple(res, file, fileList) {
        //  console.log(file)
        //  console.log(file.name)
         this.fetchKmlData(res)
      },
      fileError() {
        this.$message({
          message: `${this.$t('batchImport.errTip')}`,
          type: 'error'
        })
      },
      fetchKmlData(name) {
        this.loading = true
        GetKml(name).then(ret => {
          console.log(ret.data)
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }

</script>

<style lang="scss" scoped>
  .upload-demo {
    text-align: center;
    margin-top: 40px;
  }

  .batch-import-tip {
    padding: 12px 24px;
    border-radius: 4px;
    background-color: #F4F4F8;
    color: rgba(0, 0, 0, .95);
  }

  .v-dialog--min {
    & ::v-deep .el-dialog__body {
      height: 200px !important;
    }
  }

</style>

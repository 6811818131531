<template>
  <div class="select-car">
    <el-autocomplete clearable @dblclick.native="autoDbClick" prefix-icon="el-icon-search" @blur="autoFocusOut" v-model="VehicleName" :fetch-suggestions="fetchVehicleList" size="small" popper-class="v-popper-vehicle"
      :placeholder="$t('commonListVehicle.searchVehicle')" @select="selectChange" ref="autoSearch" :trigger-on-focus="false" :debounce="600">
      <template slot-scope="{item}">
        <p class="vehicle" @click.prevent v-if="item.DeviceRowIndex === 1"> 
         <template v-if="item.TypeID===1"><i-icon name="iconuser-person-copy" style="flex: 0 0 16px;"></i-icon></template>
        <template v-if="item.TypeID===2"><i-icon name="iconuser-airte-copy" style="flex: 0 0 16px;"></i-icon></template>
        <template v-if="item.TypeID===0"><i-icon name="iconuser-car-copy" style="flex: 0 0 16px;"></i-icon></template>
           {{item.VehicleNum}}</p>
        <p class="device" >
          <i-icon name="icon-crosshairs-gps"></i-icon> {{item.SIM2||item.SIM}}
          <span class="device__tip" v-if="!item.RcvTime">{{$t('commonListVehicle.unused')}}</span>
        </p>
        <!-- <p class="empty-text" v-if="!item.SIM2">无绑定设备</p> -->
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
// import { QueryTreeVehicleList } from '@/api/tree'
import { QueryTreeComprehenList } from '@/api/common'

export default {
  data() {
    return {
      VehicleName: ''
    }
  },
  methods: {
    autoDbClick() {
      // console.log('dbclick')
      this.$refs.autoSearch.activated = true
      this.$refs.autoSearch.suggestionDisabled = false
      this.$refs.autoSearch.debouncedGetData(this.$refs.autoSearch.value)
    },
    autoFocusOut() {
      if (this.$refs.autoSearch.value) {
        this.$refs.autoSearch.suggestionDisabled = true
      }
    },
    fetchVehicleList(queryKey, cb) {
      const promise = new Promise((resolve) => {
        QueryTreeComprehenList(queryKey, null).then(ret => {
          cb && cb(ret.data.slice().map(k => Object.assign(k, {value:k.VehicleName})))
          resolve(ret.data)
        })
      })
      return promise
    },
    selectChange(item) {
      this.$emit('select', item.RLon, item.RLat, item.VehicleNum)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-autocomplete {
  position: relative;
  width: 100%;
  & ::v-deep .el-input--prefix input {
    padding-left: 32px;
    border-radius: 20px;
    box-shadow: 0 2px 6px 0 rgba(181,181,181,.4);
  }
  & ::v-deep .el-input-group__append {
    padding: 0;
    border-radius: 12px;
    position: absolute;
    top: 1px;
    background-color: #F5F7FA;
    z-index: 1;
    right: 1px;
    width: 28px;
    height: 26px;
    border-width: 0;
    border-left: 1px solid #dcdfe6;
    i {
      padding: 6px 8px;
      cursor: pointer;
    }
  }
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"append-to-body":"","custom-class":"v-dialog v-dialog--md v-dialog--mdpadding v-dialog--footer","visible":_vm.dialogListVisible,"title":_vm.$t('regionManage.vehiceBindList')},on:{"update:visible":function($event){_vm.dialogListVisible=$event}}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.dialogList}},[_c('el-table-column',{attrs:{"label":_vm.$t('regionManage.vehicleName'),"prop":"VehicleName","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('p',{},[_vm._v(" "+_vm._s(row.VehicleName || row.SIM)+" ")])}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('regionManage.deviceID'),"prop":"SIM2","width":"110"}}),_c('el-table-column',{attrs:{"label":_vm.$t('regionManage.lineType'),"width":"115"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"color":"#3F51B5"}},[_vm._v(_vm._s(row.TrackerType==0?_vm.$t('dict')['有线设备']:_vm.$t('dict')['无线设备']))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('regionManage.triggerAlarm'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.NeedAlarm)?_c('p',{staticStyle:{"color":"#4CAF50"}},[_vm._v(_vm._s(_vm.$t('regionManage.yes')))]):_c('p',[_vm._v(_vm._s(_vm.$t('regionManage.no')))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('regionManage.recordType'),"width":"260"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(_vm.$t('regionManage.ioTypeList')[row.IOType] || '--')+" / "),_c('span',{staticStyle:{"color":"#3F51B5"}},[_vm._v(_vm._s(row.StartTime)+"-"+_vm._s(row.EndTime))])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('regionManage.regionLimit'),"width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.CheckSpeed)?_c('p',[_vm._v(_vm._s(_vm.$t('regionManage.yes'))+" / "),_c('span',{staticStyle:{"color":"#f44336"}},[_vm._v(_vm._s(_vm.$t('regionManage.limitTip2', {speed: row.MaxSpeed})))])]):_c('p',[_vm._v(_vm._s(_vm.$t('regionManage.no')))])]}}])}),_c('el-table-column',{attrs:{"min-width":"65"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.item.type===1)?_c('p',{staticClass:"i-link",on:{"click":function($event){return _vm.deleteRegionObject(_vm.dialogListRegionId, row.ObjectID)}}},[_vm._v(" "+_vm._s(_vm.$t('regionManage.jiechu')))]):(_vm.item.type===2)?_c('p',{staticClass:"i-link",on:{"click":function($event){return _vm.deleteCenterCircleObjects(_vm.dialogListRegionId, row.ObjectID)}}},[_vm._v(" "+_vm._s(_vm.$t('regionManage.jiechu')))]):_vm._e()]}}])})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"gs-button1",on:{"click":function($event){_vm.dialogListVisible=false}}},[_vm._v(_vm._s(_vm.$t('common.close')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import OnlineMonitor from '@/views/online/online-monitor'
import OnlineMonitorGL from '@/views/online/online-monitor.gl'
import OnlineTrack from '@/views/online/online-track'
import OnlineTrackBmap from '@/views/online/online-track.bmap'
import OnlineTrackQQmap from '@/views/online/online-track.qqmap'
// import OnlineMonitorGoogle from '@/views/online/online-monitor.google'
const OnlineMonitorGoogle = require('@/views/online/online-monitor.google' + process.env.VUE_APP_LOGIN + '.vue').default
// import OnlineMonitorKycs from '@/views/online/online-monitor.google.kycs'
// import OnlineMonitorGoogle2 from '@/views/online/online-monitor.google.old'
// import OnlineTrackGoogle from '@/views/online/online-track.google'
const OnlineTrackGoogle = require('@/views/online/online-track.google' + process.env.VUE_APP_LOGIN + '.vue').default
// import OnlineTrackKycs from '@/views/online/online-track.google.kycs'
// import OnlineTrackGoogle2 from '@/views/online/online-track.google.old'
import OnlineMonitorOpenstreet from '@/views/online/online-monitor.openstreet'
import OnlineTrackOpenstreet from '@/views/online/online-track.openstreet'
import OnlineMonitorBmap from '@/views/online/online-monitor.bmap'
import OnlineMonitorBmapGL from '@/views/online/online-monitor.bmap.gl'
import OnlineMonitorQQmap from '@/views/online/online-monitor.qqmap'
import RiskPolygon from '@/views/risk/risk-polygon'
// import RiskPolygonGoogle from '@/views/risk/risk-polygon.google'
const RiskPolygonGoogle = require('@/views/risk/risk-polygon.google' + process.env.VUE_APP_LOGIN + '.vue').default
// import RiskPolygonKycs from '@/views/risk/risk-polygon.google.kycs'
// import RiskPolygonGoogle2 from '@/views/risk/risk-polygon.google.old'
import RiskPolygonOpenstreet from '@/views/risk/risk-polygon.openstreet'
import RiskPolygonBmap from '@/views/risk/risk-polygon.bmap'
import RiskPolygonQQmap from '@/views/risk/risk-polygon.qqmap'
import OnlineMonitorBing from '@/views/online/online-monitor.bing'
import OnlineTrackBing from '@/views/online/online-track.bing'
import RiskPolygonBing from '@/views/risk/risk-polygon.bing'

export default {
  OnlineMonitor,
  OnlineTrack,
  OnlineTrackBmap,
  OnlineMonitorGoogle,
  // OnlineMonitorGoogle2,
  OnlineTrackGoogle,
  // OnlineTrackGoogle2,
  OnlineMonitorOpenstreet,
  OnlineTrackOpenstreet,
  OnlineTrackQQmap,
  OnlineMonitorBmap,
  OnlineMonitorBmapGL,
  OnlineMonitorGL,
  OnlineMonitorQQmap,
  RiskPolygon,
  RiskPolygonGoogle,
  // RiskPolygonGoogle2,
  RiskPolygonOpenstreet,
  RiskPolygonBmap,
  RiskPolygonQQmap,
  OnlineMonitorBing,
  OnlineTrackBing,
  RiskPolygonBing,
  // OnlineMonitorKycs,
  // OnlineTrackKycs,
  // RiskPolygonKycs
}

import { parseQueryString } from '@/common/utils'
import { getSessionConnectKey } from '@/common/cache'
import { QueryObjectIDByDeviceID, QueryObjectIDByVehicleName } from '@/api/common'

let mixin = {
  beforeRouteLeave(to, from, next) {
    // console.log({
    //   to,
    //   from
    // })

    // 从在线监控和轨迹回放进入/connect时，如果key一样则不用重新进入
    if (to.name === 'connect') {

      var searchString = ''
      var startIndex = window.location.href.indexOf('?')
      if (startIndex != -1) {
        var endIndex = window.location.href.indexOf('#')
        if (endIndex < startIndex) {
          searchString = window.location.href.substring(startIndex)
        } else {
          searchString = window.location.href.substring(startIndex, endIndex)
        }
      }
      var qs = parseQueryString(searchString)
      var key = qs.key || ''
      if (key === getSessionConnectKey()) {

        // 不用进入登录页面
        let success = ret => {
          var oid = ret.data.data
          let clear = qs.clear !== '0'
          if (oid.length > 0) {
            next(`/online/monitor?action=addTaskList&objectid=${oid.join(',')}${clear?'&clear':''}`)
          } else {
            this.$message({
              type: 'info',
              message: `${this.$t('login.notFoundID')}:${decodeURIComponent(qs.ids)}`
            })
            next(false)
          }
        }

        let error = err => {
          this.$message({
            type: 'error',
            message: err.message
          })
          next(false)
        }

        if (qs.ids && qs.type=='1') {
          QueryObjectIDByVehicleName(decodeURIComponent(qs.ids)).then(success).catch(error)
        } else if (qs.ids) {
          // 加载设备
          QueryObjectIDByDeviceID(qs.ids).then(success).catch(error)
        }

      } else {
        return next(true)
      }
    } else {
      next(true)
    }
  }
}

export { mixin }

<template>
  <!-- 面板 导入KML 文件生成路径 -->
  <transition name="slide-fade">
    <div class="v-block-kml" v-if="visibel">
      <h3>导入KML文件 <img :src="require('../../assets/images/gs-t7.png')" alt="" @click="handlerSlide"></h3>
      <!-- v-show="slideVisible" -->
      <div>
        <el-upload class="upload-demo" :multiple="false" drag :on-remove="handleRemove" :file-list="fileList"
          :on-change="fileChange" :on-error="fileError" :limit="1" :on-success="handleAvatarSuccessMultiple"
          :action="`${POST_URL}?loginKey=${loginInfo.loginKey}&userid=${loginInfo.UserID}&type=kml`">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">{{$t('batchImport.tip1')}}<em>{{$t('batchImport.tip2')}}</em></div>
        </el-upload>
        <h4>线路
           <template v-if="!(tempRoad&&tempRoad.RoadID)">
            <span @click="hanlderSaveNext" v-if="roadDataList.length">另存为</span>
          </template>
        </h4>
        <ul class="list">
          <el-scrollbar wrap-class="content-body-scroll">
            <el-checkbox-group v-model="checkRoadDataList">
              <li v-for="(item, index) in roadDataList" :key="index">
                <el-checkbox :label="item.PolygonID" :key="item.PolygonID">
                  {{item.PolygonName}}
                </el-checkbox>
              </li>
            </el-checkbox-group>
          </el-scrollbar>
        </ul>
        <h4>圆形围栏 
          <template v-if="!(tempCircle&&tempCircle.CircleID)">
             <span @click="hanlderSaveCircles" v-if="circleDataList.length">另存为</span>
          </template>
        </h4>
        <ul class="list">
          <el-scrollbar wrap-class="content-body-scroll">
            <el-checkbox-group v-model="checkCircleDataList">
              <li v-for="(item, index) in circleDataList" :key="index">
                <el-checkbox :label="item.CircleID" :key="item.CircleID" disabled>
                  {{item.coordName}}</el-checkbox>
              </li>
            </el-checkbox-group>
          </el-scrollbar>
        </ul>
        <div class="gap"></div>
      </div>
    </div>
  </transition>
</template>
<script>
  import {
    POST_URL,
    DOWNLOAD_URL
  } from '@/api/config'
  import miment from 'miment'
  import * as API from '@/api/common'
  import {
    mixinAmap
  } from '@/views/mixins/polygonMixin.js'
  import {
    SaveRegion,
    SaveCenterCircle
  } from '@/api/region'
  import * as road from '@/api/road'
  import {
    mapGetters,
    mapMutations
  } from 'vuex'
  export default {
    data() {
      return {
        POST_URL,
        DOWNLOAD_URL,
        filePath: '',
        fileList: [],
        checkRoadDataList: [],
        checkCircleDataList: [],
        roadDataList: [],
        circleDataList: [],
        polylineOverlays: [],
        circleOverlays: [],
        markerOverlays: [],
        visibel: false,
        tempRoad: {},
        tempCircle:{},
        roadName: '',
        slideVisible: false
      }
    },
    props: {
      map: {
        required: true
      },
      google: {
        type: Boolean,
        default: false
      },
      infoWindow: {
        required: true
      },
      objectId: {
        type: Number,
        default: null
      },
      display: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      ...mapGetters([
        'loginInfo',
        'taskList',
        'currentHoldId'
      ])
    },
    mixins: [
      mixinAmap
    ],
    methods: {
      open() {
        this.roadName = ''
        this.visibel = true
      },
      close() {
        this.visibel = false
        this.handleRemove()
      },
      handlerSlide() {
        this.visibel = false
        this.handleRemove()
      },
      addRoad(map, row) {
        map = map || this.map
        this.removeRoad()
        let polygon = new window.AMap.Polyline({
          map,
          path: row.Points.map(j => [j.Lon, j.Lat]),
          strokeColor: '#1890ff',
          strokeOpacity: '0.7',
          strokeWeight: '10',
          lineJoin: 'round',
          lineCap: 'round',
          showDir: 'true',
          fillColor: '#1890ff',
          fillOpacity: '0.5',
          extData: row.PolygonID
        })
        this.polylineOverlays.push(polygon)
        this.map.setFitView([polygon])
      },
      removeRoad() {
        this.polylineOverlays.forEach(k => {
          k.setMap(null)
          k = null
        })
        this.polylineOverlays.splice(0)
      },
      addCircle(map, list) {
        map = map || this.map
        this.removeCircle()
        list.forEach(k => {
          let polygon = new window.AMap.Circle({
            map,
            center: [k.Lon, k.Lat],
            radius: k.R, //半径
            strokeColor: '#1890ff',
            strokeOpacity: '0.9',
            fillColor: '#1890ff',
            fillOpacity: '0.1',
            extData: k.CircleID
          })
          polygon.getDraggable = () => false
          polygon.getPosition = () => new window.AMap.LngLat(k.Lon, k.Lat)
          polygon.on('click', () => {
            this.infoWindow.setContent(this.getSimplePanel(k.CircleName, [{
                title: `${this.$t('onlineMonitor.regionName')}：`,
                text: k.CircleName
              },
              {
                title: `${this.$t('onlineMonitor.createTime')}：`,
                text: miment(k.CreateTime).format()
              }
            ], '5em', {
              lon: k.Lon,
              lat: k.Lat
            }))
            this.infoWindow.open(map, [k.Lon, k.Lat])
            this.infoWindow.setId(null)
          })

          this.circleOverlays.push(polygon)
        })
        map.setFitView(this.circleOverlays)
      },
      addMakers(map, list) {
        map = map || this.map
        this.removeMaker()
        list.forEach(k => {
          let marker = new window.AMap.Marker({
            map,
            position: [k.Lon, k.Lat],
            content: k.CircleName,
            anchor: 'center', //设置锚点
            offset: new window.AMap.Pixel(0, 0), //设置偏移量
            extData: k.CircleID,
            zoom: 13
          })
          this.markerOverlays.push(marker)
        })
        map.setFitView(this.markerOverlays)
      },
      removeMaker() {
        this.markerOverlays.forEach(k => {
          k.setMap(null)
          k = null
        })
        this.markerOverlays.splice(0)
      },
      removeCircle() {
        this.circleOverlays.forEach(k => {
          k.setMap(null)
          k = null
        })
        this.circleOverlays.splice(0)
      },
      handleRemove(file, fileList) {
        this.fileList = []
        this.roadDataList = []
        this.circleDataList = []
        this.roadName = ''
        this.removeRoad()
        this.removeCircle()
        this.removeMaker()
      },
      fileError() {
        this.$message({
          message: `${this.$t('batchImport.errTip')}`,
          type: 'error'
        })
      },
      fileChange(file, fileList) {
        if (fileList.length > 0) {
          this.fileList = fileList.slice(-1)
          this.filePath = fileList[0].response
        }
      },
      handleAvatarSuccessMultiple(res, file, fileList) {
        this.fetchKmlData(res).then((data) => {
          this.tempRoad = ''
          this.tempCircle =''
          this.addRoad(this.map, data.r[0])
          this.addCircle(this.map, data.c)
          this.addMakers(this.map, data.c)
          // this.mixinAddCircleDataToMap(this.map, data.c)
          // this.mixinAddMarkerDataToMap(this.map, data.c)
        })
      },
      fetchKmlData(name) {
        const promise = new Promise((resolve, reject) => {
          this.loading = true
          API.GetKml(name).then(ret => {
            console.log(ret.data)
            let rList = ret.data.lineCoordList
            rList.forEach((k, index) => {
              k.PolygonName = k.coordName || '没有名字'
              k.PolygonID = index
              k.Points = k.list.map(k => {
                return {
                  Lon: k.lon,
                  Lat: k.lat
                }
              })
              k.list = k.list.map(k => {
                return {
                  Lon: k.slon,
                  Lat: k.slat
                }
              })
            })
            this.roadDataList = rList
            this.checkRoadDataList.push(rList[0].PolygonID)
            let cList = ret.data.pointCoordList
            cList.forEach((k, index) => {
              k.CircleName = k.coordName || '没有名字'
              k.CircleID = index
              k.R = 40
              k.CreateTime = new Date()
              k.Lon = k.list[0].lon // 地图纠偏点
              k.Lat = k.list[0].lat // 地图纠偏点
              k.sLon = k.list[0].slon
              k.sLat = k.list[0].slat
            })
            this.circleDataList = cList
            this.checkCircleDataList = cList.map(k => {
              return k.CircleID
            })
            let obj = {
              c: cList,
              r: rList
            }
            resolve(obj)
          }).finally(() => {
            this.loading = false
          })
        })
        return promise
      },
      hanlderSaveNext() {
        // 保存线路
        // const list = (await ConvertFrom(this.polygonData.points, 'marsr', 'gps')).data
        const item = this.roadDataList[0]
        let list = item.list.map(k => {
          return {
            lng: k.Lon,
            lat: k.Lat
          }
        })
        let temp = {
          PolygonID: 0,
          RoadName: item.PolygonName || '',
          RoadWidth: 10,
          LimitSpeed: 0,
          timeRange: ['00:00:00', '23:59:59'],
          list: list.filter((element, index, self) => {
            let a = []
            var b = []
            list.forEach((item, i) => {
              a.push(item.lat)
              b.push(item.lng)
            })
            let one = a.indexOf(element.lat) === index
            let two = b.indexOf(element.lng) === index
            return one || two
          })
        }
        this.$prompt('请输入线路名称', '', {
          inputValue: temp.RoadName || '',
          confirmButtonText: this.$t('common.save'),
          cancelButtonText: this.$t('common.cancel'),
        }).then(({
          value
        }) => {
          const obj = {
            RoadName: value,
            RoadWidth: temp.RoadWidth,
            LimitSpeed: temp.LimitSpeed,
            StartTime: temp.timeRange[0],
            EndTime: temp.timeRange[1],
          }
          road.SaveRegion(temp.PolygonID, obj, temp.list.map(k => {
            return {
              Lon: k.lng,
              Lat: k.lat
            }
          })).then(ret => {
            if (!ret.data.errCode) {
              this.$message({
                type: 'success',
                message: '保存线路: ' + obj.RoadName + "成功"
              });
              this.poiEditStatus = 0
              this.mousetool = null
              this.msTool = null
              this.tempRoad = {
                RoadID: ret.data.data.RoadID,
                RoadName: obj.RoadName
              }
              let temp = {
                type: 1,
                obj: {
                  RegionID: ret.data.RegionID,
                  PolygonName: obj.RoadName
                }
              }
            }
          }).finally(() => {
            // this.poiEditStatus = 0
            // this.mousetool = null
            // this.msTool = null
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
      },
      hanlderSaveCircles() {
        const temp = this.tempRoad
        if (!temp||!temp.RoadID) {
          this.$message({
            type: 'info',
            message: '请先保存路线'
          });
          return
        }
        let list = this.circleDataList.map(k => {
          return {
            CircleID: 0,
            CircleName: k.CircleName + '__' + temp.RoadName,
            CreateTime: miment(),
            HoldID: this.loginInfo.HoldID,
            Lat: k.sLat,
            Lon: k.sLon,
            R: k.R,
            UpdateTime: miment(),
            UserID: this.loginInfo.UserID
          }
        })
        SaveCenterCircle(list, temp.RoadID).then(ret => {
          if (!ret.data.errCode) {
            this.$message({
              type: 'success',
              message: '另存围栏成功'
            });
            this.tempCircle ={
              CircleID:1
            }
            const temp = {
              type: 2,
              obj: {
                CircleID: '',
                CircleName: ''
              },
              tag: ret.data.RegionList
            }
            console.log(temp)
            this.handleRemove()
            this.$emit('relation', temp)
          }
        }).finally(() => {

        })

      },
    }
  }

</script>
<style lang="scss" scoped>
  .v-block-kml {
    position: absolute;
    right: 490px;
    top: 8px;
    z-index: 2;
    width: 350px;
    border-radius: 5px;
    background-color: #051121;
    border: 1px solid #739AA3;

    .upload-demo {
      text-align: center;
      margin: 10px 20px 34px 20px;
      height: 100px;
      border: 1px dashed #223F5D;

      & ::v-deep .el-upload {
        width: 100%;
        height: 96px;
      }

      & ::v-deep .el-upload-list {
        li {
          background-color: #0E2237;
        }

        .el-upload-list__item-name {
          color: #fff;
        }
      }

      & ::v-deep.el-upload-dragger {
        width: 100%;
        height: 96px;
        background-color: #051121;
        border: none;

        .el-icon-upload {
          font-size: 32px;
          margin: 10px 0 5px 0;
        }

        .el-upload__text {
          color: #B9B9B9;

          em {
            color: #00FFFF;
          }
        }
      }
    }

    h4 {
      display: flex;
      justify-content: space-between;
      line-height: 32px;
      font-size: 14px;
      font-weight: normal;
      color: #B9B9B9;
      margin: 0 20px;

      span {
        cursor: pointer;
        color: #fff;
        padding: 0 20px;
        position: relative;
        top: 5px;
        z-index: 1;
        height: 30px;
        line-height: 30px;
        background: url(../../assets/images/form-h2.png) no-repeat center;
        background-size: 100% 100%;
      }

      span:hover {
        background: url(../../assets/images/form-h1.png) no-repeat center;
        background-size: 100% 100%;
      }
    }

    h3 {
      line-height: 32px;
      margin: 0 20px;
      font-size: 14px;
      font-weight: 400;
      color: #00FFFF;
      border-bottom: 1px solid #8C8C8C;
      position: relative;
      z-index: 1;

      img {
        position: absolute;
        right: 3px;
        top: 5px;
      }
    }

    .list {
      height: 120px;

      .el-scrollbar {
        height: 100%;

        & ::v-deep .el-scrollbar__wrap {
          height: calc(100% + 17px);
        }
      }

      li {
        height: 24px;
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        z-index: 0;

        & ::v-deep .el-checkbox__label {
          color: #fff;
        }
      }
    }

    .gap {
      width: 100%;
      height: 36px;
      margin-top: 10px;
    }
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }

  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active 用于 2.1.8 以下版本 */
    {
    opacity: 0;
  }

</style>

<template>
  <el-dialog append-to-body custom-class="v-dialog v-dialog--md v-dialog--mdpadding v-dialog--footer"
    :visible.sync="dialogListVisible" :title="$t('regionManage.vehiceBindList')">
    <el-table style="width: 100%" :data="dialogList">
      <el-table-column :label="$t('regionManage.vehicleName')" prop="VehicleName" width="120">
        <p slot-scope="{row}">
          {{row.VehicleName || row.SIM}}
        </p>
      </el-table-column>
      <el-table-column :label="$t('regionManage.deviceID')" prop="SIM2" width="110"></el-table-column>
      <el-table-column :label="$t('regionManage.lineType')" width="115">
        <template slot-scope="{row}">
          <span style="color:#3F51B5;">{{row.TrackerType==0?$t('dict')['有线设备']:$t('dict')['无线设备']}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('regionManage.triggerAlarm')" width="120">
        <template slot-scope="{row}">
          <p v-if="row.NeedAlarm" style="color:#4CAF50">{{$t('regionManage.yes')}}</p>
          <p v-else>{{$t('regionManage.no')}}</p>
        </template>
      </el-table-column>
      <el-table-column :label="$t('regionManage.recordType')" width="260">
        <template slot-scope="{row}">
          <p>{{$t('regionManage.ioTypeList')[row.IOType] || '--'}} / <span
              style="color:#3F51B5">{{row.StartTime}}-{{row.EndTime}}</span></p>
        </template>
      </el-table-column>
      <el-table-column :label="$t('regionManage.regionLimit')" width="90">
        <template slot-scope="{row}">
          <p v-if="row.CheckSpeed">{{$t('regionManage.yes')}} / <span
              style="color:#f44336">{{$t('regionManage.limitTip2', {speed: row.MaxSpeed})}}</span></p>
          <p v-else>{{$t('regionManage.no')}}</p>
        </template>
      </el-table-column>
      <el-table-column min-width="65">
        <template slot-scope="{row}">
          <p class="i-link" @click="deleteRegionObject(dialogListRegionId, row.ObjectID)" v-if="item.type===1">
            {{$t('regionManage.jiechu')}}</p>
          <p class="i-link" @click="deleteCenterCircleObjects(dialogListRegionId, row.ObjectID)"
            v-else-if="item.type===2">
            {{$t('regionManage.jiechu')}}</p>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer">
      <el-button @click="dialogListVisible=false" class="gs-button1">{{$t('common.close')}}</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import * as API from '@/api/region'
  import miment from 'miment'
  export default {
    data() {
      return {
        miment,
        dialogListVisible: false,
        dialogList: [],
        item: ''
      }
    },

    methods: {
      open(item) {
        this.dialogList = []
        this.dialogListVisible = true
        this.item = item
        if (item.type === 1) {
          this.dialogListRegionId = item.PolygonID
          this.fetchRegionObjectList()
        } else if (item.type === 2) {
          this.dialogListRegionId = item.PolygonID
          this.fetchCircleObjectList()
        }
      },
      close() {
        this.dialogVisible = false
      },
      deletePolygonObject(regionId, objectid) {
        if (item.type === 1) {
          this.deleteRegionObject(regionId, objectid)
        } else if (item.type === 2) {
          this.deleteCenterCircleObjects(regionId, objectid)
        }
      },
      deleteRegionObject(regionId, objectid) {
        const list = [objectid]
        API.DeleteRegionObject(regionId, list).then(ret => {
          if (!ret.data.errCode) {
            const index = this.dialogList.findIndex(k => k.ObjectID === objectid)
            if (index != -1) {
              this.dialogList.splice(index, 1)
            }
          }
        })
      },
      deleteCenterCircleObjects(regionId, objectid) {
        const list = [objectid]
        API.DeleteCenterCircleObjects(regionId, list).then(ret => {
          if (!ret.data.errCode) {
            const index = this.dialogList.findIndex(k => k.ObjectID === objectid)
            if (index != -1) {
              this.dialogList.splice(index, 1)
            }
          }
        })
      },
      fetchRegionObjectList() {
        if (!this.dialogListRegionId) {
          this.dialogList = []
          return
        }
        const promise = new Promise((resolve) => {
          API.GetRegionObject(this.dialogListRegionId).then(({
            data
          }) => {
            this.dialogList = data.slice()
            resolve()
          })
        })
        return promise
      },
      fetchCircleObjectList() {
        if (!this.dialogListRegionId) {
          this.dialogList = []
          return
        }
        const promise = new Promise((resolve) => {
          API.GetRegionCircleObject(this.dialogListRegionId).then(({
            data
          }) => {
            console.log(data.data)
            this.dialogList = data.data
            resolve()
          })
        })
        return promise
      }
    }
  }

</script>

<style lang="scss" scoped>
  .tab-wrapper {
    height: 618px;

    .tab-place-search {
      position: absolute;
      z-index: 111;
      top: 50px;
      left: 20px;
      width: 350px;
      border-radius: 20px;
      box-shadow: 0 2px 6px 0 rgba(181, 181, 181, .4);
    }

    .v-tool {
      position: absolute;
      z-index: 111;
      top: 25%;
      left: 25%;
      height: 50%;
      width: 50%;
      transition: all 0.3s ease;
      background-color: rgba(64, 158, 255, .8);
      border-radius: 4px;

      .tip {
        color: #FAFAFA;
        height: 38px;
        line-height: 38px;
        padding-left: 12px;
      }

      .start {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 12px 24px;
        font-size: 18px;
        color: #FFF;
        transition: all 0.3s ease;
        cursor: default;

        & span:hover {
          // color: #ef5350;
          cursor: pointer;
          transition: all 0.3s ease;
          display: inline-block;
          transform: translateY(-5px);
        }
      }

      &.minimize {
        top: 0;
        left: 0;
        height: 38px;
        width: 100%;

        .start {
          display: none;
        }
      }
    }

    &.tab--flex {
      display: flex;

      .tab-nav {
        width: 250px;
        border-left: 1px solid #E4E7ED;

        &.en {
          width: 350px;

          .v-item {
            .v_title {
              width: 11em;
              text-align: right;
            }

            .v_text {
              margin-left: 11em;
            }
          }
        }
      }

      .tab-body {
        flex-grow: 1;
      }
    }

    .v-item {
      .v_title {
        line-height: 32px;
        height: 32px;
      }
    }

    & ::v-deep .amap-logo,
    & ::v-deep .amap-copyright {
      display: none !important;
      z-index: -1;
    }
  }

  .tabPanels {
    width: 100%;
    // height: 560px;
    display: flex;

    .cols {
      flex-grow: 1;
      overflow: hidden;
    }

    .step1 {
      width: 50%;
      border: 1px solid #e0e1e1;
      border-radius: 10px;

      & ::v-deep .treeBlock {
        border-right: 0;
      }
    }

    .step2 {
      padding-left: 20px;

      .searchInput {
        .group {
          padding-top: 10px;
        }
      }
    }

    .con {
      width: 100%;
      height: 100%;
    }

    .selectUser {
      border: 1px solid #e0e1e1;
      padding: 5px;
      border-radius: 10px;

      .searchBar {
        margin-bottom: 15px;
      }
    }

    .solid {
      padding: 0px 10px 10px 10px;
    }

    .label-form {
      color: #999;
      margin-right: 10px;
    }

    .bot {
      border: 1px dashed #1890ff;
      border-radius: 5px;
      padding: 5px 10px;

      .tips {
        margin-top: 10px;
        height: 20px;
        color: #1890ff;
      }

      .clk {
        cursor: pointer;
        line-height: 28px;
        text-indent: 10px;
        color: #1890ff;
      }
    }

    .elRow {
      display: flex;

      .elCol {
        margin-right: 15px;
      }
    }

    .line {
      display: flex;

      span {
        width: 40px;
        position: relative;
        z-index: 1;

        i {
          position: absolute;
          left: 10px;
          top: 6px;
          z-index: 1;
          display: block;
          width: 18px;
          height: 18px;
          border: 1px solid #ccc;
          border-radius: 50%;
        }

        .icon {
          width: 26px;
          height: 26px;
          color: #1890ff;
          position: absolute;
          left: 6px;
          top: 3px;
          z-index: 1;
        }
      }

      p {
        // width: 100px;
        padding-right: 30px;
      }

      .input {
        & ::v-deep .el-input__inner {
          width: 160px;
        }

        & ::v-deep.el-input-group__append {
          min-width: 96px;
        }
      }
    }

    & ::v-deep .el-form-item--small.el-form-item {
      margin-bottom: 0px;
    }

    & ::v-deep .el-form-item__label {
      padding-bottom: 0;
    }
  }
   .el-table {
        border-top: none;
        border-left: none;
        & ::v-deep .el-table__header{
          th{
            padding: 8px 0;
            background: #263346;
            color: #a1a1a1;
            font-weight: 600;
            border-bottom: none;
          }
        }
         & ::v-deep .el-table__body-wrapper{
           background-color: #263346;
        }
         & ::v-deep .el-table__body{
           p{
             color:#fff;
           }
           tr{
             background-color: #001025;
           }
           tr:hover{
             background-color: #001025;
           }
           td{
            background-color: #001025!important;
            border: none !important;
           }
           td:hover{
             background-color: #001025;
           }
        }
        &::before {
          background-color: #126585;
        }
        &::after {
          background-color: #126585;
        }

      }
  .gs-button1{
  background: url(../../../assets/images/form-btn2.png) no-repeat center;
  background-size: auto 100%;
  color: #2F302C;
  padding: 0;
  border: none;
  height: 42px;
  min-width: 110px;
}
.gs-button1:hover{
  color: #2F302C;
  background: url(../../../assets/images/form-btn2.png) no-repeat center;
  background-size: auto 100%;
}

</style>

export default function (fn, limit = 250) {
  let wait = false
  let result
  let timer

  return function (...args) {
    if (wait) {

      if (timer) {
        clearTimeout(timer)
      }

      timer = setTimeout(() => {
        fn.apply(this, args)
        timer = null
      }, limit)

      return result
    }

    wait = true
    result = fn.apply(this, args)
    setTimeout(() => {
      wait = false
    }, limit)
    return result
  }
}

<template>
  <div class="treeAside">
    <split-pane style="overflow:hidden;" :class="{'showCount': showCount}" @end="update" min-height="28px" :min-percent="10" :default-percent="loginInfo.isUser ? percent : 0" split="horizontal">
      <div slot="paneL" class="i-side-pane" v-if="loginInfo.isUser">
        <tree-user
          ref="treeUser"
          v-if="show"
          custom-search
          :checkbox="false"
          @return="returnHandler"
          show-exchange
          show-arrow
          fixed-search
          :show-object-count.sync="showObjectCount"
          :show-return="showReturn"
          @click="clickHandler"
          @click-batch="clickBatch"
          @click-device="clickDevice"
          @exchange="simpleMode=!simpleMode"
          @expand="userExpand" :title="$t('commonTreeUser.userList')"></tree-user>
      </div>
      <div slot="paneR" class="i-side-pane">
        <list-vehicle
          ref="list"
          v-if="show"
          :show-dvr="showDvr"
          :keys="checkHoldKeys"
          @click-device="clickDevice"
          @click-refresh ='clickRefresh'
          :show-arrow="true"
          :upgrade='upgrade'
          :updateDevice="updateDevice"
          :resetCheck = "resetCheck"
          @expand="listExpand"
          @modify="modify"
          @modify-device="modifyDevice"
          @modify-user="modifyUser"
          @sendmsg="sim => $emit('sendmsg', sim)"
          @command="command"
          @handler-all="handlerAll"
          @click-batch="clickBatch"
          @transfer-batch="transferBatch"
          >
        </list-vehicle>
      </div>
    </split-pane>
     <img :src="require(`../../assets/images/gs-t2.png`)" alt="" class="gs-tag t1">
    <img :src="require(`../../assets/images/gs-t1.png`)" alt="" class="gs-tag b1">
  </div>
</template>

<script>
  import SplitPane from '@/components/split-pane'
  import TreeUser from './tree-team'
  import ListVehicle from './list-equipment'
  import ListDevice from './list-device'
  import ListTest from './list-test'
  import {
    mapGetters
  } from 'vuex'
  import SummarLine from '@/views/common/summar-line'
  import {
    QueryVehicleSummarCount,
    GetObjectIdAndVehicleIDByMixID
  } from '@/api/common'
  import {
    setUserTreeSetting,
    getUserTreeSetting
  } from '@/common/cache'

  // 显示列表车辆的混合组件
  export default {
    props: {
      showReturn: {
        type: Boolean,
        default: false
      },
      cache:{
        type: Boolean,
        default: true
      },
      showArrow: {
        type: Boolean,
        default: false
      },
      showCount: {
        type: Boolean,
        default: false
      },
      upgrade: {
        type: Number,
        default: 15
      },
      resetCheck: {
        type: [String, Object],
        default: null
      },
      showDvr: {
        type: Boolean,
        default: false
      },
      updateDevice:{
         type: Array,
        default:() => ([])
      }
    },
    computed: {
      ...mapGetters([
        'loginInfo'
      ])
    },
    components: {
      ListVehicle,
      SplitPane,
      SummarLine,
      TreeUser,
      ListTest,
      ListDevice
    },
    data() {
      return {
        show: true,
        checkHoldKeys: [],
        percent: 38,
        userArrowDown: true,
        listArrowDown: true,
        simpleMode: false,
        summar: {
          totalCount: 0,
          onlineCount: 0,
          alarmCount: 0
        },
        showObjectCount: false,
        auto: false,
        showUserTree:true
      }
    },
    methods: {
      clickDrawer(e){
        this.showUserTree = e
      },
      refresh() {
        this.show = false
        setTimeout(() => {
          this.show = true
        }, 50)
      },
      update() {
        this.$refs.treeUser && this.$refs.treeUser.update && this.$refs.treeUser.update()
        // this.$refs.list.update()
      },
      checkHandler(keys) {
        this.checkHoldKeys = keys.slice()
      },
      clickHandler(id) {
        this.checkHoldKeys = [id]
      },
      clickDevice(VehicleID, ObjectID,bool) {
        this.$emit('click-device', VehicleID, ObjectID,bool)
      },
      clickRefresh(e) {
        this.auto = e
      },
      clickBatch(list, bool) {
        this.$emit('click-batch', list, bool)
      },
      handlerAll(type,hoid){
        console.log(type)
        this.$emit('handler-all',type,hoid)
      },
      transferBatch(data, list) {
        this.$emit('transfer-batch', data)
      },
      returnHandler() {
        this.$emit('return')
      },
      userExpand(val) {
        const percent = val ? 50 : 7
        this.runExpand(this.percent, percent)
      },
      listExpand(val) {
        const percent = val ? 50 : 93
        this.runExpand(this.percent, percent)
      },
      runExpand(start, end) {
        let interval = (end - start) / 18
        let i = 0
        let hander = () => {
          this.percent = this.percent + interval
          if (i++ < 17) {
            setTimeout(hander, 5)
          }
        }
        setTimeout(hander, 17)
      },
      modify(VehicleID) {
        this.$emit('modify', VehicleID)
      },
      modifyDevice(ObjectID) {
        this.$emit('modify-device', ObjectID)
      },
      modifyUser(HoldID) {
        this.$emit('modify-user', HoldID)
      },
      command(row) {
        this.$emit('command', row)
      },
      fetchSummar() {
        QueryVehicleSummarCount().then(ret => {
          this.summar.alarmCount = ret.data.alarmCount
          this.summar.totalCount = ret.data.totalCount
          this.summar.onlineCount = ret.data.onlineCount
        })
      },
      enterAlarm() {
        if (this.simpleMode) {
          this.simpleMode = false
          setTimeout(() => {
            this.$refs.list && (this.$refs.list.selectedTypeId = 3)
          }, 50)
        } else {
          this.$refs.list.selectedTypeId = 3
        }

        this.$emit('enter-alarm')
      },
      simpleTreeClick(id) {
        GetObjectIdAndVehicleIDByMixID([id]).then(ret => {
          if (ret.data.length > 1) {
            let list = ret.data.map(k => k.ObjectID)
            if (list.length > 500) {
              this.$message({
                message: this.$t('onlineMonitor.monitorAllTip'),
                type: 'info'
              })
              list = list.slice(0, 500)
            }

            this.$emit('click-batch', list)
          } else {
            this.$emit('click-device', ret.data[0].VehicleID, ret.data[0].ObjectID)
          }
        })
      }
    },
    watch: {
      simpleMode(val) {
        let state = getUserTreeSetting({})
        state.simpleMode = val
        setUserTreeSetting(state)
      },
      showObjectCount(val) {
        let state = getUserTreeSetting({})
        state.showObjectCount = val
        setUserTreeSetting(state)
      },
      // upgrade(n, o) {
      //   if (n == 1) {
      //     // this.fetchSummar()
      //   }
      // }
    },
    created() {
      if (this.showCount) {
        // 获取数量
        this.fetchSummar()
        // clearInterval(window.timer_summar)
        // window.timer_summar = setInterval(this.fetchSummar, 90000) //左下角在线报警统计数刷新时间间隔
      }
      let state = getUserTreeSetting({})
      // this.simpleMode = state.simpleMode || this.simpleMode
      this.simpleMode = false
      this.showObjectCount = state.showObjectCount || this.showObjectCount
      this.checkHoldKeys = [this.loginInfo.HoldID]
    },
    beforeDestroy() {

    }
  }

</script>

<style lang="scss" scoped>
  .treeAside{
    width: 300px;
    height: 99%;
    margin-top:2%;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    .gs-tag{
      position: absolute;
      right: -3px;
      z-index: 1;
    }
    .t1{
      top: -4px;
    }
    .b1{
      bottom: -4px;
    }
  }
  .treeUser {
    position: relative !important;
    height: calc(100% - 20px) !important;
  }

  // .showCount {
  //   height: calc(100% - 20px) !important;
  // }
  .i-side-pane{
    height: 100%;
  }
  .i-side-pane ::v-deep .el-tree-node.is-current>.el-tree-node__content {
    background-color: #E3F2FD;
  }

  .vue-splitter-container {
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 40px);
      top: 40px;
      right: 0;
      z-index: 1;
      position: absolute;
      background-color: #DCDFE6;
    }
  }

  .panes {
    width:100%;
    height: 100%;
    .left{
      // width:240px;
      height: 40%;
    }
    .hidden{
      width:0;
    }
    .right{
      // width:240px;
      height: 60%;
      position: relative;
      border-top: 1px solid #DCDFE6;
    }
    & ::v-deep .el-loading-mask{
      background-color: #0C0C0C !important;
    }
  }

</style>

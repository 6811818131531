import {
  GetPOI,
  DeletePoi,
  GetRegionByLngLat,
  AddPoi
} from '@/api/common'
import {
  findFromArray
} from '@/common/utils'
import miment from 'miment'

// 混入POI与区域加载
export const mixinAmap = {
  created() {
    this.mixinPoiOverlays = []
    this.mixinRegionOverlays = []
    this.mixinRegionOverlaysJuhe = []
    this.mixinCircleOverlays = []
    this.mixinCircleOverlaysJuhe = []
    this.mixinCircleMarkers = []
    this.mixinRoadOverlays = []
    this.mixinRoadOverlaysJuhe = []
  },
  data() {
    return {
      miment,
      mixinPoiOverlays: [], // 兴趣点集合
      mixinRegionOverlays: [], // 多边形围栏集合
      mixinRegionOverlaysJuhe: [], //  多边形围栏聚合点
      mixinCircleOverlays: [], // 圆形围栏点集合
      mixinCircleOverlaysJuhe: [], //  圆形围栏点聚合点
      mixinCircleMarkers: [], //  圆形marker点集合
      mixinRoadOverlays: [], // 线路集合
      mixinRoadOverlaysJuhe: [], // 线路聚合
    }
  },
  methods: {
    getSimplePanel(title, datas, padding, others = {}) {
      let panel = this.$dataPanel.create(Object.assign({
        bgColor: true,
        title: title,
        datas,
        paddingLeft: padding,
        showMore: false,
        showHeader: false,
        close: true,
        lon: others.lon,
        lat: others.lat,
        onClose: () => {
          this.map.clearInfoWindow()
        },
        onChange: () => {}
      }, others))
      return panel
    },
    mixinRemovePoi() {
      this.mixinPoiOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinPoiOverlays.splice(0)
    },
    mixinRemoveRegion() {
      this.mixinRegionOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinRegionOverlays.splice(0)
      this.mixinRegionOverlaysJuhe.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinRegionOverlaysJuhe.splice(0)
    },
    mixinRemoveCircle() {
      this.mixinCircleOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinCircleOverlays.splice(0)
      this.mixinCircleOverlaysJuhe.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinCircleOverlaysJuhe.splice(0)
    },
    mixinRemoveRoad() {
      this.mixinRoadOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinRoadOverlays.splice(0)
      this.mixinRoadOverlaysJuhe.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.mixinRoadOverlaysJuhe.splice(0)
    },
    mixinSelectPoi(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinPoiOverlays, k => k.getExtData() === item.PointID)
      if (target) {
        map.setFitView([target])
        return
      }
      let k = item
      let marker = new window.AMap.Marker({
        map: map,
        position: [k.Lon_F, k.Lat_F],
        extData: k.PointID,
        icon: require('../../assets/icon/shadow_marker.png'),
        offset: new window.AMap.Pixel(-16, -32),
        label: {
          content: k.PointName,
          offset: new window.AMap.Pixel(32, 8)
        }
      })
      marker.on('click', () => {
        const d = k
        this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
          title: `${this.$t('onlineMonitor.lngLat')}：`,
          text: d.Lon + ',' + d.Lat
        }], '5em', {
          links: [this.$t('onlineMonitor.removeFavirite')],
          onLinkClick: () => {
            this.deletePoi(d)
          },
          lon: d.Lon,
          lat: d.Lat
        }))
        this.infoWindow.open(this.map, marker.getPosition())
        this.infoWindow.setId(null)
      })
      this.mixinPoiOverlays.push(marker)
      map.setFitView([marker])
    },
    mixinSelectRoad(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinRoadOverlays, k => k.getExtData() === item.PolygonID)
      if (target) {
        this.map.setFitView([target])
      } else {
        let k = item
        let polygon = new window.AMap.Polyline({
          map,
          path: k.Points.map(j => [j.Lon, j.Lat]),
          strokeColor: '#1890ff',
          strokeOpacity: '0.7',
          lineCap: 'round',
          lineJoin: 'round',
          showDir: 'true',
          strokeWeight: '10',
          fillColor: '#1890ff',
          fillOpacity: '0.5',
          extData: k.PolygonID
        })
        // polygon.getDraggable = () => false
        // polygon.getPosition = () => new window.AMap.LngLat(k.Lon,  k.Lat)
        // polygon.on('click', () => {
        //   this.infoWindow.setContent(this.getSimplePanel(k.CircleName, [{
        //       title: `${this.$t('onlineMonitor.regionName')}：`,
        //       text: k.CircleName
        //     },
        //     {
        //       title: `${this.$t('onlineMonitor.createTime')}：`,
        //       text: miment(k.CreateTime).format()
        //     }
        //   ], '5em', {
        //     lon: k.Points[0].Lon,
        //     lat: k.Points[0].Lat
        //   }))
        //   this.infoWindow.open(map, [k.Points[0].Lon,  k.Points[0].Lat])
        //   this.infoWindow.setId(null)
        // })

        this.mixinRoadOverlays.push(polygon)
        map.setFitView([polygon])
      }
    },
    mixinSelectCircle(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinCircleOverlays, k => k.getExtData() === item.CircleID)
      if (target) {
        this.map.setFitView([target])
      } else {
        let k = item
        let polygon = new window.AMap.Circle({
          map,
          center: [k.Lon, k.Lat],
          radius: k.R, //半径
          strokeColor: '#ffbf33',
          strokeOpacity: '0.9',
          fillColor: '#ffbf33',
          fillOpacity: '0.1',
          extData: k.CircleID
        })
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.AMap.LngLat(k.Lon, k.Lat)
        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.CircleName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.CircleName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.Lon,
            lat: k.Lat
          }))
          this.infoWindow.open(map, [k.Lon, k.Lat])
          this.infoWindow.setId(null)
        })

        this.mixinCircleOverlays.push(polygon)
        map.setFitView([polygon])
      }
    },
    mixinSelectRegion(map, item) {
      map = map || this.map
      const target = findFromArray(this.mixinRegionOverlays, k => k.getExtData() === item.RegionID)
      if (target) {
        this.map.setFitView([target])
      } else {
        let k = item
        let polygon = new window.AMap.Polygon({
          map: map,
          path: k.points.map(j => [j.Lon, j.Lat]),
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extData: k.RegionID
        })
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.AMap.LngLat(k.points[0].Lon, k.points[0].Lat)
        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.RegionName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.points[0].Lon,
            lat: k.points[0].Lat
          }))
          this.infoWindow.open(map, [k.points[0].Lon, k.points[0].Lat])
          this.infoWindow.setId(null)
        })

        this.mixinRegionOverlays.push(polygon)
        map.setFitView([polygon])
      }
    },
    mixinAddPoi(map, list) {
      map = map || this.map
      this.mixinRemovePoi()
      list.forEach((k, index) => {
        let marker = new window.AMap.Marker({
          map: map,
          position: [k.Lon_F, k.Lat_F],
          extData: k.PointID,
          icon: require('../../assets/icon/shadow_marker.png'),
          offset: new window.AMap.Pixel(-16, -32),
          label: {
            content: k.PointName,
            offset: new window.AMap.Pixel(32, 8)
          }
        })
        marker.on('click', () => {
          const d = list[index]
          this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
            title: `${this.$t('onlineMonitor.lngLat')}：`,
            text: d.Lon_F + ',' + d.Lat_F
          }], '5em', {
            links: [this.$t('onlineMonitor.removeFavirite')],
            onLinkClick: () => {
              this.deletePoi(d)
            },
            lon: d.Lon_F,
            lat: d.Lat_F
          }))
          this.infoWindow.open(map, marker.getPosition())
          this.infoWindow.setId(null)
        })
        this.mixinPoiOverlays.push(marker)
      })
      map.setFitView()
    },
    mixinAddMarkerDataToMap(map, list) {
      map = map || this.map
      var icon = new window.AMap.Icon({
        size: new window.AMap.Size(40, 50), // 图标尺寸
        imageOffset: new window.AMap.Pixel(0, -60), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new window.AMap.Size(40, 50) // 根据所设置的大小拉伸或压缩图片
      });
      list.forEach(k => {
        let marker = new window.AMap.Marker({
          map,
          position: [k.Lon, k.Lat],
          content: k.CircleName,
          anchor: 'center', //设置锚点
          icon: icon, // 添加 Icon 实例
          offset: new window.AMap.Pixel(0, 0), //设置偏移量
          extData: k.CircleID,
          zoom: 13
        })
        this.mixinCircleMarkers.push(marker)
      })
      map.setFitView()
    },
    mixinAddCircleDataToMap(map, list) {
      map = map || this.map
      this.mixinRemoveCircle()
      list.forEach(k => {
        let polygon = new window.AMap.Circle({
          map,
          center: [k.Lon, k.Lat],
          radius: k.R, //半径
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extData: k.CircleID
        })
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.AMap.LngLat(k.Lon, k.Lat)
        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.CircleName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.CircleName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.Lon,
            lat: k.Lat
          }))
          this.infoWindow.open(map, [k.Lon, k.Lat])
          this.infoWindow.setId(null)
        })

        this.mixinCircleOverlaysJuhe.push(polygon)
      })
      map.setFitView()
      // // 添加聚合
      // const opts = {
      //   url: require('../../assets/icon/darkRed.png'),
      //   size: new window.AMap.Size(48, 48),
      //   offset: new window.AMap.Pixel(-24, -24),
      //   textColor: '#FFF'
      // }
      // const sts = [opts, opts, opts, opts, opts]

      // map.plugin(["AMap.MarkerClusterer"], () => {
      //   let cluster = new window.AMap.MarkerClusterer(map, this.mixinCircleOverlays, {
      //     styles: sts,
      //     gridSize: 80,
      //     maxZoom: 14
      //   })

      //   this.mixinCircleOverlaysJuhe.push(cluster)
      //   // this.cluster = cluster
      //   map.setFitView()
      // })
    },
    mixinAddRegionDataToMap(map, list) {
      map = map || this.map
      this.mixinRemoveRegion()
      list.forEach(k => {
        let polygon = new window.AMap.Polygon({
          map,
          path: k.points.map(j => [j.Lon, j.Lat]),
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extData: k.RegionID
        })
        polygon.getDraggable = () => false
        polygon.getPosition = () => new window.AMap.LngLat(k.points[0].Lon, k.points[0].Lat)
        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
              title: `${this.$t('onlineMonitor.regionName')}：`,
              text: k.RegionName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}：`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.Lon,
            lat: k.Lat
          }))
          this.infoWindow.open(map, [k.points[0].Lon, k.points[0].Lat])
          this.infoWindow.setId(null)
        })

        this.mixinRegionOverlays.push(polygon)
      })
      // 添加聚合
      const opts = {
        url: require('../../assets/icon/darkRed.png'),
        size: new window.AMap.Size(48, 48),
        offset: new window.AMap.Pixel(-24, -24),
        textColor: '#FFF'
      }
      const sts = [opts, opts, opts, opts, opts]

      map.plugin(["AMap.MarkerClusterer"], () => {
        let cluster = new window.AMap.MarkerClusterer(map, this.mixinRegionOverlays, {
          styles: sts,
          gridSize: 80,
          maxZoom: 14
        })

        this.mixinRegionOverlaysJuhe.push(cluster)
        // this.cluster = cluster
        map.setFitView()
      })
    },
    mixinAddRoadDataToMap(map, list) {
      map = map || this.map
      this.mixinRemoveRoad()
      list.forEach(row => {
        let polygon = new window.AMap.Polyline({
          map,
          path: row.Points.map(j => [j.Lon, j.Lat]),
          strokeColor: '#1890ff',
          strokeOpacity: '0.7',
          strokeWeight: '10',
          fillColor: '#1890ff',
          fillOpacity: '0.5',
          extData: row.PolygonID
        })
        this.mixinRoadOverlaysJuhe.push(polygon)
      })
      map.setFitView()
      // 添加聚合
      // const opts = {
      //   url: require('../../assets/icon/darkRed.png'),
      //   size: new window.AMap.Size(48, 48),
      //   offset: new window.AMap.Pixel(-24, -24),
      //   textColor: '#FFF'
      // }
      // const sts = [opts, opts, opts, opts, opts]

      // map.plugin(["AMap.MarkerClusterer"], () => {
      //   let cluster = new window.AMap.MarkerClusterer(map, this.mixinRoadOverlays, {
      //     styles: sts,
      //     gridSize: 80,
      //     maxZoom: 14
      //   })

      //   this.mixinRoadOverlaysJuhe.push(cluster)
      //   map.setFitView()
      // })
    },
    mixinSetFitView(map, markers) {
      if (!markers) {
        map.setFitView()
      } else {
        map.setFitView(markers)
      }
    },
  }
}

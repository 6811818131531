let mixin = {
  data() {
    return {
      currentMapLayer: 0,
      layerSelectVisible: false,
      // 可选图层
      mapLayerOptions: [
        {
          name: 'layerNormal',
          img: require('../../assets/icon/layer_normal.png'),
          url: '//a.tile.openstreetmap.org/[z]/[x]/[y].png',
          type: ['openstreetmap']
        },
        {
          name: 'layerZixingche',
          img: require('../../assets/icon/layer_zixingche.png'),
          url: 'https://c.tile.thunderforest.com/cycle/[z]/[x]/[y].png?apikey=6170aad10dfd42a38d4d8c709a536f38',
          type: ['openstreetmap']
        },
        {
          name: 'layerTraffic',
          img: require('../../assets/icon/layer_jiaotongtu.png'),
          url: 'https://a.tile.thunderforest.com/transport/[z]/[x]/[y].png?apikey=6170aad10dfd42a38d4d8c709a536f38',
          type: ['openstreetmap']
        },
        {
          name: 'layerHumanitarian',
          img: require('../../assets/icon/layer_Humanitarian.png'),
          url: 'https://tile-a.openstreetmap.fr/hot/[z]/[x]/[y].png',
          type: ['openstreetmap']
        },
        {
          name: 'layerNormal',
          img: require('../../assets/icon/layer_normal.png'),
          url: '//47.88.25.10:10808/vt/lyrs=m@142&hl=zh-CN&gl=cn&src=app&x=[x]&y=[y]&z=[z]&s=Ga',
          type: ['google']
        },
        {
          name: 'layerSatellite',
          img: require('../../assets/icon/layer_satellite.png'),
          url: '//47.88.25.10:10808/vt/lyrs=y&scale=2&hl=zh-CN&gl=cn&x=[x]&y=[y]&z=[z]',
          type: ['google', 'openstreetmap']
        },
        // {
        //   name: 'layerGoogle',
        //   img: require('../../assets/icon/layer_Humanitarian.png'),
        //   url: (x, y, z) => {
        //     return `http://mt1.google.cn/vt/lyrs=m@142&hl=zh-CN&gl=cn&x=${x}&y=${y}&z=${z}&s=Galil`;
        //   }
        // },
        // {
        //   name: 'layerBaidu',
        //   img: require('../../assets/icon/layer_Humanitarian.png'),
        //   url: (x, y, z) => {

        //   }
        // },
        // {
        //   name: 'layerTecent',
        //   img: require('../../assets/icon/layer_Humanitarian.png'),
        //   url: (x, y, z) => {
        //     y = Math.pow(2, z) - 1 - y
        //     return `https://p2.map.gtimg.com/maptilesv2/${z}/${Math.floor(x/16.0)}/${Math.floor(y/16.0)}/${x}_${y}.png`
        //   }
        // }
      ]
    }
  },
  watch: {
    currentMapLayer() {
      this.$nextTick(() => {
        // 图层切换
        if (this.mapLayer && this.map) {
          // this.mapLayer.setMap(null)
          // this.mapLayer.setMap(this.map)
          this.mapLayer.setTileUrl(this.mapLayerOptions[this.currentMapLayer].url)
          this.mapLayer.reload()
        }
      })
    }
  },
  computed: {
    getLayerTile() {
      return this.mapLayerOptions[this.currentMapLayer].url
    }
  },
  methods: {
    // 获取图层路径
    // getLayerTile(x, y, z) {
    //   return this.mapLayerOptions[this.currentMapLayer].url(x, y, z)
    // }
  }
}

export default mixin
